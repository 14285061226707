import { FeedbackBlock, ContentRow } from "design-system";
import { option } from "fp-ts";
import { useFormatMessage } from "../../../intl";
import { useAppContext } from "../../../useAppContext";

export function UnableToProceed() {
  const formatMessage = useFormatMessage();

  const {
    config: { ezyPageURL },
  } = useAppContext();

  return (
    <ContentRow type="lateral-margins">
      <FeedbackBlock
        type="negative"
        size="large"
        heading={formatMessage(
          "StandardLoan.FollowUpAndSignature.SecurityPassword.KO.heading"
        )}
        subheading={option.some(
          formatMessage(
            "StandardLoan.FollowUpAndSignature.SecurityPassword.KO.subheading"
          )
        )}
        actions={[
          {
            variant: "primary",
            label: formatMessage(
              "StandardLoan.FollowUpAndSignature.SecurityPassword.KO.submit"
            ),
            action: () => window.open(ezyPageURL, "_blank"),
          },
        ]}
      />
    </ContentRow>
  );
}
