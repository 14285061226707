import { Reader } from "fp-ts/Reader";

interface PasswordForCommunicationState {
  type: "PasswordForCommunication";
  error: boolean;
}

export function passwordForCommunication(): PasswordForCommunicationState {
  return { type: "PasswordForCommunication", error: false };
}

interface UnableToProceedState {
  type: "UnableToProceed";
  error: boolean;
}

export function unableToProceed(): UnableToProceedState {
  return { type: "UnableToProceed", error: false };
}

type State = PasswordForCommunicationState | UnableToProceedState;

export function foldState<T>(
  matches: { [k in State["type"]]: Reader<Extract<State, { type: k }>, T> }
): Reader<State, T> {
  return state => matches[state.type](state as any);
}

interface UnableToProceedAction {
  type: "UnableToProceed";
}

export function unableToProceedAction(): UnableToProceedAction {
  return { type: "UnableToProceed" };
}

interface PasswordForCommunicationErrorAction {
  type: "PasswordForCommunicationError";
}

export function passwordForCommunicationErrorAction(): PasswordForCommunicationErrorAction {
  return { type: "PasswordForCommunicationError" };
}

type Action = UnableToProceedAction | PasswordForCommunicationErrorAction;

export function reducer(state: State, action: Action): State {
  switch (state.type) {
    case "PasswordForCommunication":
      switch (action.type) {
        case "UnableToProceed":
          return unableToProceed();
        case "PasswordForCommunicationError":
          return {
            ...state,
            error: true,
          };
      }
    case "UnableToProceed":
      switch (action.type) {
        case "UnableToProceed":
        case "PasswordForCommunicationError":
          return state;
      }
  }
}
