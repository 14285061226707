import {
  ComputedFieldProps,
  DropdownOption,
  unsafeLocalizedString,
} from "design-system";
import { array, tuple } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import * as remoteData from "../../../../../../RemoteData";
import { useFormatMessage } from "../../../../../../intl";
import { useQuery } from "../../../../../../useAPI";
import { businessSectors } from "../../../../api";
import {
  dropdownOptionToValue,
  selectedDropdownOption,
} from "../../../../../../Common/selectDropdownOption";
import { DropdownField } from "../../../../../../Common/DropdownField/DropdownField";

export type Props = {
  fieldProps: ComputedFieldProps<Option<NonEmptyString>>;
  disabled?: boolean;
};

export function BusinessSectorField(props: Props) {
  const formatMessage = useFormatMessage();

  const dropdownProps = props.fieldProps;

  const options: Array<DropdownOption<NonEmptyString>> = pipe(
    useQuery(businessSectors),
    tuple.fst,
    remoteData.fold(
      () => array.empty,
      () => array.empty,
      array.map(({ name, key }) => ({
        label: unsafeLocalizedString(name),
        value: key,
      }))
    )
  );

  const onChange = (value: Option<DropdownOption<NonEmptyString>>) => {
    dropdownProps.onChange(dropdownOptionToValue(value));
  };

  return (
    <DropdownField
      {...dropdownProps}
      clearable={false}
      label={formatMessage(
        "StandardLoan.Tradesman.CompanySection.BusinessSector.label"
      )}
      onChange={onChange}
      options={options}
      placeholder={formatMessage(
        "StandardLoan.Tradesman.CompanySection.BusinessSector.placeholder"
      )}
      searchable
      value={selectedDropdownOption(dropdownProps.value, options)}
      disabled={props.disabled || dropdownProps.disabled}
    />
  );
}
