import {
  DropdownOption,
  unsafeLocalizedString,
  ComputedFieldProps,
} from "design-system";
import { array, option, tuple } from "fp-ts";
import { Option } from "fp-ts/Option";
import { constant, pipe, identity } from "fp-ts/function";
import { useFormatMessage } from "../../../intl";
import { useQuery } from "../../../useAPI";
import * as api from "../api";
import { fold } from "../../../RemoteData";
import { DropdownField } from "../../../Common/DropdownField/DropdownField";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";

type Props = {
  fieldProps: ComputedFieldProps<Option<NonEmptyString>>;
  disabled?: boolean;
};

export function JobPositionDropdown({ fieldProps, disabled }: Props) {
  const formatMessage = useFormatMessage();

  const jobPositions = pipe(
    useQuery(api.jobPositions),
    tuple.fst,
    fold(constant([]), constant([]), identity)
  );

  const options: DropdownOption<NonEmptyString>[] = jobPositions.map(
    jobPosition => ({
      value: jobPosition.key,
      label: unsafeLocalizedString(jobPosition.name),
    })
  );

  const onChange = (dropdownOption: Option<DropdownOption<NonEmptyString>>) => {
    fieldProps.onChange(
      pipe(
        dropdownOption,
        option.map(v => v.value)
      )
    );
  };

  const value = getSelectedDropdownOption(fieldProps.value, options);

  function getSelectedDropdownOption(
    initialValue: Option<NonEmptyString>,
    options: Array<DropdownOption<NonEmptyString>>
  ): Option<DropdownOption<NonEmptyString>> {
    return pipe(
      initialValue,
      option.chain(initialValue =>
        pipe(
          options,
          array.findFirst(
            o =>
              initialValue.toString().toLowerCase() ===
              o.value.toString().toLowerCase()
          )
        )
      )
    );
  }

  return (
    <DropdownField
      {...fieldProps}
      clearable={false}
      label={formatMessage("StandardLoan.EmployedCard.jobPosition")}
      onChange={onChange}
      options={options}
      placeholder={formatMessage(
        "StandardLoan.EmployedCard.jobPositionPlaceholder"
      )}
      searchable
      value={value}
      disabled={disabled || fieldProps.disabled}
    />
  );
}
