import { constNull, pipe } from "fp-ts/function";
import * as remoteData from "../../../RemoteData";
import { usePolling } from "../../../useAPI";
import * as api from "../../api";
import { foldRemoteSignatureStatus } from "./LowRiskClientState";
import { Option } from "fp-ts/Option";
import { LFStatus } from "../../api";
import { BasicFullScreenLoader } from "../../../Common/BasicFullScreenLoader";

type Props = {
  onContinueRemoteSigning: (status: Option<LFStatus>) => unknown;
  onCancelSignature: () => unknown;
};

export function CheckingRemoteClientSignature(props: Props) {
  const [getRemoteSignatureStatus] = usePolling(api.getRemoteSignatureStatus, {
    shouldPollingContinue: ({ status }) =>
      status === "PENDING" || status === "SIGNING",
    intervalMS: 3000,
  });

  return pipe(
    getRemoteSignatureStatus,
    remoteData.fold(
      () => constNull(),
      constNull,
      response =>
        pipe(
          response,
          foldRemoteSignatureStatus(
            () => constNull(),
            () => <BasicFullScreenLoader />,
            () => constNull(),
            () => {
              props.onContinueRemoteSigning(response.lfStatus);
              return null;
            }
          )
        )
    )
  );
}
