import { useReducer } from "react";
import { option, taskEither } from "fp-ts";
import { pipe } from "fp-ts/function";
import { TaskEither } from "fp-ts/TaskEither";
import { useFormatMessage } from "../../../intl";
import {
  passwordForCommunication,
  reducer,
  foldState,
  unableToProceedAction,
  unableToProceed,
} from "./HighRiskClientState";
import { PasswordForCommunication } from "./PasswordForCommunication";
import { UnableToProceed } from "./UnableToProceed";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { useCommand } from "../../../useAPI";
import * as api from "../../api";

export function HighRiskClient(props: {
  credentialStatus:
    | {
        existingClient: true;
      }
    | {
        existingClient: false;
        shouldAskCredential: boolean;
      };
}) {
  const submitCredentials = useCommand(api.submitCredentials);

  const [state, dispatch] = useReducer(
    reducer,
    !props.credentialStatus.existingClient &&
      props.credentialStatus.shouldAskCredential
      ? passwordForCommunication()
      : unableToProceed()
  );
  const formatMessage = useFormatMessage();

  const onComplete = (password: NonEmptyString): TaskEither<unknown, unknown> =>
    pipe(
      submitCredentials({
        profile: {
          userId: option.none,
          pin: option.none,
          password,
        },
      }),
      taskEither.orElse(() =>
        taskEither.leftIO<unknown, unknown>(() =>
          pipe(
            formatMessage("UKonto.GenericError"),
            option.fromPredicate(() => state.error)
          )
        )
      ),
      taskEither.chain(() =>
        taskEither.fromIO(() => dispatch(unableToProceedAction()))
      )
    );

  return pipe(
    state,
    foldState({
      PasswordForCommunication: () => (
        <PasswordForCommunication
          onComplete={onComplete}
          error={pipe(
            formatMessage("UKonto.GenericError"),
            option.fromPredicate(() => state.error)
          )}
        />
      ),
      UnableToProceed: () => <UnableToProceed />,
    })
  );
}
