import { Box, FeedbackBlock } from "design-system";
import { option } from "fp-ts";
import { constNull, pipe } from "fp-ts/function";
import { useFormatMessage } from "../../../intl";
import * as remoteData from "../../../RemoteData";
import { usePolling } from "../../../useAPI";
import * as api from "../../api";
import { foldSignatureStatus } from "./LowRiskClientState";

type Props = {
  onContinue: () => unknown;
  onRetrySignature: () => unknown;
};

export function AfterSignature(props: Props) {
  const [getSignatureStatus] = usePolling(api.getSignatureStatus, {
    shouldPollingContinue: ({ signatureStatus }) =>
      signatureStatus === "PENDING",
    intervalMS: 3000,
  });
  const formatMessage = useFormatMessage();

  return pipe(
    getSignatureStatus,
    remoteData.fold(
      () => constNull(),
      constNull,
      response =>
        pipe(
          response,
          foldSignatureStatus(
            () => constNull(),
            () => (
              <Box height="100vh" hAlignContent="center" vAlignContent="center">
                <FeedbackBlock
                  size="large"
                  subheading={option.none}
                  type="loading"
                  heading={formatMessage("AfterSignature.loading.title")}
                />
              </Box>
            ),
            () => (
              <Box height="100vh" hAlignContent="center" vAlignContent="center">
                <FeedbackBlock
                  size="large"
                  type="negative"
                  heading={formatMessage("AfterSignature.error.title")}
                  subheading={option.none}
                  actions={[
                    {
                      variant: "primary",
                      label: formatMessage("AfterSignature.error.retry"),
                      action: props.onRetrySignature,
                    },
                  ]}
                />
              </Box>
            ),
            () => {
              props.onContinue();
              return null;
            }
          )
        )
    )
  );
}
