import { ComputedFieldProps, DropdownOption } from "design-system";
import { record } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { useFormatMessage } from "../../../intl";
import { DropdownField } from "../../../Common/DropdownField/DropdownField";
import {
  dropdownOptionToValue,
  selectedDropdownOption,
} from "../../../Common/selectDropdownOption";
import * as api from "../api";
import { useFormatEmploymentType } from "../domain";

type Props = {
  fieldProps: ComputedFieldProps<Option<api.EmploymentType>>;
};

export function EmploymentTypeDropdown({ fieldProps }: Props) {
  const formatMessage = useFormatMessage();
  const formatEmploymentType = useFormatEmploymentType();

  const dropdownOptions: Array<DropdownOption<api.EmploymentType>> = pipe(
    api.EmploymentType.keys,
    record.collect(employmentType => ({
      value: employmentType,
      label: formatEmploymentType(employmentType),
    }))
  );

  const onChange = (
    dropdownOption: Option<DropdownOption<api.EmploymentType>>
  ) => {
    fieldProps.onChange(dropdownOptionToValue(dropdownOption));
  };

  const value = selectedDropdownOption(fieldProps.value, dropdownOptions);

  return (
    <DropdownField
      {...fieldProps}
      clearable={false}
      label={formatMessage("StandardLoan.EmployedCard.employmentType")}
      onChange={onChange}
      options={dropdownOptions}
      placeholder={formatMessage(
        "StandardLoan.EmployedCard.employmentTypePlaceholder"
      )}
      searchable={false}
      value={value}
    />
  );
}
