import {
  Banner,
  ComputedFieldProps,
  FormRow,
  FormSection,
  NumberField,
  Stack,
  unsafeLocalizedString,
} from "design-system";
import { boolean, option } from "fp-ts";
import { constNull, pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { useFormatMessage } from "../../../../intl";
import { CompanySuggestion } from "../../../../Common/CompanyField/domain";
import { CompanyInfoRow } from "../../commons/CompanyInfoRow";

type Props = {
  companyNameFieldProps: ComputedFieldProps<string>;
  companyIcoFieldProps: ComputedFieldProps<string>;
  syncCompanyFields: (suggestion: CompanySuggestion) => void;
  stakeInCompany: ComputedFieldProps<Option<number>>;
  onCompanyNameValidationChange: (value: boolean) => unknown;
  onCompanyIcoValidationChange: (value: boolean) => unknown;
};

export const STAKELIMIT = 33;

export function CompanySection(props: Props) {
  const formatMessage = useFormatMessage();

  return (
    <FormSection
      heading={{
        title: formatMessage(
          "StandardLoan.CompanyOwnerCard.CompanySection.title"
        ),
      }}
    >
      <CompanyInfoRow
        incomeSourceType="CompanyOwner"
        companyIcoFieldProps={props.companyIcoFieldProps}
        companyNameFieldProps={props.companyNameFieldProps}
        syncCompanyFields={props.syncCompanyFields}
        onCompanyNameValidationChange={props.onCompanyNameValidationChange}
        onCompanyIcoValidationChange={props.onCompanyIcoValidationChange}
      />
      <FormRow type="full">
        <Stack column units={4}>
          <NumberField
            {...props.stakeInCompany}
            description={formatMessage(
              "StandardLoan.CompanyOwner.StakeInCompany.info"
            )}
            label={formatMessage("StandardLoan.CompanyOwner.StakeInCompany")}
            placeholder={formatMessage(
              "StandardLoan.CompanyOwner.StakeInCompanyPlaceholder"
            )}
            rightContent={option.some(unsafeLocalizedString("%"))}
            min={0}
            max={100}
          />
          {pipe(
            props.stakeInCompany.value,
            option.exists(value => value < STAKELIMIT),
            boolean.fold(constNull, () => (
              <Banner
                type="warning"
                title={option.none}
                content={formatMessage(
                  "StandardLoan.CompanyOwner.StakeInCompany.validation"
                )}
                onDismiss={option.none}
                actions={option.none}
                inline
              />
            ))
          )}
        </Stack>
      </FormRow>
    </FormSection>
  );
}
