import { DropdownField } from "../../../../../Common/DropdownField/DropdownField";
import {
  dropdownOptionToValue,
  selectedDropdownOption,
} from "../../../../../Common/selectDropdownOption";
import { DropdownOption, ComputedFieldProps } from "design-system";
import { useFormatMessage } from "../../../../../intl";
import { Option } from "fp-ts/Option";
import { record } from "fp-ts";
import { pipe } from "fp-ts/function";
import { useFormatEmploymentType } from "../../../../../StandardLoan/IncomeForm/domain";
import { EmploymentType } from "../../../api";

type Props = {
  fieldProps: ComputedFieldProps<Option<EmploymentType>>;
  disabled?: boolean;
};
export function EmploymentTypeDropdown({ fieldProps, disabled }: Props) {
  const formatMessage = useFormatMessage();
  const formatEmploymentType = useFormatEmploymentType();

  const dropdownOptions: Array<DropdownOption<EmploymentType>> = pipe(
    EmploymentType.keys,
    record.collect(employmentType => ({
      value: employmentType,
      label: formatEmploymentType(employmentType),
    }))
  );

  const onChange = (dropdownOption: Option<DropdownOption<EmploymentType>>) => {
    fieldProps.onChange(dropdownOptionToValue(dropdownOption));
  };

  const value = selectedDropdownOption(fieldProps.value, dropdownOptions);

  return (
    <DropdownField
      {...fieldProps}
      clearable={false}
      label={formatMessage("Mortgage.Bonita.Income.Contract.employmentType")}
      onChange={onChange}
      options={dropdownOptions}
      placeholder={formatMessage(
        "Mortgage.Bonita.Income.Contract.employmentTypePlaceholder"
      )}
      searchable={false}
      value={value}
      disabled={disabled}
    />
  );
}
