import { apiCall } from "../../APICall";
import { eq } from "fp-ts";
import * as t from "io-ts";
import { constFalse } from "fp-ts/function";

const PrepareDocumentsStatus = t.keyof(
  {
    STARTED: true,
    KYC_REJECTED: true,
  },
  "PrepareDocumentsStatus"
);

const PrepareDocumentsOutput = t.type(
  {
    status: PrepareDocumentsStatus,
  },
  "PrepareDocumentsOutput"
);
export const prepareDocuments = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: PrepareDocumentsOutput,
  path: ["packages", "loans", "standard-loan", "prepareDocuments"],
});

const DocumentsPreparedStatus = t.keyof(
  {
    retry: true,
    success: true,
    error: true,
  },
  "DocumentsPreparedStatus"
);

const CheckDocumentsPreparedOutput = t.type(
  {
    status: DocumentsPreparedStatus,
  },
  "CheckDocumentsPreparedOutput"
);

export const checkDocumentPrepared = apiCall({
  path: ["packages", "loans", "standard-loan", "checkDocumentsPrepared"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: CheckDocumentsPreparedOutput,
});
