import {
  FormRow,
  FormSection,
  NumberInputStepperField,
  useForm,
  validators,
} from "design-system";
import { Option } from "fp-ts/Option";
import { option, taskEither } from "fp-ts";
import { constNull, pipe } from "fp-ts/function";
import { useEffect, useRef } from "react";
import { useValidators } from "../../../Common/useValidators";
import { AlimonyIncomeDetails, IncomeData, PaymentMethod } from "../domain";
import {
  constOptionNoneValidator,
  getEmptyAllowanceInfo,
  getEmptyCompanyInfo,
  getEmptyContractInfo,
  getEmptyIncomeInfoSpecial,
} from "../../../StandardLoan/IncomeForm/commons/Utils";
import { useFormatMessage } from "../../../intl";
import { useTenantCurrency } from "../../../Common/useTenantCurrency";
import { foldTenant } from "../../../globalDomain";
import { useAppContext } from "../../../useAppContext";
import { MonthlyIncome } from "../commons/MonthlyIncomeField";
import { YesNoRadioGroupField } from "../../../Common/YesNoRadioGroup/YesNoRadioGroupField";
import { PaymentMethodRadiogroup } from "../commons/PaymentMethodRadioGroup";
import { CurrencyDropdown } from "../commons/CurrencyDropdown";
import {
  IncomeCardProps,
  useControlledSubmit,
} from "../commons/useControlledSubmit";
import { useReworkComparator } from "../../Rework/useReworkComparator";

export function AlimonyCard(props: IncomeCardProps) {
  const { incomeData, options } = props;

  const { amountMinMax, definedNoExtract, nonNegativeNumber } = useValidators();
  const validatedValues = useRef<Option<IncomeData>>(option.none);
  const formatMessage = useFormatMessage();
  const tenantCurrency = useTenantCurrency();

  const {
    reworkFieldProps,
    specialFieldsReworkFieldProps,
  } = useReworkComparator(props.rework);

  const {
    apiParameters: { tenant },
  } = useAppContext();

  const { fieldProps, handleSubmit, resetForm } = useForm(
    {
      initialValues: {
        alimonyChildrenInvolved: pipe(
          incomeData.incomeInfo,
          option.chain(value => value.alimonyIncomeDetails),
          option.chain(value => value.alimonyChildrenInvolved),
          option.alt(() => option.some(1))
        ) as Option<number>,
        alimonyIdentifiable: pipe(
          incomeData.incomeInfo,
          option.chain(value => value.alimonyIncomeDetails),
          option.chain(value => value.alimonyIdentifiable)
        ),
        alimonyOutsideCountry: pipe(
          incomeData.incomeInfo,
          option.chain(value => value.alimonyIncomeDetails),
          option.chain(value => value.alimonyOutsideCountry)
        ),
        monthlyAlimonyIncome: pipe(
          incomeData.incomeInfo,
          option.chain(value => value.alimonyIncomeDetails),
          option.chain(value => value.monthlyAlimonyIncome)
        ) as Option<number>,
        paymentMethod: pipe(
          incomeData.contractInfo,
          option.chain(data => data.paymentMethod)
        ),
        alimonyCurrency: pipe(
          incomeData.incomeInfo,
          option.chain(value => value.alimonyIncomeDetails),
          option.chain(value => value.alimonyCurrency)
        ),
      },
      fieldValidators: () => ({
        alimonyChildrenInvolved: validators.inSequence(
          definedNoExtract<number>(),
          validators.validateIfDefined(nonNegativeNumber)
        ),
        alimonyIdentifiable: foldTenant(tenant, constOptionNoneValidator, () =>
          definedNoExtract<boolean>()
        ),
        alimonyOutsideCountry: foldTenant(
          tenant,
          constOptionNoneValidator,
          () => definedNoExtract<boolean>()
        ),
        paymentMethod: foldTenant(
          tenant,
          () => definedNoExtract<PaymentMethod>(),
          constOptionNoneValidator
        ),
        monthlyAlimonyIncome: validators.inSequence(
          definedNoExtract<number>(),
          validators.validateIfDefined(amountMinMax(0, 999_999_999))
        ),
        currency: definedNoExtract<string>(),
      }),
    },
    {
      onSubmit: values =>
        taskEither.fromIO(() => {
          validatedValues.current = option.some({
            ...incomeData,
            incomeInfo: option.some({
              ...getEmptyIncomeInfoSpecial("Alimony"),
              alimonyIncomeDetails: option.some({
                alimonyChildrenInvolved: values.alimonyChildrenInvolved,
                alimonyIdentifiable: values.alimonyIdentifiable,
                alimonyOutsideCountry: values.alimonyOutsideCountry,
                monthlyAlimonyIncome: values.monthlyAlimonyIncome,
                alimonyCurrency: values.alimonyCurrency,
              }),
            }),
            companyInfo: option.some({
              ...getEmptyCompanyInfo(),
            }),
            contractInfo: option.some({
              ...getEmptyContractInfo(),
              paymentMethod: values.paymentMethod,
            }),
            allowanceInfo: option.some({
              ...getEmptyAllowanceInfo(),
            }),
          });
        }),
    }
  );
  useEffect(() => {
    resetForm();
  }, [props.employmentType]);
  useControlledSubmit(props, handleSubmit, validatedValues);

  const reworkedAlimony = (alimonyField: keyof AlimonyIncomeDetails): boolean =>
    pipe(
      props.rework,
      option.chain(rework => rework.incomeInfo),
      option.chain(incomeInfo => incomeInfo.alimonyIncomeDetails),
      option.map(details => details[alimonyField]),
      option.fold(
        () => false,
        () => true
      )
    );

  const monthlyAlimonyIncomeReworked = reworkedAlimony("monthlyAlimonyIncome");
  const alimonyChildrenInvolvedReworked = reworkedAlimony(
    "alimonyChildrenInvolved"
  );
  const alimonyIdentifiableReworked = reworkedAlimony("alimonyIdentifiable");
  const alimonyOutsideCountryReworked = reworkedAlimony(
    "alimonyOutsideCountry"
  );
  const alimonyCurrencyReworked = reworkedAlimony("alimonyCurrency");

  const disabled = !options.isEditing;

  return (
    <FormSection
      heading={{
        title: formatMessage("StandardLoan.AlimonyCard.title"),
        description: formatMessage("StandardLoan.AlimonyCard.description"),
      }}
    >
      <FormRow type="full">
        <MonthlyIncome
          fieldProps={specialFieldsReworkFieldProps(
            fieldProps("monthlyAlimonyIncome"),
            false,
            monthlyAlimonyIncomeReworked,
            disabled,
            props.reworkAll
          )}
          currency={pipe(
            fieldProps("alimonyCurrency").value,
            option.getOrElse<string>(() => tenantCurrency)
          )}
        />
      </FormRow>
      <FormRow type="full">
        <NumberInputStepperField
          {...specialFieldsReworkFieldProps(
            fieldProps("alimonyChildrenInvolved"),
            false,
            alimonyChildrenInvolvedReworked,
            disabled,
            props.reworkAll
          )}
          label={formatMessage("StandardLoan.AlimonyCard.childrenInvolved")}
          max={10}
          min={1}
          onChange={value =>
            fieldProps("alimonyChildrenInvolved").onChange(
              pipe(
                value,
                option.map(v => Math.round(v))
              )
            )
          }
        />
      </FormRow>
      {foldTenant(tenant, constNull, () => (
        <FormRow type="full">
          <YesNoRadioGroupField
            {...specialFieldsReworkFieldProps(
              fieldProps("alimonyIdentifiable"),
              false,
              alimonyIdentifiableReworked,
              disabled,
              props.reworkAll
            )}
            label={formatMessage(
              "StandardLoan.AlimonyCard.alimonyIdentifiable"
            )}
          />
        </FormRow>
      ))}
      {foldTenant(tenant, constNull, () => (
        <FormRow type="full">
          <YesNoRadioGroupField
            {...specialFieldsReworkFieldProps(
              fieldProps("alimonyOutsideCountry"),
              false,
              alimonyOutsideCountryReworked,
              disabled,
              props.reworkAll
            )}
            label={formatMessage(
              "StandardLoan.AlimonyCard.alimonyOutsideCountry"
            )}
          />
        </FormRow>
      ))}
      {foldTenant(
        tenant,
        () => (
          <FormRow type="full">
            <PaymentMethodRadiogroup
              fieldProps={reworkFieldProps(
                fieldProps("paymentMethod"),
                "contractInfo",
                disabled
              )}
            />
          </FormRow>
        ),
        constNull
      )}
      <FormRow type="full">
        <CurrencyDropdown
          fieldProps={specialFieldsReworkFieldProps(
            fieldProps("alimonyCurrency"),
            false,
            alimonyCurrencyReworked,
            disabled,
            props.reworkAll
          )}
          isMainIncome={props.isMainIncome}
        />
      </FormRow>
    </FormSection>
  );
}
