import { Either } from "fp-ts/Either";
import { TaskEither } from "fp-ts/TaskEither";
import { useEffect, useRef, useState } from "react";
import { useCommand } from "../../useAPI";
import * as api from "./api";
import { boolean, either, taskEither } from "fp-ts";
import { constVoid, pipe } from "fp-ts/function";

export type DocumentState = "Loading" | "Ready" | "Error" | "KycRejected";

type Props = {
  signingRemoteContractsAlreadyPrepared: boolean;
};

export const useDocumentStateFold = (props: Props) => {
  const pollingTimeout = useRef<number>();
  const [documentState, setDocumentState] = useState<DocumentState>("Loading");

  const prepareDocuments = useCommand(api.prepareDocuments);
  const checkDocumentPrepared = useCommand(api.checkDocumentPrepared);

  const pollDocumentPrepared: TaskEither<unknown, unknown> = () =>
    new Promise((resolve: (result: Either<unknown, unknown>) => unknown) => {
      checkDocumentPrepared()().then(
        either.fold(
          error => resolve(either.left(error)),
          result => {
            switch (result.status) {
              case "retry":
                pollingTimeout.current = window.setTimeout(
                  () => pollDocumentPrepared().then(resolve),
                  3000
                );
                return;
              case "error":
                setDocumentState("Error");
                resolve(either.left(null));
                return;
              case "success":
                setDocumentState("Ready");
                resolve(either.right(null));
                return;
            }
          }
        )
      );
    });

  useEffect(
    () =>
      pipe(
        documentState === "Ready",
        boolean.fold(constVoid, () => clearTimeout(pollingTimeout.current))
      ),
    [documentState]
  );

  useEffect(() => {
    pipe(
      props.signingRemoteContractsAlreadyPrepared,
      boolean.fold(
        () =>
          pipe(
            prepareDocuments(),
            taskEither.fold(
              () => taskEither.fromIO(() => setDocumentState("Error")),
              ({ status }) =>
                status === "KYC_REJECTED"
                  ? taskEither.fromIO(() => setDocumentState("KycRejected"))
                  : pollDocumentPrepared
            )
          ),
        () => pollDocumentPrepared
      )
    )();
  }, []);

  const foldDocumentState = <R>(
    onLoading: () => R,
    onReady: () => R,
    onError: () => R,
    onRejectedKyc: () => R
  ): R => {
    switch (documentState) {
      case "Loading":
        return onLoading();
      case "Ready":
        return onReady();
      case "Error":
        return onError();
      case "KycRejected":
        return onRejectedKyc();
    }
  };

  return { foldDocumentState, documentState };
};
