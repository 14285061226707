import { Panel, TextChildren } from "design-system";
import { option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { SecurityPassword } from "../../../UKontoSecondPart/CreateProfileAndReadDocuments/CreateProfile/SecurityPassword";
import { useFormatMessage } from "../../../intl";

type Props = {
  onComplete: ReaderTaskEither<NonEmptyString, unknown, unknown>;
  error: Option<TextChildren>;
};

export function PasswordForCommunication(props: Props) {
  const formatMessage = useFormatMessage();

  return (
    <Panel
      status="active"
      onSelect={option.none}
      headerIcon={option.none}
      title={formatMessage(
        "StandardLoan.FollowUpAndSignature.SecurityPassword.title"
      )}
      content={() => (
        <SecurityPassword
          submitLabel={formatMessage(
            "StandardLoan.FollowUpAndSignature.SecurityPassword.submit"
          )}
          onComplete={props.onComplete}
          error={props.error}
        />
      )}
      isOpen
    />
  );
}
