import { IO } from "fp-ts/IO";
import { pipe } from "fp-ts/function";
import { CredentialsMain } from "../../UKontoSecondPart/Credentials/CredentialsMain";
import * as remoteData from "../../RemoteData";
import { usePolling } from "../../useAPI";
import * as api from "../api";
import { ErrorBanner, FeedbackBlock } from "design-system";
import { useFormatMessage } from "../../intl";
import { boolean, option } from "fp-ts";
import { foldClientStatus } from "./LowRiskClient/LowRiskClientState";
import { useState } from "react";
import { CheckingContracts } from "./LowRiskClient/CheckingContracts";
import { Option } from "fp-ts/Option";
import { ExistingClientAuthenticationMethod } from "../../globalDomain";
import { StandardLoanFlowType } from "../domain";

type Props = {
  onExit: IO<unknown>;
  onComplete: (status: Option<api.LFStatus>) => void;
  onAfterSignature: (status: Option<api.LFStatus>) => void;
  authenticationMethod: Option<ExistingClientAuthenticationMethod>;
  onApplicationRejected: IO<unknown>;
  skipContracts: boolean;
  flowType: StandardLoanFlowType;
  isCredentialsAndSigningRemote: boolean;
  onRemoteCredentialsExit: IO<unknown>;
  credentialStatus:
    | {
        existingClient: true;
      }
    | {
        existingClient: false;
        shouldAskCredential: boolean;
      };
  isKycNeeded: boolean;
  isRiskyClient: boolean;
  mustReadAllDocuments?: boolean;
};

export function CreateProfileWithRemoteCredentials(props: Props) {
  return props.isKycNeeded
    ? CreateProfileWithRemoteCredentialsAndKyc(props)
    : CreateProfileWithRemoteCredentialsAndWithoutKyc(props);
}

function CreateProfileWithRemoteCredentialsAndKyc(props: Props) {
  const formatMessage = useFormatMessage();
  const [didCreateCredentials, setDidCreateCredentials] = useState(
    props.credentialStatus.existingClient
      ? props.credentialStatus.existingClient
      : !props.credentialStatus.shouldAskCredential
  );
  const [getCustomerStatusResult] = usePolling(api.getKnowYourCustomerStatus, {
    shouldPollingContinue: ({ status }) => status === "PENDING",
    intervalMS: 3000,
  });

  const renderLoading = (
    <FeedbackBlock
      type="loading"
      size="large"
      heading={formatMessage("StandardLoan.CheckSyron.Loading.heading")}
      subheading={option.some(
        formatMessage("StandardLoan.CheckSyron.Loading.subheading")
      )}
    />
  );

  return pipe(
    getCustomerStatusResult,
    remoteData.fold(
      () => renderLoading,
      () => <ErrorBanner children={formatMessage("GenericError")} />,
      response =>
        pipe(
          response,
          foldClientStatus(
            () => renderLoading,
            () => (
              <FeedbackBlock
                type="negative"
                size="large"
                heading={formatMessage("StandardLoan.CheckSyron.errorTitle")}
                subheading={option.some(
                  formatMessage("StandardLoan.CheckSyron.goToBranch")
                )}
                actions={[
                  {
                    action: props.onExit,
                    variant: "text",
                    label: formatMessage("StandardLoan.CheckSyron.exit"),
                  },
                ]}
              />
            ),
            () =>
              pipe(
                didCreateCredentials,
                boolean.fold(
                  () => (
                    <CredentialsMain
                      hasPassword={true}
                      hasPin={!props.isRiskyClient}
                      hasUserId={!props.isRiskyClient}
                      isRiskyClient={props.isRiskyClient}
                      onClose={props.onExit}
                      onComplete={() => setDidCreateCredentials(true)}
                      withCountdown
                      onRemoteCredentialsExit={props.onRemoteCredentialsExit}
                      isSLFlow={true}
                    />
                  ),
                  () => (
                    <CheckingContracts
                      onContinue={props.onComplete}
                      onApplicationRejected={props.onApplicationRejected}
                      flowType={props.flowType}
                      authenticationMethod={props.authenticationMethod}
                      skipContracts={props.skipContracts}
                      existingClient={props.credentialStatus.existingClient}
                      isCredentialsAndSigningRemote={
                        props.isCredentialsAndSigningRemote
                      }
                      isRiskyClient={props.isRiskyClient}
                      mustReadAllDocuments={props.mustReadAllDocuments}
                      onAfterSignature={props.onAfterSignature}
                    />
                  )
                )
              )
          )
        )
    )
  );
}

function CreateProfileWithRemoteCredentialsAndWithoutKyc(props: Props) {
  const [didCreateCredentials, setDidCreateCredentials] = useState(
    props.credentialStatus.existingClient
      ? props.credentialStatus.existingClient
      : !props.credentialStatus.shouldAskCredential
  );

  return pipe(
    didCreateCredentials,
    boolean.fold(
      () => (
        <CredentialsMain
          hasPassword={true}
          hasPin={!props.isRiskyClient}
          hasUserId={!props.isRiskyClient}
          isRiskyClient={props.isRiskyClient}
          onClose={props.onExit}
          onComplete={() => setDidCreateCredentials(true)}
          withCountdown
          onRemoteCredentialsExit={props.onRemoteCredentialsExit}
          isSLFlow={true}
        />
      ),
      () => (
        <CheckingContracts
          onContinue={props.onComplete}
          onApplicationRejected={props.onApplicationRejected}
          flowType={props.flowType}
          authenticationMethod={props.authenticationMethod}
          skipContracts={props.skipContracts}
          existingClient={props.credentialStatus.existingClient}
          isCredentialsAndSigningRemote={props.isCredentialsAndSigningRemote}
          isRiskyClient={props.isRiskyClient}
          mustReadAllDocuments={props.mustReadAllDocuments}
          onAfterSignature={props.onAfterSignature}
        />
      )
    )
  );
}
