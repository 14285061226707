import {
  FormRow,
  ComputedFieldProps,
  // NonNegativeInteger,
  // LocalizedString,
  CheckboxField,
  FormSection,
  DropdownOption,
  LocalizedString,
  TextField,
  AutocompleteInputField,
  unsafeLocalizedString,
  SuggestionsStatus,
} from "design-system";
import { LocaleKey, useFormatMessage } from "../../../intl";
import { useCompanyFields } from "../../../Common/CompanyField/utils";
import { CompanySuggestion } from "../../../Common/CompanyField/domain";
import { isOptionTrue, StandardIncomeSourceType } from "../domain";
import {
  constant,
  constFalse,
  constNull,
  identity,
  pipe,
} from "fp-ts/function";
import { boolean, option, taskEither } from "fp-ts";
// import { useState} from "react";
import { Option } from "fp-ts/Option";
import {
  isReworked,
  useReworkComparator,
} from "../../Rework/useReworkComparator";
import { ReworkIncomeNoSourceOutput } from "../../Rework/api";
import { IncomeFormOptions } from "./useControlledSubmit";
import { useEffect, useMemo, useState } from "react";
import { useCommand, useQuery } from "../../../useAPI";
import * as identificationApi from "../../../IdUpload/api";
import * as remoteData from "../../../RemoteData";
import {
  autocompleteInputStatusFromRemoteData,
  getCitySuggestionLabel,
  mapCountriesToOptions,
} from "../../../IdUpload/addressFormUtils";
import {
  dropdownOptionToValue,
  selectedDropdownOption,
} from "../../../Common/selectDropdownOption";
import { DropdownField } from "../../../Common/DropdownField/DropdownField";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { BusinessSectorField } from "../Forms/TradesManForm/CompanySection/Fields/BusinessSectorField";
import { MonthYearField } from "./MonthYearField";
import { PhoneNumberField } from "../../../Common/PhoneNumberField/PhoneNumberField";
import { useDebounceState } from "../../../Common/useDebounceState";
import { useRemoteData } from "../../../useRemoteData";
import { CountryCode } from "../../../globalDomain";
import { DataForClientWithoutIcoOutput } from "../api";
import { RemoteData } from "../../../RemoteData";

interface CompanyFieldTexts {
  label: LocaleKey;
  placeholder: LocaleKey;
}

interface CompanySectionFields {
  name: CompanyFieldTexts;
  ico: CompanyFieldTexts;
}

type CustomTextsIncomeSourceType = Extract<
  StandardIncomeSourceType,
  "Employed" | "Freelancer" | "CompanyOwner" | "TradesmanCoOperatingPerson"
>;

const incomeSourceFieldTexts: Record<
  CustomTextsIncomeSourceType,
  CompanySectionFields
> = {
  Employed: {
    name: {
      label: "StandardLoan.EmployedCard.CompanySection.companyNameLabel",
      placeholder:
        "StandardLoan.EmployedCard.CompanySection.companyNamePlaceholder",
    },
    ico: {
      label: "StandardLoan.EmployedCard.CompanySection.companyIcoLabel",
      placeholder:
        "StandardLoan.EmployedCard.CompanySection.companyIcoPlaceholder",
    },
  },
  Freelancer: {
    name: {
      label: "StandardLoan.FreelancerCard.CompanySection.companyNameLabel",
      placeholder:
        "StandardLoan.FreelancerCard.CompanySection.companyNamePlaceholder",
    },
    ico: {
      label: "StandardLoan.FreelancerCard.CompanySection.companyIcoLabel",
      placeholder:
        "StandardLoan.FreelancerCard.CompanySection.companyIcoPlaceholder",
    },
  },
  CompanyOwner: {
    name: {
      label: "StandardLoan.CompanyOwner.CompanySection.companyNameLabel",
      placeholder:
        "StandardLoan.CompanyOwner.CompanySection.companyNamePlaceholder",
    },
    ico: {
      label: "StandardLoan.CompanyOwner.CompanySection.companyIcoLabel",
      placeholder:
        "StandardLoan.CompanyOwner.CompanySection.companyIcoPlaceholder",
    },
  },
  TradesmanCoOperatingPerson: {
    name: {
      label: "StandardLoan.Tradesman.CompanySection.companyNameLabel",
      placeholder:
        "StandardLoan.Tradesman.CompanySection.companyNamePlaceholder",
    },
    ico: {
      label: "StandardLoan.Tradesman.CompanySection.companyIcoLabel",
      placeholder:
        "StandardLoan.Tradesman.CompanySection.companyIcoPlaceholder",
    },
  },
};

type Props = {
  incomeSourceType: CustomTextsIncomeSourceType;
  companyNameFieldProps: ComputedFieldProps<string>;
  companyIcoFieldProps: ComputedFieldProps<string>;
  syncCompanyFields: (suggestion: CompanySuggestion) => void;
  onCompanyNameValidationChange: (value: boolean) => unknown;
  onCompanyIcoValidationChange: (value: boolean) => unknown;
};

export function CompanyInfoRow(props: Props) {
  const formatMessage = useFormatMessage();
  const { Name, ICO } = useCompanyFields();
  const texts = incomeSourceFieldTexts[props.incomeSourceType];

  return (
    <FormRow type="1-1">
      <Name
        {...props.companyNameFieldProps}
        label={formatMessage(texts.name.label)}
        placeholder={formatMessage(texts.name.placeholder)}
        onSelectSuggestion={props.syncCompanyFields}
        onValidationChange={props.onCompanyNameValidationChange}
      />
      <ICO
        {...props.companyIcoFieldProps}
        label={formatMessage(texts.ico.label)}
        placeholder={formatMessage(texts.ico.placeholder)}
        onSelectSuggestion={props.syncCompanyFields}
        onCompanyIcoValidationChange={props.onCompanyIcoValidationChange}
      />
    </FormRow>
  );
}

type TextFieldOptionProps = {
  fieldProps: ComputedFieldProps<Option<NonEmptyString>>;
  label: LocalizedString;
  placeholder: LocalizedString;
  initialValue: NonEmptyString;
};

function TextFieldOption({
  fieldProps,
  label,
  placeholder,
  initialValue,
}: TextFieldOptionProps) {
  const onChange = (value: string) =>
    fieldProps.onChange(option.some(value as NonEmptyString));

  useEffect(
    () =>
      pipe(
        fieldProps.value,
        option.fold(
          () => {
            onChange(initialValue);
          },
          value =>
            pipe(
              value === "",
              boolean.fold(
                () => {
                  onChange(value);
                },
                () => {
                  if (initialValue !== value) {
                    onChange(initialValue);
                  }
                }
              )
            )
        )
      ),
    []
  );

  return (
    <TextField
      {...fieldProps}
      label={label}
      onChange={onChange}
      placeholder={placeholder}
      value={pipe(
        fieldProps.value,
        option.getOrElse(() => "")
      )}
    />
  );
}

type PhoneFieldOptionProps = {
  fieldProps: ComputedFieldProps<Option<string>>;
  label: LocalizedString;
  placeholder: LocalizedString;
  initialValue: string;
};

function PhoneFieldOption({
  fieldProps,
  label,
  placeholder,
  initialValue,
}: PhoneFieldOptionProps) {
  const onChange = (value: string) => fieldProps.onChange(option.some(value));

  useEffect(
    () =>
      pipe(
        fieldProps.value,
        option.fold(
          () => {
            onChange(initialValue);
          },
          value =>
            pipe(
              value === "" || value.length <= 4,
              boolean.fold(
                () => {
                  onChange(value);
                },
                () => {
                  if (initialValue !== value) {
                    onChange(initialValue);
                  }
                }
              )
            )
        )
      ),
    []
  );

  return (
    <PhoneNumberField
      {...fieldProps}
      label={label}
      onChange={onChange}
      placeholder={placeholder}
      value={pipe(
        fieldProps.value,
        option.getOrElse(() => "")
      )}
    />
  );
}

type AutocompleteFieldOptionProps = {
  fieldProps: ComputedFieldProps<Option<NonEmptyString>>;
  label: LocalizedString;
  placeholder: LocalizedString;
  loadingMessage: LocalizedString;
  initialValue: NonEmptyString;
  suggestionStatus: SuggestionsStatus<any>;
  onSelectSuggestion: (suggestion: DropdownOption<any>) => unknown;
};

function AutocompleteFieldOption({
  fieldProps,
  label,
  placeholder,
  initialValue,
  loadingMessage,
  suggestionStatus,
  onSelectSuggestion,
}: AutocompleteFieldOptionProps) {
  const onChange = (value: string) =>
    fieldProps.onChange(option.some(value as NonEmptyString));

  useEffect(
    () =>
      pipe(
        fieldProps.value,
        option.fold(
          () => {
            onChange(initialValue);
          },
          value =>
            pipe(
              value === "",
              boolean.fold(
                () => {
                  onChange(value);
                },
                () => {
                  if (initialValue !== value) {
                    onChange(initialValue);
                  }
                }
              )
            )
        )
      ),
    []
  );

  return (
    <AutocompleteInputField
      {...fieldProps}
      value={pipe(
        fieldProps.value,
        option.getOrElse(() => "")
      )}
      label={label}
      onChange={onChange}
      suggestionsStatus={suggestionStatus}
      onSelectSuggestion={onSelectSuggestion}
      placeholder={placeholder}
      loadingMessage={loadingMessage}
    />
  );
}

type CountriesProps = {
  countriesFieldProps: ComputedFieldProps<Option<string>>;
  initialValue: Option<string>;
};

export function Countries({
  countriesFieldProps,
  initialValue,
}: CountriesProps) {
  const formatMessage = useFormatMessage();

  const dropdownProps = countriesFieldProps;

  const countries = pipe(
    useQuery(identificationApi.allCountries),
    ([result]) => result,
    remoteData.fold(constant([]), constant([]), identity)
  );

  const options = mapCountriesToOptions(countries);

  const onChange = (value: Option<DropdownOption<string>>) => {
    dropdownProps.onChange(dropdownOptionToValue(value));
  };
  const selectedValue = pipe(
    dropdownProps.value,
    option.fold(
      () => {
        if (
          pipe(
            initialValue,
            option.getOrElse(() => "")
          ) !== "" &&
          initialValue !== dropdownProps.value
        ) {
          dropdownProps.value = initialValue;
          dropdownProps.onChange(initialValue);
        }
        return initialValue;
      },
      value => option.some(value)
    )
  );

  console.log(dropdownProps.value);
  return (
    <DropdownField
      {...dropdownProps}
      value={selectedDropdownOption(selectedValue, options)}
      onChange={onChange}
      label={formatMessage("CashLoan.Income.country")}
      placeholder={formatMessage("CashLoan.Income.country")}
      options={options}
      searchable
      clearable={false}
    />
  );
}

type ClientWithoutICOProps = {
  incomeSourceType: CustomTextsIncomeSourceType;
  fieldProps: any;
  rework: Option<ReworkIncomeNoSourceOutput>;
  reworkAll: boolean;
  initialValues: any;
  options: IncomeFormOptions;
  onSelectCitySuggestion: (suggestion: LocalizedString) => unknown;
  onSelectStreetSuggestion: (suggestion: string) => unknown;
  dataForClientWithoutIco: RemoteData<unknown, DataForClientWithoutIcoOutput>;
  phoneValue: Option<string>;
};

export function ClientWithoutICO({
  fieldProps,
  rework,
  reworkAll,
  options,
  initialValues,
  onSelectCitySuggestion,
  onSelectStreetSuggestion,
  dataForClientWithoutIco,
  phoneValue,
}: ClientWithoutICOProps) {
  const { specialFieldsReworkFieldProps } = useReworkComparator(rework);
  const formatMessage = useFormatMessage();
  const addIcoInitialState = pipe(
    fieldProps("addIco").value,
    option.getOrElse(constFalse)
  );
  const [addIco, setAddIco] = useState(addIcoInitialState);
  const handleAddIco = (value: boolean) => {
    fieldProps("addIco").value = option.some(value);
    fieldProps("addIco").onChange(option.some(value));
    setAddIco(value);
  };

  const currentYear = new Date().getFullYear();

  const fetchCitySuggestions = useCommand(identificationApi.citySuggestions, {
    skipTracking: true,
  });
  const noSuggestions = taskEither.right(option.none);
  const cityQueryTerms = useDebounceState(
    pipe(
      fieldProps("city").value,
      option.getOrElse(() => "")
    ),
    500
  );
  const citySuggestions = useRemoteData(
    useMemo(
      () =>
        cityQueryTerms.length >= 2
          ? pipe(
              fetchCitySuggestions({
                searchTerm: cityQueryTerms.replace(/^.*,\s*/, ""),
                country: pipe(
                  initialValues.country,
                  option.getOrElse(() => "CZE")
                ) as CountryCode,
              }),
              taskEither.map(option.some)
            )
          : noSuggestions,
      [cityQueryTerms]
    )
  );

  const searchTermRegExp = /(.+),\s+(.+)/;
  function splitSearchTerm(
    searchTerm: string
  ): Option<{ city: NonEmptyString; provinceName: NonEmptyString }> {
    if (!searchTermRegExp.test(searchTerm)) return option.none;

    const blocks = searchTerm.match(searchTermRegExp);

    return pipe(
      option.fromNullable(blocks),
      option.map(blocks => ({
        city: blocks[1] as NonEmptyString,
        provinceName: blocks[2] as NonEmptyString,
      }))
    );
  }
  const cityAndProvince = splitSearchTerm(
    pipe(
      fieldProps("city").value,
      option.getOrElse(() => "")
    )
  );
  const streetQueryTerms = useDebounceState(
    pipe(
      fieldProps("street").value,
      option.getOrElse(() => "")
    ),
    500
  );
  const fetchstreetSuggestions = useCommand(
    identificationApi.streetSuggestions,
    {
      skipTracking: true,
    }
  );
  const streetSuggestions = useRemoteData(
    useMemo(
      () =>
        streetQueryTerms.length >= 2
          ? pipe(
              fetchstreetSuggestions(
                pipe(
                  cityAndProvince,
                  option.fold(
                    () => ({
                      streetName: streetQueryTerms,
                      zipCode: pipe(
                        fieldProps("zipCode").value,
                        option.getOrElse(() => "")
                      ),
                      cityName: pipe(
                        fieldProps("city").value,
                        option.getOrElse(() => "")
                      ),
                      provinceName: "",
                      country: pipe(
                        fieldProps("country").value,
                        option.getOrElse(() => "")
                      ) as CountryCode,
                    }),
                    cityAndProvince => ({
                      streetName: streetQueryTerms,
                      zipCode: pipe(
                        fieldProps("zipCode").value,
                        option.getOrElse(() => "")
                      ),
                      cityName: cityAndProvince.city,
                      provinceName: cityAndProvince.provinceName,
                      country: pipe(
                        fieldProps("country").value,
                        option.getOrElse(() => "")
                      ) as CountryCode,
                    })
                  )
                )
              ),
              taskEither.map(option.some)
            )
          : noSuggestions,
      [streetQueryTerms]
    )
  );

  const clientCountry = pipe(
    dataForClientWithoutIco,
    remoteData.fold(
      () => option.none,
      () => option.none,
      value => value.country
    )
  );
  const clientCity = pipe(
    dataForClientWithoutIco,
    remoteData.fold(
      () => option.none,
      () => option.none,
      value => value.city
    )
  );
  const clientStreet = pipe(
    dataForClientWithoutIco,
    remoteData.fold(
      () => option.none,
      () => option.none,
      value => value.street
    )
  );
  const clientHouseNr = pipe(
    dataForClientWithoutIco,
    remoteData.fold(
      () => option.none,
      () => option.none,
      value => value.houseNumber
    )
  );
  const clientZipCode = pipe(
    dataForClientWithoutIco,
    remoteData.fold(
      () => option.none,
      () => option.none,
      value => value.zipCode
    )
  );

  const employmentTypeReworked = isReworked(
    rework,
    "contractInfo",
    "employmentType"
  );
  const incomeSourceReworked = isReworked(rework, "incomeInfo", "incomeSource");
  const disabled = !options.isEditing;
  const reworkDependent = employmentTypeReworked || incomeSourceReworked;

  const nameOfEntrepreneur = (
    <TextFieldOption
      fieldProps={specialFieldsReworkFieldProps(
        fieldProps("nameOfEntrepreneur"),
        reworkDependent,
        isReworked(rework, "companyInfo", "nameOfEntrepreneur") &&
          option.isSome(initialValues.nameOfEntrepreneur),
        disabled,
        reworkAll
      )}
      label={formatMessage("CashLoan.Income.nameOfEntrepreneur")}
      placeholder={formatMessage("CashLoan.Income.nameOfEntrepreneur")}
      initialValue={"" as NonEmptyString}
    />
  );

  const entrepreneurIco = (
    <TextFieldOption
      fieldProps={specialFieldsReworkFieldProps(
        fieldProps("entrepreneurIco"),
        reworkDependent,
        isReworked(rework, "companyInfo", "entrepreneurIco") &&
          option.isSome(initialValues.entrepreneurIco),
        disabled,
        reworkAll
      )}
      label={formatMessage("CashLoan.Income.entrepreneurIco")}
      placeholder={formatMessage("CashLoan.Income.entrepreneurIco")}
      initialValue={"" as NonEmptyString}
    />
  );

  const businessSector = pipe(
    addIco,
    boolean.fold(constNull, () => (
      <FormRow type="full">
        <BusinessSectorField
          fieldProps={specialFieldsReworkFieldProps(
            fieldProps("businessSector"),
            reworkDependent,
            isReworked(rework, "companyInfo", "businessSector") &&
              option.isSome(initialValues.businessSector),
            disabled,
            reworkAll
          )}
        />
      </FormRow>
    ))
  );

  const businessStartingDate = pipe(
    addIco,
    boolean.fold(constNull, () => (
      <MonthYearField
        {...specialFieldsReworkFieldProps(
          fieldProps("businessStartingDate"),
          reworkDependent,
          isReworked(rework, "companyInfo", "businessStartingDate") &&
            option.isSome(initialValues.businessStartingDate),
          disabled,
          reworkAll
        )}
        clearable={false}
        label={formatMessage("StandardLoan.FreelancerCard.startingDate")}
        maxYear={currentYear}
        minYear={currentYear - 50}
        searchable
        monthSelectionYearLimit={0}
        checkMonthLimit={false}
      />
    ))
  );

  const country = pipe(
    addIco,
    boolean.fold(constNull, () => (
      <FormRow type="full">
        <Countries
          countriesFieldProps={specialFieldsReworkFieldProps(
            fieldProps("country"),
            reworkDependent,
            isReworked(rework, "companyInfo", "country") &&
              option.isSome(initialValues.country),
            disabled,
            reworkAll
          )}
          initialValue={clientCountry}
        />
      </FormRow>
    ))
  );

  const phone = pipe(
    addIco,
    boolean.fold(constNull, () => (
      <FormRow type="full">
        <PhoneFieldOption
          fieldProps={specialFieldsReworkFieldProps(
            fieldProps("phone"),
            reworkDependent,
            isReworked(rework, "companyInfo", "phone") &&
              option.isSome(initialValues.phone),
            disabled,
            reworkAll
          )}
          label={formatMessage("Mortgage.Bonita.phone")}
          placeholder={formatMessage("Mortgage.Bonita.phone")}
          initialValue={pipe(
            phoneValue,
            option.getOrElse(() => "")
          )}
        />
      </FormRow>
    ))
  );

  const city = pipe(
    addIco,
    boolean.fold(constNull, () => (
      <FormRow type="full">
        <AutocompleteFieldOption
          fieldProps={specialFieldsReworkFieldProps(
            fieldProps("city"),
            reworkDependent,
            isReworked(rework, "companyInfo", "city") &&
              option.isSome(initialValues.city),
            disabled,
            reworkAll
          )}
          initialValue={
            pipe(
              clientCity,
              option.getOrElse(() => "")
            ) as NonEmptyString
          }
          label={formatMessage("Identification.address.city")}
          suggestionStatus={autocompleteInputStatusFromRemoteData(
            pipe(
              citySuggestions,
              remoteData.map(
                option.getOrElse(
                  constant<identificationApi.IDCitySuggestionsOutput>([])
                )
              )
            ),
            suggestion => ({
              value: suggestion,
              label: getCitySuggestionLabel(suggestion),
            })
          )}
          onSelectSuggestion={suggestion => {
            onSelectCitySuggestion(getCitySuggestionLabel(suggestion.value));
          }}
          placeholder={formatMessage("Identification.address.cityPlaceholder")}
          loadingMessage={formatMessage("LoadingEllipsis")}
        />
      </FormRow>
    ))
  );

  const street = pipe(
    addIco,
    boolean.fold(constNull, () => (
      <FormRow type="full">
        <AutocompleteFieldOption
          fieldProps={specialFieldsReworkFieldProps(
            fieldProps("street"),
            reworkDependent,
            isReworked(rework, "companyInfo", "street") &&
              option.isSome(initialValues.street),
            disabled,
            reworkAll
          )}
          label={formatMessage("Identification.address.streetName")}
          initialValue={
            pipe(
              clientStreet,
              option.getOrElse(() => "")
            ) as NonEmptyString
          }
          suggestionStatus={autocompleteInputStatusFromRemoteData(
            pipe(
              streetSuggestions,
              remoteData.map(
                option.getOrElse(
                  constant<identificationApi.IDStreetSuggestionsOutput>([])
                )
              )
            ),
            suggestion => ({
              value: suggestion,
              label: unsafeLocalizedString(suggestion.streetName),
            })
          )}
          onSelectSuggestion={({ value }) => {
            onSelectStreetSuggestion(value.streetName);
          }}
          placeholder={formatMessage("Identification.address.streetName")}
          loadingMessage={formatMessage("LoadingEllipsis")}
        />
      </FormRow>
    ))
  );

  const houseNumber = pipe(
    addIco,
    boolean.fold(constNull, () => (
      <FormRow type="full">
        <TextFieldOption
          fieldProps={specialFieldsReworkFieldProps(
            fieldProps("houseNr"),
            reworkDependent,
            isReworked(rework, "companyInfo", "houseNr") &&
              option.isSome(initialValues.houseNr),
            disabled,
            reworkAll
          )}
          initialValue={
            pipe(
              clientHouseNr,
              option.getOrElse(() => "")
            ) as NonEmptyString
          }
          label={formatMessage("Identification.address.streetNumber")}
          placeholder={formatMessage("Identification.address.streetNumber")}
        />
      </FormRow>
    ))
  );

  const zipCode = pipe(
    addIco,
    boolean.fold(constNull, () => (
      <FormRow type="full">
        <TextFieldOption
          fieldProps={specialFieldsReworkFieldProps(
            fieldProps("zipCode"),
            reworkDependent,
            isReworked(rework, "companyInfo", "zipCode") &&
              option.isSome(initialValues.zipCode),
            disabled,
            reworkAll
          )}
          initialValue={
            pipe(
              clientZipCode,
              option.getOrElse(() => "")
            ) as NonEmptyString
          }
          label={formatMessage("Identification.address.zipCode")}
          placeholder={formatMessage("Identification.address.zipCode")}
        />
      </FormRow>
    ))
  );

  const addIcoCheckBox = (
    <FormRow type="full">
      <CheckboxField
        {...specialFieldsReworkFieldProps(
          fieldProps("addIco"),
          reworkDependent,
          isReworked(rework, "companyInfo", "addIco") &&
            option.isSome(initialValues.addIco),
          disabled,
          reworkAll
        )}
        value={addIco}
        onChange={handleAddIco}
        label={formatMessage("Mortgage.Bonita.addIco")}
      />
    </FormRow>
  );

  return (
    <FormSection>
      {addIcoCheckBox}
      {isOptionTrue(fieldProps("addIco").value) && (
        <FormRow type="1-1">
          {nameOfEntrepreneur}
          {entrepreneurIco}
        </FormRow>
      )}
      {businessSector}
      {businessStartingDate}
      {phone}
      {country}
      {city}
      {street}
      {houseNumber}
      {zipCode}
    </FormSection>
  );
}
