import { Reader } from "fp-ts/Reader";
import { IO } from "fp-ts/IO";

export type RiskType = "high" | "low";

export function foldRiskType<T>(
  whenHighRisk: IO<T>,
  whenLowRisk: IO<T>
): Reader<RiskType, T> {
  return type => {
    switch (type) {
      case "high":
        return whenHighRisk();
      case "low":
        return whenLowRisk();
    }
  };
}
