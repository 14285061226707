import { RadioGroupField, ComputedFieldProps } from "design-system";
import { option, record } from "fp-ts";
import { constant, pipe, constFalse } from "fp-ts/function";
import {
  PaymentMethod,
  PaymentMethodCZ,
  PaymentMethodSK,
  useFormatPaymentMethod,
} from "../domain";
import { useFormatMessage } from "../../../intl";
import { foldTenant } from "../../../globalDomain";
import { useAppContext } from "../../../useAppContext";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { Option } from "fp-ts/Option";

type Props = {
  fieldProps: ComputedFieldProps<Option<PaymentMethod>>;
};

export function PaymentMethodRadiogroup({ fieldProps }: Props) {
  const {
    apiParameters: { tenant },
  } = useAppContext();
  const formatMessage = useFormatMessage();
  const formatPaymentMethod = useFormatPaymentMethod();

  const paymentMethods = pipe(
    foldTenant(
      tenant,
      () => record.keys(PaymentMethodSK.keys) as NonEmptyArray<PaymentMethod>,
      () => record.keys(PaymentMethodCZ.keys) as NonEmptyArray<PaymentMethod>
    )
  );

  return (
    <RadioGroupField
      {...fieldProps}
      label={formatMessage("StandardLoan.PaymentMethod.label")}
      variant="vertical"
      options={paymentMethods}
      optionKey={value => value.toString()}
      renderOption={formatPaymentMethod}
      renderOptionChildren={constant(option.none)}
      isOptionDisabled={constFalse}
    />
  );
}
