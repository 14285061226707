import {
  ComputedFieldProps,
  LocalizedString,
  NumberField,
} from "design-system";
import { option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { useFormatIncomeCurrency, useFormatMessage } from "../../../intl";
import { pipe } from "fp-ts/function";

type MonthlyIncomeProps = {
  fieldProps: ComputedFieldProps<Option<number>>;
  currency?: string;
  description?: LocalizedString;
  label?: LocalizedString;
  placeholder?: LocalizedString;
  min?: number;
  max?: number;
};

export function MonthlyIncome(props: MonthlyIncomeProps) {
  const formatMessage = useFormatMessage();
  const formatCurrency = useFormatIncomeCurrency();

  return (
    <NumberField
      {...props.fieldProps}
      rightContent={pipe(
        props.currency,
        option.fromNullable,
        option.map(formatCurrency)
      )}
      label={
        !!props.label
          ? props.label
          : formatMessage("StandardLoan.EmployedCard.monthlyIncome")
      }
      placeholder={
        !!props.placeholder
          ? props.placeholder
          : formatMessage("StandardLoan.EmployedCard.monthlyIncomePlaceholder")
      }
      description={props.description}
      min={props.min}
      max={props.max}
      locale="sk"
    />
  );
}
