import { ComputedFieldProps, FormRow, FormSection } from "design-system";
import { boolean, option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { foldTenant } from "../../../../globalDomain";
import { useFormatMessage } from "../../../../intl";
import { useAppContext } from "../../../../useAppContext";
import { MoneyField } from "../../../../Common/Price/MoneyField/MoneyField";
import { useTenantCurrency } from "../../../../Common/useTenantCurrency";
import { ReworkIncomeNoSourceOutput } from "../../../Rework/api";
import {
  isReworked,
  useReworkComparator,
} from "../../../Rework/useReworkComparator";
import { PaymentMethodRadiogroup } from "../../commons/PaymentMethodRadioGroup";
import { PensionerIncomeDetails } from "../../domain";
import { PensionerFormFields, PensionType } from "./domain";
import { PensionTypeDropdown } from "./PensionerTypeDropdown";
import { YesNoRadioGroupField } from "../../../../Common/YesNoRadioGroup/YesNoRadioGroupField";

export type PensionerFormProps = {
  fieldProps: <K extends keyof PensionerFormFields>(
    name: K
  ) => ComputedFieldProps<PensionerFormFields[K]>;
  disabled?: boolean;
  rework: Option<ReworkIncomeNoSourceOutput>;
  reworkAll: boolean;
};

type Props = PensionerFormProps;

export function PensionerForm({
  fieldProps,
  disabled,
  rework,
  reworkAll,
}: Props): JSX.Element {
  const formatMessage = useFormatMessage();
  const {
    apiParameters: { tenant },
  } = useAppContext();
  const currency = useTenantCurrency();

  const getPensionType = () =>
    pipe(
      fieldProps("pensionType").value,
      option.getOrElse(() => "" as PensionType)
    );

  const {
    reworkFieldProps,
    specialFieldsReworkFieldProps,
  } = useReworkComparator(rework);

  const reworkedPension = (
    pensionField: keyof PensionerIncomeDetails
  ): boolean =>
    pipe(
      rework,
      option.chain(rework => rework.incomeInfo),
      option.chain(incomeInfo => incomeInfo.pensionerIncomeDetails),
      option.chain(details => details[pensionField] as any),
      option.isSome
    );

  const pensionTypeReworked = reworkedPension("pensionType");
  const monthlyPensionReworked = reworkedPension("monthlyPension");
  const monthlyRentReworked = reworkedPension("monthlyRent");

  const employmentTypeReworked = isReworked(
    rework,
    "contractInfo",
    "employmentType"
  );
  const incomeSourceReworked = isReworked(rework, "incomeInfo", "incomeSource");
  const reworkDependent = employmentTypeReworked || incomeSourceReworked;

  return (
    <FormSection>
      <FormRow type="full">
        <PensionTypeDropdown
          fieldProps={specialFieldsReworkFieldProps(
            fieldProps("pensionType"),
            reworkDependent,
            pensionTypeReworked,
            !!disabled,
            reworkAll
          )}
        />
      </FormRow>
      {foldTenant(
        tenant,
        () => [
          <FormRow type="full">
            <MoneyField
              {...specialFieldsReworkFieldProps(
                fieldProps("monthlyPension"),
                reworkDependent,
                monthlyPensionReworked,
                !!disabled,
                reworkAll
              )}
              currency={currency}
              placeholder={formatMessage(
                "StandardLoan.PensionerForm.IncomeSection.monthlyIncomePlaceholder"
              )}
              label={formatMessage(
                "StandardLoan.PensionerForm.IncomeSection.monthlyIncome"
              )}
              min={0}
              max={999999999}
            />
          </FormRow>,
          <FormRow type="full">
            <PaymentMethodRadiogroup
              fieldProps={reworkFieldProps(
                fieldProps("paymentMethod"),
                "contractInfo",
                !!disabled
              )}
            />
          </FormRow>,
        ],
        () =>
          pipe(
            getPensionType() === "Retirement",
            boolean.fold(
              () =>
                pipe(
                  fieldProps("pensionType").value,
                  option.fold(
                    (): Array<JSX.Element> => [],
                    () => [
                      <FormRow type="full">
                        <MoneyField
                          {...specialFieldsReworkFieldProps(
                            fieldProps("monthlyPension"),
                            reworkDependent,
                            monthlyPensionReworked,
                            !!disabled,
                            reworkAll
                          )}
                          currency={currency}
                          placeholder={formatMessage(
                            "StandardLoan.PensionerForm.IncomeSection.monthlyPensionPlaceholder"
                          )}
                          label={formatMessage(
                            "StandardLoan.PensionerForm.IncomeSection.monthlyPension"
                          )}
                          min={0}
                          max={999999999}
                        />
                      </FormRow>,
                    ]
                  )
                ),
              () => [
                <FormRow type="full">
                  <MoneyField
                    {...specialFieldsReworkFieldProps(
                      fieldProps("monthlyRent"),
                      reworkDependent,
                      monthlyRentReworked,
                      !!disabled,
                      reworkAll
                    )}
                    currency={currency}
                    placeholder={formatMessage(
                      "StandardLoan.PensionerForm.IncomeSection.monthlyRentPlaceholder"
                    )}
                    label={formatMessage(
                      "StandardLoan.PensionerForm.IncomeSection.monthlyRent"
                    )}
                    min={0}
                    max={999999999}
                  />
                </FormRow>,
              ]
            )
          )
      )}
      <FormRow type="full">
        <YesNoRadioGroupField
          {...specialFieldsReworkFieldProps(
            fieldProps("hasAdditionalIncome"),
            reworkDependent,
            isReworked(rework, "incomeInfo", "hasAdditionalIncome"),
            !!disabled,
            reworkAll
          )}
          label={formatMessage("StandardLoan.additionalIncome")}
          description={formatMessage(
            "StandardLoan.additionalIncome.description"
          )}
        />
      </FormRow>
    </FormSection>
  );
}
