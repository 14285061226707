import {
  FormRow,
  FormSection,
  Stack,
  useForm,
  validators,
} from "design-system";
import { boolean, option, taskEither } from "fp-ts";
import { constNull, constTrue, identity, pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { useEffect, useMemo, useRef, useState } from "react";
import { foldTenant } from "../../../../globalDomain";
import { useFormatMessage } from "../../../../intl";
import { useAppContext } from "../../../../useAppContext";
import { CompanySuggestion } from "../../../../Common/CompanyField/domain";
import { useValidators } from "../../../../Common/useValidators";
import {
  isReworked,
  useReworkComparator,
} from "../../../Rework/useReworkComparator";
import { JobPositionDropdown } from "../../commons/JobPositionsDropdown";
import {
  IncomeCardProps,
  useControlledSubmit,
} from "../../../../StandardLoan/IncomeForm/commons/useControlledSubmit";
import {
  constOptionNoneValidator,
  getEmptyAllowanceInfo,
  getEmptyCompanyInfo,
  getEmptyContractInfo,
  getEmptyIncomeInfo,
  notInTheFutureMonthYearValidator,
  useUniqueCompanyValidators,
} from "../../../../StandardLoan/IncomeForm/commons/Utils";
import {
  IncomeData,
  isOptionTrue,
} from "../../../../StandardLoan/IncomeForm/domain";
import {
  CompanySectionCZ,
  CompanySectionSK,
} from "./CompanySection/CompanySection";
import {
  IncomeSectionCZ,
  IncomeSectionSK,
} from "./IncomeSection/IncomeSection";
import { ClientWithoutICO } from "../../commons/CompanyInfoRow";
import { useQuery } from "../../../../useAPI";
import * as api from "../../api";
import * as remoteData from "../../../../RemoteData";

const isOptionFalse = (value: Option<boolean>) => {
  return pipe(
    value,
    option.exists(value => value === false)
  );
};

const MIN_AMOUNT = -999999999;
const MAX_AMOUNT = 999999999;

const currentYear = new Date().getFullYear();

export function TradesManCard(props: IncomeCardProps) {
  const { incomeData, options } = props;
  const {
    apiParameters: { tenant },
  } = useAppContext();

  const {
    definedNoExtract,
    validCompanyNameRequired,
    validCompanyIcoRequired,
    amountMinMaxWithNegative,
    validCompanyIco,
    validAddressField,
    validStreetNumber,
    validPhone,
    nonBlankString,
    nonNegativeNumber,
  } = useValidators();

  const amountValidator = () =>
    validators.inSequence(
      definedNoExtract<number>(),
      validators.validateIfDefined(
        amountMinMaxWithNegative(MIN_AMOUNT, MAX_AMOUNT)
      )
    );

  const SKOnlyAmountValidator = foldTenant(
    tenant,
    amountValidator,
    constOptionNoneValidator
  );
  const formatMessage = useFormatMessage();
  const validatedValues = useRef<Option<IncomeData>>(option.none);

  const syncCompanyFields = (suggestion: CompanySuggestion) =>
    setFormState({
      companyName: suggestion.name,
      companyIco: suggestion.id,
    });

  const [
    uniqueCompanyNameValidator,
    uniqueCompanyIcoValidator,
  ] = useUniqueCompanyValidators(options);

  const startingDateMinYear = useMemo(
    () =>
      pipe(
        incomeData.incomeOptions.startDateYearOptions,
        option.map(years => years.sort((a, b) => a - b)[0]),
        option.getOrElse(() => currentYear - 50)
      ),
    [incomeData.incomeOptions.startDateYearOptions]
  );

  function shouldValidateCompanyIco(
    entrepreneurIcoOption: Option<string>,
    addIco: Option<boolean>
  ): boolean {
    if (isOptionTrue(addIco)) {
      const entrepreneurIco = pipe(
        entrepreneurIcoOption,
        option.getOrElse(() => "")
      );
      return entrepreneurIco.length > 0;
    }
    return false;
  }

  const {
    reworkFieldProps,
    specialFieldsReworkFieldProps,
  } = useReworkComparator(props.rework);

  // This dummy validator is to avoid a known error in useForm fieldArrayValidator
  const workAroundAlwaysRightValidator = <T extends any>() =>
    validators.fromPredicate<T>(constTrue, formatMessage("GenericError"));

  const initialValues = {
    salaryCurrency: props.salaryCurrency,
    monthlyIncome: props.monthlyIncome,
    companyName: pipe(
      incomeData.companyInfo,
      option.chain(companyInfo => companyInfo.companyName),
      option.getOrElse(() => "")
    ),
    companyIco: pipe(
      incomeData.companyInfo,
      option.chain(companyInfo => companyInfo.companyIco),
      option.getOrElse(() => "")
    ),
    keepsAccountancy: pipe(
      incomeData.incomeInfo,
      option.fold(
        () => option.some(true),
        data => data.keepsAccountancy
      )
    ),
    equityPositive: pipe(
      incomeData.companyInfo,
      option.fold(
        () => option.some(true),
        data => data.equityPositive
      )
    ),
    equityFromLastYear: pipe(
      incomeData.incomeInfo,
      option.chain(value => value.equityFromLastYear)
    ),
    grossIncomes: pipe(
      incomeData.incomeInfo,
      option.chain(value => value.grossIncomes)
    ),
    r37PartialTaxBase: pipe(
      incomeData.incomeInfo,
      option.chain(value => value.r37PartialTaxBase)
    ),
    r39PartialTaxBase: pipe(
      incomeData.incomeInfo,
      option.chain(value => value.r39PartialTaxBase)
    ),
    r201IncomeFromLease: pipe(
      incomeData.incomeInfo,
      option.chain(value => value.r201IncomeFromLease)
    ),
    r101BruttoIncomes: pipe(
      incomeData.incomeInfo,
      option.chain(value => value.r101BruttoIncomes)
    ),
    reInPersonalOwnership: pipe(
      incomeData.incomeInfo,
      option.chain(value => value.reInPersonalOwnership)
    ),
    reCoOwned: pipe(
      incomeData.incomeInfo,
      option.chain(value => value.reCoOwned)
    ),
    reUsedAsCollateral: pipe(
      incomeData.incomeInfo,
      option.chain(value => value.reUsedAsCollateral)
    ),
    incomeFromRentContract: pipe(
      incomeData.incomeInfo,
      option.chain(value => value.incomeFromRentContract)
    ),
    isApplicantDeclareAsUserInDeclaration: pipe(
      incomeData.incomeInfo,
      option.chain(value => value.isApplicantDeclareAsUserInDeclaration)
    ),
    isCooperativeOwnership: pipe(
      incomeData.incomeInfo,
      option.chain(value => value.isCooperativeOwnership)
    ),
    tax: pipe(
      incomeData.incomeInfo,
      option.chain(value => value.tax)
    ),
    taxBase: pipe(
      incomeData.incomeInfo,
      option.chain(value => value.taxBase)
    ),
    businessGrossIncome: pipe(
      incomeData.incomeInfo,
      option.chain(value => value.businessGrossIncome)
    ),
    businessSector: pipe(
      incomeData.companyInfo,
      option.chain(value => value.businessSector)
    ),
    businessStartingDate: pipe(
      incomeData.companyInfo,
      option.chain(value => value.businessStartingDate)
    ),
    jobPosition: pipe(
      incomeData.contractInfo,
      option.chain(value => value.jobPosition)
    ),
    addIco: pipe(
      incomeData.companyInfo,
      option.chain(data => data.addIco)
    ),
    nameOfEntrepreneur: pipe(
      incomeData.companyInfo,
      option.chain(data => data.nameOfEntrepreneur)
    ),
    entrepreneurIco: pipe(
      incomeData.companyInfo,
      option.chain(data => data.entrepreneurIco)
    ),
    country: pipe(
      incomeData.companyInfo,
      option.chain(data => data.country)
    ),
    phone: pipe(
      incomeData.companyInfo,
      option.chain(data => data.phone)
    ),
    city: pipe(
      incomeData.companyInfo,
      option.chain(data => data.city)
    ),
    street: pipe(
      incomeData.companyInfo,
      option.chain(data => data.street)
    ),
    houseNr: pipe(
      incomeData.companyInfo,
      option.chain(data => data.houseNr)
    ),
    zipCode: pipe(
      incomeData.companyInfo,
      option.chain(data => data.zipCode)
    ),
    hasAdditionalIncome: pipe(
      incomeData.incomeInfo,
      option.chain(data => data.hasAdditionalIncome),
      option.alt(() => option.some(false))
    ),
    hasInfoFromTaxReturn: pipe(
      incomeData.incomeInfo,
      option.chain(data => data.hasInfoFromTaxReturn),
      option.alt(() => option.some(false))
    ),
  };
  const [companyNameIsValid, setCompanyNameIsValid] = useState(true);
  const [companyIcoIsValid, setCompanyIcoIsValid] = useState(true);
  const [addInfoFromTaxReturn, setAddInfoFromTaxReturn] = useState(
    option.isSome(initialValues.hasInfoFromTaxReturn)
      ? initialValues.hasInfoFromTaxReturn.value
      : false
  );

  const onCompanyNameValidationChange = (isValid: boolean) => {
    setCompanyNameIsValid(isValid);
  };
  const onCompanyIcoValidationChange = (isValid: boolean) => {
    setCompanyIcoIsValid(isValid);
  };
  const {
    fieldProps,
    setValues: setFormState,
    handleSubmit,
    resetForm,
  } = useForm(
    {
      initialValues: initialValues,
      fieldValidators: values => ({
        salaryCurrency: () => definedNoExtract<string>()(props.salaryCurrency),
        monthlyIncome: () =>
          validators.inSequence(
            definedNoExtract<number>(),
            validators.validateIfDefined(nonNegativeNumber)
          )(props.monthlyIncome),
        companyName: !isOptionTrue(values.addIco)
          ? validators.inSequence(
              validCompanyNameRequired(companyNameIsValid),
              uniqueCompanyNameValidator(values.companyName)
            )
          : workAroundAlwaysRightValidator<string>(),
        companyIco: !isOptionTrue(values.addIco)
          ? validators.inSequence(
              validCompanyIcoRequired(false, companyIcoIsValid),
              uniqueCompanyIcoValidator(values.companyIco)
            )
          : workAroundAlwaysRightValidator<string>(),
        keepsAccountancy: foldTenant(tenant, constOptionNoneValidator, () =>
          definedNoExtract()
        ),
        equityPositive: foldTenant(tenant, constOptionNoneValidator, () =>
          pipe(
            isOptionTrue(values.keepsAccountancy),
            boolean.fold(constOptionNoneValidator, () =>
              validators.inSequence(
                definedNoExtract(),
                validators.validateIfDefined(
                  validators.fromPredicate(
                    identity,
                    formatMessage(
                      "StandardLoan.CompanyOwnerCard.equityNegativeError"
                    )
                  )
                )
              )
            )
          )
        ),
        equityFromLastYear: foldTenant(tenant, constOptionNoneValidator, () =>
          pipe(
            isOptionTrue(values.keepsAccountancy) && addInfoFromTaxReturn,
            boolean.fold(constOptionNoneValidator, () => amountValidator())
          )
        ),
        grossIncomes: foldTenant(tenant, constOptionNoneValidator, () =>
          pipe(
            isOptionTrue(values.keepsAccountancy) && addInfoFromTaxReturn,
            boolean.fold(constOptionNoneValidator, () => amountValidator())
          )
        ),
        r37PartialTaxBase: foldTenant(tenant, constOptionNoneValidator, () =>
          pipe(
            addInfoFromTaxReturn,
            boolean.fold(constOptionNoneValidator, amountValidator)
          )
        ),
        r39PartialTaxBase: foldTenant(tenant, constOptionNoneValidator, () =>
          pipe(
            addInfoFromTaxReturn,
            boolean.fold(constOptionNoneValidator, amountValidator)
          )
        ),
        r101BruttoIncomes: foldTenant(tenant, constOptionNoneValidator, () =>
          pipe(
            isOptionFalse(values.keepsAccountancy) && addInfoFromTaxReturn,
            boolean.fold(constOptionNoneValidator, amountValidator)
          )
        ),
        reInPersonalOwnership: foldTenant(
          tenant,
          constOptionNoneValidator,
          () =>
            pipe(
              needsRentFieldsValidation(values.r39PartialTaxBase),
              boolean.fold(constOptionNoneValidator, () =>
                definedNoExtract<boolean>()
              )
            )
        ),
        reCoOwned: foldTenant(tenant, constOptionNoneValidator, () =>
          pipe(
            isOptionTrue(values.reInPersonalOwnership),
            boolean.fold(constOptionNoneValidator, () =>
              definedNoExtract<boolean>()
            )
          )
        ),
        r201IncomeFromLease: foldTenant(tenant, constOptionNoneValidator, () =>
          pipe(
            needsRentFieldsValidation(values.r39PartialTaxBase),
            boolean.fold(constOptionNoneValidator, () => amountValidator())
          )
        ),
        reUsedAsCollateral: foldTenant(tenant, constOptionNoneValidator, () =>
          pipe(
            isOptionTrue(values.reInPersonalOwnership),
            boolean.fold(constOptionNoneValidator, () =>
              definedNoExtract<boolean>()
            )
          )
        ),
        incomeFromRentContract: foldTenant(
          tenant,
          constOptionNoneValidator,
          () =>
            pipe(
              needsRentFieldsValidation(values.r39PartialTaxBase),
              boolean.fold(constOptionNoneValidator, () => amountValidator())
            )
        ),
        isCooperativeOwnership: foldTenant(
          tenant,
          constOptionNoneValidator,
          () =>
            pipe(
              isOptionFalse(values.reInPersonalOwnership) &&
                needsRentFieldsValidation(values.r39PartialTaxBase),
              boolean.fold(constOptionNoneValidator, () =>
                definedNoExtract<boolean>()
              )
            )
        ),
        isApplicantDeclareAsUserInDeclaration: foldTenant(
          tenant,
          constOptionNoneValidator,
          () =>
            pipe(
              needsRentFieldsValidation(values.r39PartialTaxBase),
              boolean.fold(constOptionNoneValidator, () =>
                pipe(
                  isOptionTrue(values.isCooperativeOwnership),
                  boolean.fold(constOptionNoneValidator, () =>
                    definedNoExtract<boolean>()
                  )
                )
              )
            )
        ),
        tax: pipe(
          addInfoFromTaxReturn,
          boolean.fold(constOptionNoneValidator, () => SKOnlyAmountValidator)
        ),
        taxBase: pipe(
          addInfoFromTaxReturn,
          boolean.fold(constOptionNoneValidator, () => SKOnlyAmountValidator)
        ),
        businessGrossIncome: pipe(
          addInfoFromTaxReturn,
          boolean.fold(constOptionNoneValidator, () => SKOnlyAmountValidator)
        ),
        businessSector: foldTenant(
          tenant,
          () => definedNoExtract(),
          () =>
            pipe(
              isOptionTrue(values.addIco),
              boolean.fold(constOptionNoneValidator, () => definedNoExtract())
            )
        ),
        businessStartingDate: foldTenant(
          tenant,
          () =>
            notInTheFutureMonthYearValidator(
              formatMessage("StandardLoan.EmployedCard.startingDateError"),
              formatMessage("Form.fieldError.required")
            ),
          () =>
            pipe(
              isOptionTrue(values.addIco),
              boolean.fold(constOptionNoneValidator, () =>
                notInTheFutureMonthYearValidator(
                  formatMessage("StandardLoan.EmployedCard.startingDateError"),
                  formatMessage("Form.fieldError.required")
                )
              )
            )
        ),
        jobPosition: foldTenant(
          tenant,
          () => definedNoExtract(),
          constOptionNoneValidator
        ),
        country: pipe(
          isOptionTrue(values.addIco),
          boolean.fold(constOptionNoneValidator, () =>
            definedNoExtract<string>()
          )
        ),
        nameOfEntrepreneur: pipe(
          isOptionTrue(values.addIco),
          boolean.fold(constOptionNoneValidator, () =>
            validators.validateIfDefined(nonBlankString)
          )
        ),
        entrepreneurIco: pipe(
          shouldValidateCompanyIco(values.entrepreneurIco, values.addIco),
          boolean.fold(constOptionNoneValidator, () =>
            validators.validateIfDefined(validCompanyIco(false))
          )
        ),
        city: pipe(
          isOptionTrue(values.addIco),
          boolean.fold(constOptionNoneValidator, () =>
            validators.validateIfDefined(validAddressField(40))
          )
        ),
        street: pipe(
          isOptionTrue(values.addIco),
          boolean.fold(constOptionNoneValidator, () =>
            validators.validateIfDefined(validAddressField(40))
          )
        ),
        houseNr: pipe(
          isOptionTrue(values.addIco),
          boolean.fold(constOptionNoneValidator, () =>
            validators.validateIfDefined(validStreetNumber)
          )
        ),
        zipCode: pipe(
          isOptionTrue(values.addIco),
          boolean.fold(constOptionNoneValidator, () =>
            validators.validateIfDefined(nonBlankString)
          )
        ),
        phone: pipe(
          isOptionTrue(values.addIco),
          boolean.fold(constOptionNoneValidator, () =>
            validators.validateIfDefined(validPhone)
          )
        ),
      }),
    },
    {
      onSubmit: data => {
        return taskEither.fromIO(
          () =>
            (validatedValues.current = option.some({
              ...incomeData,
              companyInfo: option.some({
                ...getEmptyCompanyInfo(),
                companyName: !isOptionTrue(data.addIco)
                  ? pipe(
                      data.companyName,
                      NonEmptyString.decode,
                      option.fromEither
                    )
                  : option.none,
                companyIco: !isOptionTrue(data.addIco)
                  ? pipe(
                      data.companyIco,
                      NonEmptyString.decode,
                      option.fromEither
                    )
                  : option.none,
                equityPositive: data.equityPositive,
                businessSector: data.businessSector,
                businessStartingDate: data.businessStartingDate,
                country: data.country,
                city: data.city,
                street: data.street,
                houseNr: data.houseNr,
                zipCode: data.zipCode,
                phone: data.phone,
                nameOfEntrepreneur: data.nameOfEntrepreneur,
                entrepreneurIco: data.entrepreneurIco,
                addIco: data.addIco,
              }),
              incomeInfo: option.some({
                ...getEmptyIncomeInfo("TradesmanCoOperatingPerson"),
                keepsAccountancy: data.keepsAccountancy,
                equityFromLastYear: data.equityFromLastYear,
                grossIncomes: data.grossIncomes,
                r37PartialTaxBase: data.r37PartialTaxBase,
                r39PartialTaxBase: data.r39PartialTaxBase,
                r201IncomeFromLease: data.r201IncomeFromLease,
                r101BruttoIncomes: data.r101BruttoIncomes,
                reInPersonalOwnership: data.reInPersonalOwnership,
                isApplicantDeclareAsUserInDeclaration:
                  data.isApplicantDeclareAsUserInDeclaration,
                isCooperativeOwnership: data.isCooperativeOwnership,
                reCoOwned: data.reCoOwned,
                reUsedAsCollateral: data.reUsedAsCollateral,
                incomeFromRentContract: data.incomeFromRentContract,
                businessGrossIncome: data.businessGrossIncome,
                tax: data.tax,
                taxBase: data.taxBase,
                salaryCurrency: data.salaryCurrency,
                monthlyIncome: data.monthlyIncome,
                hasAdditionalIncome: data.hasAdditionalIncome,
                hasInfoFromTaxReturn: option.some(addInfoFromTaxReturn),
              }),
              contractInfo: option.some({
                ...getEmptyContractInfo(),
                jobPosition: data.jobPosition,
              }),
              allowanceInfo: option.some({ ...getEmptyAllowanceInfo() }),
            }))
        );
      },
    }
  );
  useEffect(() => {
    resetForm();
  }, [props.employmentType]);
  useControlledSubmit(props, handleSubmit, validatedValues);

  const employmentTypeReworked = isReworked(
    props.rework,
    "contractInfo",
    "employmentType"
  );
  const incomeSourceReworked = isReworked(
    props.rework,
    "incomeInfo",
    "incomeSource"
  );
  const disabled = !options.isEditing;
  const reworkDependent = employmentTypeReworked || incomeSourceReworked;

  const keepAccountancyReworked = isReworked(
    props.rework,
    "incomeInfo",
    "keepsAccountancy"
  );
  const reInPersonalOwnershipReworked = isReworked(
    props.rework,
    "incomeInfo",
    "reInPersonalOwnership"
  );
  const isCooperativeOwnershipReworked = isReworked(
    props.rework,
    "incomeInfo",
    "isCooperativeOwnership"
  );

  const [dataForClientWithoutIco] = useQuery(api.dataForClientWithoutIco);
  const clientPhone = pipe(
    dataForClientWithoutIco,
    remoteData.fold(
      () => option.none,
      () => option.none,
      value => value.phone
    )
  );
  const phoneValue = pipe(
    fieldProps("phone").value,
    option.fold(
      () => clientPhone,
      somePhoneValue => {
        if (somePhoneValue.length <= 4) {
          return clientPhone;
        } else {
          return option.some(somePhoneValue);
        }
      }
    )
  );

  const companySection = foldTenant(
    tenant,
    () =>
      !isOptionTrue(fieldProps("addIco").value) && (
        <CompanySectionSK
          companyIcoFieldProps={specialFieldsReworkFieldProps(
            fieldProps("companyIco"),
            reworkDependent,
            isReworked(props.rework, "companyInfo", "companyIco") &&
              initialValues.companyIco != "",
            disabled,
            props.reworkAll
          )}
          companyNameFieldProps={specialFieldsReworkFieldProps(
            fieldProps("companyName"),
            reworkDependent,
            isReworked(props.rework, "companyInfo", "companyName") &&
              initialValues.companyName != "",
            disabled,
            props.reworkAll
          )}
          businessSectorFieldProps={specialFieldsReworkFieldProps(
            fieldProps("businessSector"),
            reworkDependent,
            isReworked(props.rework, "companyInfo", "businessSector") &&
              option.isSome(initialValues.businessSector),
            disabled,
            props.reworkAll
          )}
          businessStartingDateFieldProps={specialFieldsReworkFieldProps(
            fieldProps("businessStartingDate"),
            reworkDependent,
            isReworked(props.rework, "companyInfo", "businessStartingDate") &&
              option.isSome(initialValues.businessStartingDate),
            disabled,
            props.reworkAll
          )}
          startingDateMinYear={startingDateMinYear}
          currentYear={currentYear}
          syncCompanyFields={syncCompanyFields}
          onCompanyIcoValidationChange={onCompanyIcoValidationChange}
          onCompanyNameValidationChange={onCompanyNameValidationChange}
        />
      ),
    () =>
      !isOptionTrue(fieldProps("addIco").value) && (
        <CompanySectionCZ
          companyIcoFieldProps={specialFieldsReworkFieldProps(
            fieldProps("companyIco"),
            reworkDependent,
            isReworked(props.rework, "companyInfo", "companyIco") &&
              initialValues.companyIco != "",
            disabled,
            props.reworkAll
          )}
          companyNameFieldProps={specialFieldsReworkFieldProps(
            fieldProps("companyName"),
            reworkDependent,
            isReworked(props.rework, "companyInfo", "companyName") &&
              initialValues.companyName != "",
            disabled,
            props.reworkAll
          )}
          syncCompanyFields={syncCompanyFields}
          onCompanyIcoValidationChange={onCompanyIcoValidationChange}
          onCompanyNameValidationChange={onCompanyNameValidationChange}
        />
      )
  );

  const incomeSection = foldTenant(
    tenant,
    () => (
      <IncomeSectionSK
        addInfoFromTaxReturn={addInfoFromTaxReturn}
        setAddInfoFromTaxReturn={setAddInfoFromTaxReturn}
        businessGrossIncomeFieldProps={specialFieldsReworkFieldProps(
          fieldProps("businessGrossIncome"),
          reworkDependent,
          isReworked(props.rework, "incomeInfo", "businessGrossIncome") &&
            option.isSome(initialValues.businessGrossIncome),
          disabled,
          props.reworkAll
        )}
        taxFieldProps={specialFieldsReworkFieldProps(
          fieldProps("tax"),
          reworkDependent,
          isReworked(props.rework, "incomeInfo", "tax") &&
            option.isSome(initialValues.tax),
          disabled,
          props.reworkAll
        )}
        taxBaseFieldProps={specialFieldsReworkFieldProps(
          fieldProps("taxBase"),
          reworkDependent,
          isReworked(props.rework, "incomeInfo", "taxBase") &&
            option.isSome(initialValues.taxBase),
          disabled,
          props.reworkAll
        )}
        hasAdditionalIncome={specialFieldsReworkFieldProps(
          fieldProps("hasAdditionalIncome"),
          reworkDependent,
          isReworked(props.rework, "incomeInfo", "hasAdditionalIncome") &&
            option.isSome(initialValues.hasAdditionalIncome),
          disabled,
          props.reworkAll
        )}
        isMainIncome={props.isMainIncome}
        isEditing={props.options.isEditing}
      />
    ),
    () => (
      <IncomeSectionCZ
        addInfoFromTaxReturn={addInfoFromTaxReturn}
        setAddInfoFromTaxReturn={setAddInfoFromTaxReturn}
        equityFromLastYearFieldProps={specialFieldsReworkFieldProps(
          fieldProps("equityFromLastYear"),
          reworkDependent || keepAccountancyReworked,
          isReworked(props.rework, "incomeInfo", "equityFromLastYear") &&
            option.isSome(initialValues.equityFromLastYear),
          disabled,
          props.reworkAll
        )}
        equityPositiveFieldProps={specialFieldsReworkFieldProps(
          fieldProps("equityPositive"),
          reworkDependent || keepAccountancyReworked,
          isReworked(props.rework, "companyInfo", "equityPositive") &&
            option.isSome(initialValues.equityPositive),
          !keepAccountancyReworked && disabled,
          props.reworkAll
        )}
        grossIncomesFieldProps={specialFieldsReworkFieldProps(
          fieldProps("grossIncomes"),
          reworkDependent || keepAccountancyReworked,
          isReworked(props.rework, "incomeInfo", "grossIncomes") &&
            option.isSome(initialValues.grossIncomes),
          disabled,
          props.reworkAll
        )}
        incomeFromRentContractFieldProps={specialFieldsReworkFieldProps(
          fieldProps("incomeFromRentContract"),
          reworkDependent || keepAccountancyReworked,
          isReworked(props.rework, "incomeInfo", "incomeFromRentContract") &&
            option.isSome(initialValues.incomeFromRentContract),
          disabled,
          props.reworkAll
        )}
        keepsAccountancyFieldProps={specialFieldsReworkFieldProps(
          fieldProps("keepsAccountancy"),
          reworkDependent,
          isReworked(props.rework, "incomeInfo", "keepsAccountancy") &&
            option.isSome(initialValues.keepsAccountancy),
          disabled,
          props.reworkAll
        )}
        r101BruttoIncomesFieldProps={specialFieldsReworkFieldProps(
          fieldProps("r101BruttoIncomes"),
          reworkDependent || keepAccountancyReworked,
          isReworked(props.rework, "incomeInfo", "r101BruttoIncomes") &&
            option.isSome(initialValues.r101BruttoIncomes),
          disabled,
          props.reworkAll
        )}
        r201IncomeFromLeaseFieldProps={specialFieldsReworkFieldProps(
          fieldProps("r201IncomeFromLease"),
          reworkDependent || keepAccountancyReworked,
          isReworked(props.rework, "incomeInfo", "r201IncomeFromLease") &&
            option.isSome(initialValues.r201IncomeFromLease),
          disabled,
          props.reworkAll
        )}
        r37PartialTaxBaseFieldProps={specialFieldsReworkFieldProps(
          fieldProps("r37PartialTaxBase"),
          reworkDependent || keepAccountancyReworked,
          isReworked(props.rework, "incomeInfo", "r37PartialTaxBase") &&
            option.isSome(initialValues.r37PartialTaxBase),
          disabled,
          props.reworkAll
        )}
        r39PartialTaxBaseFieldProps={specialFieldsReworkFieldProps(
          fieldProps("r39PartialTaxBase"),
          reworkDependent || keepAccountancyReworked,
          isReworked(props.rework, "incomeInfo", "r39PartialTaxBase") &&
            option.isSome(initialValues.r39PartialTaxBase),
          disabled,
          props.reworkAll
        )}
        reCoOwned={specialFieldsReworkFieldProps(
          fieldProps("reCoOwned"),
          reworkDependent || keepAccountancyReworked,
          isReworked(props.rework, "incomeInfo", "reCoOwned") &&
            option.isSome(initialValues.reCoOwned),
          disabled,
          props.reworkAll
        )}
        reInPersonalOwnershipFieldProps={specialFieldsReworkFieldProps(
          fieldProps("reInPersonalOwnership"),
          reworkDependent || keepAccountancyReworked,
          isReworked(props.rework, "incomeInfo", "reInPersonalOwnership") &&
            option.isSome(initialValues.reInPersonalOwnership),
          disabled,
          props.reworkAll
        )}
        reUsedAsCollateralFieldProps={specialFieldsReworkFieldProps(
          fieldProps("reUsedAsCollateral"),
          reworkDependent || keepAccountancyReworked,
          isReworked(props.rework, "incomeInfo", "reUsedAsCollateral") &&
            option.isSome(initialValues.reUsedAsCollateral),
          disabled,
          props.reworkAll
        )}
        isCooperativeOwnership={specialFieldsReworkFieldProps(
          fieldProps("isCooperativeOwnership"),
          reworkDependent ||
            keepAccountancyReworked ||
            reInPersonalOwnershipReworked,
          isReworked(props.rework, "incomeInfo", "isCooperativeOwnership") &&
            option.isSome(initialValues.isCooperativeOwnership),
          disabled,
          props.reworkAll
        )}
        isApplicantDeclareAsUserInDeclaration={specialFieldsReworkFieldProps(
          fieldProps("isApplicantDeclareAsUserInDeclaration"),
          reworkDependent ||
            keepAccountancyReworked ||
            reInPersonalOwnershipReworked ||
            isCooperativeOwnershipReworked,
          isReworked(
            props.rework,
            "incomeInfo",
            "isApplicantDeclareAsUserInDeclaration"
          ) &&
            option.isSome(initialValues.isApplicantDeclareAsUserInDeclaration),
          disabled,
          props.reworkAll
        )}
        hasAdditionalIncome={specialFieldsReworkFieldProps(
          fieldProps("hasAdditionalIncome"),
          reworkDependent,
          isReworked(props.rework, "incomeInfo", "hasAdditionalIncome") &&
            option.isSome(initialValues.hasAdditionalIncome),
          disabled,
          props.reworkAll
        )}
        isMainIncome={props.isMainIncome}
        isEditing={props.options.isEditing}
      />
    )
  );

  return (
    <Stack column grow shrink units={6}>
      {foldTenant(
        tenant,
        () => (
          <FormSection>
            <FormRow type="full">
              <JobPositionDropdown
                fieldProps={reworkFieldProps(
                  fieldProps("jobPosition"),
                  "contractInfo",
                  disabled
                )}
              />
            </FormRow>
          </FormSection>
        ),
        constNull
      )}
      <ClientWithoutICO
        incomeSourceType="TradesmanCoOperatingPerson"
        fieldProps={fieldProps}
        rework={props.rework}
        reworkAll={props.reworkAll}
        initialValues={initialValues}
        options={options}
        onSelectCitySuggestion={suggestion => {
          setFormState({
            city: option.some(suggestion),
          });
        }}
        onSelectStreetSuggestion={suggestion => {
          setFormState({
            street: option.some(suggestion),
          });
        }}
        dataForClientWithoutIco={dataForClientWithoutIco}
        phoneValue={phoneValue}
      />
      {companySection}
      {incomeSection}
    </Stack>
  );
}

function needsRentFieldsValidation(r39: Option<number>) {
  return pipe(
    r39,
    option.exists(value => value !== 0)
  );
}
