import { Stack, useForm, validators } from "design-system";
import { boolean, option, taskEither } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { useRef, useEffect, useState } from "react";
import { foldTenant } from "../../../../globalDomain";
import { useFormatMessage } from "../../../../intl";
import { useAppContext } from "../../../../useAppContext";
import { CompanySuggestion } from "../../../../Common/CompanyField/domain";
import { useValidators } from "../../../../Common/useValidators";
import {
  isReworked,
  useReworkComparator,
} from "../../../Rework/useReworkComparator";
import { EmploymentType } from "../../api";
import {
  IncomeCardProps,
  useControlledSubmit,
} from "../../commons/useControlledSubmit";
import {
  constOptionNoneValidator,
  getCompanyIcoWithDefault,
  getCompanyNameWithDefault,
  getEmptyAllowanceInfo,
  getEmptyCompanyInfo,
  getEmptyContractInfo,
  getEmptyIncomeInfo,
  notInTheFutureMonthYearValidator,
  useUniqueCompanyValidators,
} from "../../commons/Utils";
import {
  IncomeData,
  isOptionTrue,
  PaymentMethod,
  useFormatPaymentMethod,
} from "../../../../StandardLoan/IncomeForm/domain";
import { CompanySection } from "./CompanySection";
import { ContractSection } from "./ContractSection/ContractSection";
import { IncomeSection } from "./IncomeSection/IncomeSection";

export function CompanyOwnerCard(props: IncomeCardProps) {
  const { incomeData, options } = props;
  const {
    definedNoExtract,
    validCompanyNameRequired,
    validCompanyIcoRequired,
    amountMinMax,
    nonNegativeNumber,
  } = useValidators();
  const formatMessage = useFormatMessage();
  const {
    apiParameters: { tenant },
  } = useAppContext();
  validators.inSequence(
    definedNoExtract<number>(),
    validators.validateIfDefined(nonNegativeNumber)
  );
  const validatedValues = useRef<Option<IncomeData>>(option.none);

  const syncCompanyFields = (suggestion: CompanySuggestion) =>
    setFormState({
      companyName: suggestion.name,
      companyIco: suggestion.id,
    });

  const [
    uniqueCompanyNameValidator,
    uniqueCompanyIcoValidator,
  ] = useUniqueCompanyValidators(options);

  const [companyNameIsValid, setCompanyNameIsValid] = useState(true);
  const [companyIcoIsValid, setCompanyIcoIsValid] = useState(true);

  const { specialFieldsReworkFieldProps } = useReworkComparator(props.rework);
  const formatPaymentMethod = useFormatPaymentMethod();
  const initialValues = {
    salaryCurrency: props.salaryCurrency,
    monthlyIncome: props.monthlyIncome,
    companyName: getCompanyNameWithDefault(incomeData),
    companyIco: getCompanyIcoWithDefault(incomeData),
    stakeInCompany: pipe(
      incomeData.companyInfo,
      option.chain(data => data.stakeInCompany)
    ),
    equityPositive: pipe(
      incomeData.companyInfo,
      option.chain(data => data.equityPositive)
    ),
    isProfitPeriodPositive: pipe(
      incomeData.incomeInfo,
      option.chain(data => data.isProfitPeriodPositive),
      option.alt(() => option.some(true))
    ),
    employedInCompany: pipe(
      incomeData.incomeInfo,
      option.chain(data => data.employedInCompany),
      option.alt(() => option.some(true))
    ),
    startingDate: pipe(
      incomeData.contractInfo,
      option.chain(data => data.startingDate)
    ),
    inProbation: pipe(
      incomeData.contractInfo,
      option.chain(data => data.inProbation)
    ),
    contractIndefinitePeriod: pipe(
      incomeData.contractInfo,
      option.chain(data => data.contractIndefinitePeriod),
      option.alt(() => option.some(true))
    ),
    workBasedOnAgreementOnWorkingActivity: pipe(
      incomeData.contractInfo,
      option.chain(data => data.workBasedOnAgreementOnWorkingActivity)
    ),
    paymentMethod: pipe(
      incomeData.contractInfo,
      option.chain(data => data.paymentMethod)
    ),
    employmentType: pipe(
      incomeData.contractInfo,
      option.chain(data => data.employmentType)
    ),
    jobPosition: pipe(
      incomeData.contractInfo,
      option.chain(data => data.jobPosition)
    ),
    hasAdditionalIncome: pipe(
      incomeData.incomeInfo,
      option.chain(data => data.hasAdditionalIncome),
      option.alt(() => option.some(false))
    ),
  };
  const {
    fieldProps,
    setValues: setFormState,
    handleSubmit,
    setTouched,
    resetForm,
  } = useForm(
    {
      initialValues: initialValues,
      fieldValidators: values => ({
        salaryCurrency: () => definedNoExtract<string>()(props.salaryCurrency),
        monthlyIncome: () =>
          validators.inSequence(
            definedNoExtract<number>(),
            validators.validateIfDefined(nonNegativeNumber)
          )(props.monthlyIncome),
        companyName: validators.inSequence(
          validCompanyNameRequired(companyNameIsValid),
          uniqueCompanyNameValidator(values.companyName)
        ),
        companyIco: validators.inSequence(
          validCompanyIcoRequired(false, companyIcoIsValid),
          uniqueCompanyIcoValidator(values.companyIco)
        ),
        stakeInCompany: validators.inSequence(
          definedNoExtract<number>(),
          validators.validateIfDefined(amountMinMax(0, 100))
        ),
        equityPositive: validators.inSequence(
          definedNoExtract(),
          validators.validateIfDefined(
            validators.fromPredicate(
              equity => equity === true,
              formatMessage("StandardLoan.CompanyOwnerCard.equityNegativeError")
            )
          )
        ),
        employedInCompany: validators.inSequence(
          definedNoExtract<boolean>(),
          validators.validateIfDefined(
            validators.fromPredicate(
              inCompany => inCompany === true,
              formatMessage(
                "StandardLoan.CompanyOwnerCard.employedInCompanyNegativeError"
              )
            )
          )
        ),
        startingDate: pipe(
          isOptionTrue(values.employedInCompany),
          boolean.fold(constOptionNoneValidator, () =>
            notInTheFutureMonthYearValidator(
              formatMessage(
                "StandardLoan.CompanyOwnerCard.invalidStartingDate"
              ),
              formatMessage("Form.fieldError.required")
            )
          )
        ),
        inProbation: pipe(
          isOptionTrue(values.employedInCompany),
          boolean.fold(constOptionNoneValidator, () =>
            definedNoExtract<boolean>()
          )
        ),
        contractIndefinitePeriod: pipe(
          isOptionTrue(values.employedInCompany),
          boolean.fold(constOptionNoneValidator, () =>
            foldTenant(tenant, constOptionNoneValidator, () =>
              validators.inSequence(
                definedNoExtract(),
                validators.validateIfDefined(
                  validators.fromPredicate(
                    inCompany => inCompany,
                    formatMessage(
                      "StandardLoan.CompanyOwnerCard.noIndefinitePeriodContract"
                    )
                  )
                )
              )
            )
          )
        ),
        workBasedOnAgreementOnWorkingActivity: foldTenant(
          tenant,
          constOptionNoneValidator,
          () =>
            pipe(
              isOptionTrue(values.employedInCompany),
              boolean.fold(constOptionNoneValidator, () =>
                definedNoExtract<boolean>()
              )
            )
        ),
        paymentMethod: pipe(
          isOptionTrue(values.employedInCompany),
          boolean.fold(constOptionNoneValidator, () =>
            definedNoExtract<PaymentMethod>()
          )
        ),

        employmentType: foldTenant(
          tenant,
          () =>
            pipe(
              isOptionTrue(values.employedInCompany),
              boolean.fold(constOptionNoneValidator, () =>
                validators.inSequence(
                  definedNoExtract<EmploymentType>(),
                  validators.validateIfDefined(
                    validators.fromPredicate(
                      typeOfEmployment =>
                        typeOfEmployment !== "LimitedContract",
                      formatMessage(
                        "StandardLoan.CompanyOwnerCard.limitedContract"
                      )
                    )
                  )
                )
              )
            ),
          constOptionNoneValidator
        ),
        jobPosition: foldTenant(
          tenant,
          () =>
            pipe(
              isOptionTrue(values.employedInCompany),
              boolean.fold(constOptionNoneValidator, () =>
                definedNoExtract<NonEmptyString>()
              )
            ),
          constOptionNoneValidator
        ),
      }),
    },
    {
      onSubmit: data =>
        taskEither.fromIO(
          () =>
            (validatedValues.current = option.some({
              ...incomeData,
              companyInfo: option.some({
                ...getEmptyCompanyInfo(),
                companyName: pipe(
                  data.companyName,
                  NonEmptyString.decode,
                  option.fromEither
                ),
                companyIco: pipe(
                  data.companyIco,
                  NonEmptyString.decode,
                  option.fromEither
                ),
                stakeInCompany: data.stakeInCompany,
                equityPositive: data.equityPositive,
              }),
              incomeInfo: option.some({
                ...getEmptyIncomeInfo("CompanyOwner"),
                employedInCompany: data.employedInCompany,
                isProfitPeriodPositive: data.isProfitPeriodPositive,
                salaryCurrency: data.salaryCurrency,
                monthlyIncome: data.monthlyIncome,
                hasAdditionalIncome: data.hasAdditionalIncome,
              }),
              contractInfo: option.some({
                ...getEmptyContractInfo(),
                startingDate: data.startingDate,
                inProbation: data.inProbation,
                contractIndefinitePeriod: data.contractIndefinitePeriod,
                workBasedOnAgreementOnWorkingActivity:
                  data.workBasedOnAgreementOnWorkingActivity,
                paymentMethod: data.paymentMethod,
                paymentMethodLabel: pipe(
                  data.paymentMethod,
                  option.map(paymentMethod =>
                    formatPaymentMethod(paymentMethod)
                  )
                ),
                employmentType: data.employmentType,
                jobPosition: data.jobPosition,
              }),
              allowanceInfo: option.some({ ...getEmptyAllowanceInfo() }),
            }))
        ),
    }
  );

  useEffect(() => {
    resetForm();
  }, [props.employmentType]);

  useControlledSubmit(props, handleSubmit, validatedValues);
  setTouched({ contractIndefinitePeriod: true });

  const employmentTypeReworked = isReworked(
    props.rework,
    "contractInfo",
    "employmentType"
  );
  const incomeSourceReworked = isReworked(
    props.rework,
    "incomeInfo",
    "incomeSource"
  );
  const disabled = !options.isEditing;
  const reworkDependent = employmentTypeReworked || incomeSourceReworked;

  const employedInCompanyReworked = isReworked(
    props.rework,
    "incomeInfo",
    "employedInCompany"
  );
  const onCompanyNameValidationChange = (isValid: boolean) => {
    setCompanyNameIsValid(isValid);
  };
  const onCompanyIcoValidationChange = (isValid: boolean) => {
    setCompanyIcoIsValid(isValid);
  };

  return (
    <Stack column grow shrink units={6}>
      <CompanySection
        companyIcoFieldProps={specialFieldsReworkFieldProps(
          fieldProps("companyIco"),
          reworkDependent,
          isReworked(props.rework, "companyInfo", "companyIco") &&
            initialValues.companyIco != "",
          disabled,
          props.reworkAll
        )}
        companyNameFieldProps={specialFieldsReworkFieldProps(
          fieldProps("companyName"),
          reworkDependent,
          isReworked(props.rework, "companyInfo", "companyName") &&
            initialValues.companyName != "",
          disabled,
          props.reworkAll
        )}
        syncCompanyFields={syncCompanyFields}
        stakeInCompany={specialFieldsReworkFieldProps(
          fieldProps("stakeInCompany"),
          reworkDependent,
          isReworked(props.rework, "companyInfo", "stakeInCompany") &&
            option.isSome(initialValues.stakeInCompany),
          disabled,
          props.reworkAll
        )}
        onCompanyNameValidationChange={onCompanyNameValidationChange}
        onCompanyIcoValidationChange={onCompanyIcoValidationChange}
      />
      <IncomeSection
        equityPositiveFieldProps={specialFieldsReworkFieldProps(
          fieldProps("equityPositive"),
          reworkDependent,
          isReworked(props.rework, "companyInfo", "equityPositive") &&
            option.isSome(initialValues.equityPositive),
          disabled,
          props.reworkAll
        )}
        isProfitPeriodPositive={specialFieldsReworkFieldProps(
          fieldProps("isProfitPeriodPositive"),
          reworkDependent,
          isReworked(props.rework, "incomeInfo", "isProfitPeriodPositive") &&
            option.isSome(initialValues.isProfitPeriodPositive),
          disabled,
          props.reworkAll
        )}
        hasAdditionalIncome={specialFieldsReworkFieldProps(
          fieldProps("hasAdditionalIncome"),
          reworkDependent,
          isReworked(props.rework, "incomeInfo", "hasAdditionalIncome") &&
            option.isSome(initialValues.hasAdditionalIncome),
          disabled,
          props.reworkAll
        )}
        isMainIncome={props.isMainIncome}
      />
      <ContractSection
        employmentTypeFieldProps={specialFieldsReworkFieldProps(
          fieldProps("employmentType"),
          reworkDependent || employedInCompanyReworked,
          isReworked(props.rework, "contractInfo", "employmentType") &&
            option.isSome(initialValues.employmentType),
          disabled,
          props.reworkAll
        )}
        jobPositionFieldProps={specialFieldsReworkFieldProps(
          fieldProps("jobPosition"),
          reworkDependent || employedInCompanyReworked,
          isReworked(props.rework, "contractInfo", "jobPosition") &&
            option.isSome(initialValues.jobPosition),
          disabled,
          props.reworkAll
        )}
        contractIndefinitePeriodFieldProps={specialFieldsReworkFieldProps(
          fieldProps("contractIndefinitePeriod"),
          reworkDependent || employedInCompanyReworked,
          isReworked(
            props.rework,
            "contractInfo",
            "contractIndefinitePeriod"
          ) && option.isSome(initialValues.contractIndefinitePeriod),
          disabled,
          props.reworkAll
        )}
        employedInCompanyFieldProps={specialFieldsReworkFieldProps(
          fieldProps("employedInCompany"),
          reworkDependent,
          isReworked(props.rework, "incomeInfo", "employedInCompany") &&
            option.isSome(initialValues.employedInCompany),
          disabled,
          props.reworkAll
        )}
        inProbationFieldProps={specialFieldsReworkFieldProps(
          fieldProps("inProbation"),
          reworkDependent || employedInCompanyReworked,
          isReworked(props.rework, "contractInfo", "inProbation") &&
            option.isSome(initialValues.inProbation),
          disabled,
          props.reworkAll
        )}
        paymentMethodFieldProps={specialFieldsReworkFieldProps(
          fieldProps("paymentMethod"),
          reworkDependent || employedInCompanyReworked,
          isReworked(props.rework, "contractInfo", "paymentMethod") &&
            option.isSome(initialValues.paymentMethod),
          disabled,
          props.reworkAll
        )}
        startingDateFieldProps={specialFieldsReworkFieldProps(
          fieldProps("startingDate"),
          reworkDependent || employedInCompanyReworked,
          isReworked(props.rework, "contractInfo", "startingDate") &&
            option.isSome(initialValues.startingDate),
          disabled,
          props.reworkAll
        )}
        workBasedOnAgreementOnWorkingActivityFieldProps={specialFieldsReworkFieldProps(
          fieldProps("workBasedOnAgreementOnWorkingActivity"),
          reworkDependent || employedInCompanyReworked,
          isReworked(
            props.rework,
            "contractInfo",
            "workBasedOnAgreementOnWorkingActivity"
          ) &&
            option.isSome(initialValues.workBasedOnAgreementOnWorkingActivity),
          disabled,
          props.reworkAll
        )}
        isEquityPositive={fieldProps("equityPositive").value}
        stakeInCompany={fieldProps("stakeInCompany")}
      />
    </Stack>
  );
}
