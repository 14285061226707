import {
  Box,
  ContentRow,
  ErrorBanner,
  FeedbackBlock,
  Heading,
  Loader,
  Space,
  TextChildren,
} from "design-system";
import { useFormatMessage } from "../../../intl";
import { StandardLoanFlowType } from "../../domain";
import * as classes from "./EmptyPanel.treat";
import cx from "classnames";
import { option } from "fp-ts";
import { UnableToProceed } from "../HighRiskClient/UnableToProceed";

type Props = {
  status: "loading" | "error";
  flowType: StandardLoanFlowType;
  isRiskyClient: boolean;
};

export function CheckingContractsLoader(props: Props) {
  const formatMessage = useFormatMessage();

  const errorMessage = ((): TextChildren => {
    switch (props.flowType) {
      case "TLSAgent":
        return formatMessage("StandardLoan.ClientStatus.Error.TLSAgent");
      case "InPerson":
      case "3P":
      case "HomeBanking":
      case "SmartBanking":
      case "PWSRemote":
        return formatMessage("GenericError");
    }
  })();

  return props.isRiskyClient ? (
    props.status === "loading" ? (
      <Loader />
    ) : (
      <UnableToProceed />
    )
  ) : (
    <ContentRow type="lateral-margins">
      <Box column grow shrink>
        <Space units={8} />
        <Heading size="medium" weight="regular">
          {formatMessage(
            "StandardLoan.FollowUpAndSignature.LowRiskClient.Complete.title"
          )}
        </Heading>
        <Space units={8} />
        <Box
          className={cx(classes.panel, classes.panelVariants["active"])}
          column
          grow
          shrink
          hAlignContent="center"
        >
          <Space units={8} />
          <Heading size="medium" weight="medium" align="center">
            {formatMessage(
              "StandardLoan.FollowUpAndSignature.LowRiskClient.Complete.checkingLabel"
            )}
          </Heading>
          {props.status === "loading" ? (
            <FeedbackBlock
              inlineLoaderMessage={formatMessage(
                "StandardLoan.FollowUpAndSignature.LowRiskClient.Complete.waitLabel"
              )}
              heading={formatMessage(
                "StandardLoan.FollowUpAndSignature.LowRiskClient.Complete.waitLabel"
              )}
              subheading={option.some(
                formatMessage(
                  "StandardLoan.FollowUpAndSignature.LowRiskClient.Complete.gatheringLabel"
                )
              )}
              size="medium"
              type="loading"
            />
          ) : (
            <>
              <Space units={8} />
              <ErrorBanner width="80%" children={errorMessage} />
              <Space units={8} />
            </>
          )}
        </Box>
      </Box>
    </ContentRow>
  );
}
