import * as t from "io-ts";

export const TypeOfRent = t.keyof(
  {
    RentFromOtherRealEstate: true,
    RentFromPledgedRealEstate: true,
  },
  "TypeOfRent"
);

export type TypeOfRent = t.TypeOf<typeof TypeOfRent>;
