import { eq } from "fp-ts";
import { constFalse } from "fp-ts/function";
import * as t from "io-ts";
import { apiCall } from "../../APICall";
import { NonNegativeInteger } from "design-system";
import { optionFromUndefined, Tenant } from "../../globalDomain";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";

export const CurrenciesOutput = t.array(t.string);
export const currencies = apiCall({
  path: ["packages", "loans", "standard-loan", "income", "currencies"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: CurrenciesOutput,
});

export const FreelancerType = t.type(
  {
    key: NonEmptyString,
    tenant: Tenant,
    name: NonEmptyString,
  },
  "FreelancerType"
);

export type FreelancerType = t.TypeOf<typeof FreelancerType>;

export const FreelancerTypesOutput = t.array(FreelancerType);

export const freelancerTypes = apiCall({
  path: ["packages", "loans", "standard-loan", "income", "freelancerTypes"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: FreelancerTypesOutput,
});

export type DataForClientWithoutIcoOutput = t.TypeOf<
  typeof DataForClientWithoutIcoOutput
>;

export const DataForClientWithoutIcoOutput = t.type(
  {
    phone: optionFromUndefined(t.string),
    country: optionFromUndefined(t.string),
    city: optionFromUndefined(t.string),
    street: optionFromUndefined(t.string),
    houseNumber: optionFromUndefined(t.string),
    zipCode: optionFromUndefined(t.string),
  },
  "DataForClientWithoutIcoOutput"
);

export const dataForClientWithoutIco = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "income",
    "dataForClientWithoutIco",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: DataForClientWithoutIcoOutput,
});

export const JobPosition = t.type(
  {
    id: NonNegativeInteger,
    key: NonEmptyString,
    tenant: Tenant,
    language: NonEmptyString,
    name: NonEmptyString,
  },
  "JobPosition"
);

export type JobPosition = t.TypeOf<typeof JobPosition>;

export const JobPositionsOutput = t.array(JobPosition);

export const jobPositions = apiCall({
  path: ["packages", "loans", "standard-loan", "income", "jobPositions"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: JobPositionsOutput,
});

export const EmploymentType = t.keyof(
  {
    IndefiniteContract: true,
    LimitedContract: true,
    Agreement: true,
    SeasonalContract: true,
    Other: true,
  },
  "EmploymentType"
);

export type EmploymentType = t.TypeOf<typeof EmploymentType>;

export const AllowanceType = t.keyof(
  {
    Parental: true,
    FutureParental: true,
  },
  "AllowanceType"
);
export type AllowanceType = t.TypeOf<typeof AllowanceType>;

export const BusinessSector = t.type(
  {
    id: NonNegativeInteger,
    key: NonEmptyString,
    tenant: Tenant,
    language: NonEmptyString,
    name: NonEmptyString,
  },
  "BusinessSector"
);

export type BusinessSector = t.TypeOf<typeof BusinessSector>;

const BusinessSectorsOutput = t.array(BusinessSector);

export const businessSectors = apiCall({
  path: ["packages", "loans", "standard-loan", "income", "businessSectors"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: BusinessSectorsOutput,
});
