import { YesNoRadioGroupField } from "../../../../../Common/YesNoRadioGroup/YesNoRadioGroupField";
import {
  FormRow,
  FormSection,
  ComputedFieldProps,
  Box,
  Button,
  PlusIcon,
} from "design-system";
import { useFormatMessage } from "../../../../../intl";
import { Option } from "fp-ts/Option";
import { Dispatch, SetStateAction, useMemo } from "react";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";

import { MoneyField } from "../../../../../Common/Price/MoneyField/MoneyField";
import { useTenantCurrency } from "../../../../../Common/useTenantCurrency";
import { R37Field } from "./Fields/R37Field";
import { R39Field } from "./Fields/R39Field";
import { R101Field } from "./Fields/R101Field";
import { RentFields } from "./Fields/RentFields";

type PropsCZ = {
  keepsAccountancyFieldProps: ComputedFieldProps<Option<boolean>>;
  equityPositiveFieldProps: ComputedFieldProps<Option<boolean>>;
  equityFromLastYearFieldProps: ComputedFieldProps<Option<number>>;
  grossIncomesFieldProps: ComputedFieldProps<Option<number>>;
  r37PartialTaxBaseFieldProps: ComputedFieldProps<Option<number>>;
  r39PartialTaxBaseFieldProps: ComputedFieldProps<Option<number>>;
  r201IncomeFromLeaseFieldProps: ComputedFieldProps<Option<number>>;
  r101BruttoIncomesFieldProps: ComputedFieldProps<Option<number>>;
  reInPersonalOwnershipFieldProps: ComputedFieldProps<Option<boolean>>;
  reUsedAsCollateralFieldProps: ComputedFieldProps<Option<boolean>>;
  reCoOwned: ComputedFieldProps<Option<boolean>>;
  incomeFromRentContractFieldProps: ComputedFieldProps<Option<number>>;
  isCooperativeOwnership: ComputedFieldProps<Option<boolean>>;
  isApplicantDeclareAsUserInDeclaration: ComputedFieldProps<Option<boolean>>;
  hasAdditionalIncome: ComputedFieldProps<Option<boolean>>;
  addInfoFromTaxReturn: boolean;
  setAddInfoFromTaxReturn: Dispatch<SetStateAction<boolean>>;
  isMainIncome: boolean;
  isEditing: boolean;
};

export function IncomeSectionCZ(props: PropsCZ) {
  const formatMessage = useFormatMessage();
  const currency = useTenantCurrency();

  const MIN_MONEY_VALIDATION = -999999999;
  const MAX_MONEY_VALIDATION = 999999999;

  const onAddInfoFromTaxReturn = () => {
    props.setAddInfoFromTaxReturn(true);
  };
  const onCloseAddInfoFromTaxReturnSection = () => {
    props.setAddInfoFromTaxReturn(false);
  };

  const keepAccountancy = useMemo(() => {
    return pipe(props.keepsAccountancyFieldProps.value, option.toNullable);
  }, [props.keepsAccountancyFieldProps]);

  const equityPositive = useMemo(() => {
    return pipe(props.equityPositiveFieldProps.value, option.toNullable);
  }, [props.equityPositiveFieldProps]);

  const r39Value = useMemo(() => {
    return pipe(
      props.r39PartialTaxBaseFieldProps.value,
      option.getOrElse(() => 0)
    );
  }, [props.r39PartialTaxBaseFieldProps]);

  const r39AdditionalFields = (
    <RentFields
      currency={currency}
      r201IncomeFromLeaseFieldProps={props.r201IncomeFromLeaseFieldProps}
      reInPersonalOwnershipFieldProps={props.reInPersonalOwnershipFieldProps}
      reUsedAsCollateralFieldProps={props.reUsedAsCollateralFieldProps}
      reCoOwned={props.reCoOwned}
      incomeFromRentContractFieldProps={props.incomeFromRentContractFieldProps}
      isCooperativeOwnership={props.isCooperativeOwnership}
      isApplicantDeclareAsUserInDeclaration={
        props.isApplicantDeclareAsUserInDeclaration
      }
    />
  );

  return (
    <FormSection
      heading={{
        title: formatMessage("StandardLoan.Tradesman.IncomeSection.title"),
      }}
    >
      <FormRow type="full">
        <YesNoRadioGroupField
          {...props.keepsAccountancyFieldProps}
          label={formatMessage(
            "StandardLoan.Tradesman.IncomeSection.isKeepingAccountancy"
          )}
          description={formatMessage(
            "StandardLoan.Tradesman.IncomeSection.isKeepingAccountancyDescription"
          )}
        />
      </FormRow>
      {props.isMainIncome && (
        <FormRow type="full">
          <YesNoRadioGroupField
            {...props.hasAdditionalIncome}
            label={formatMessage("StandardLoan.additionalIncome")}
            description={formatMessage(
              "StandardLoan.additionalIncome.description"
            )}
          />
        </FormRow>
      )}
      {keepAccountancy && [
        <FormRow type="full">
          <YesNoRadioGroupField
            {...props.equityPositiveFieldProps}
            label={formatMessage(
              "StandardLoan.Tradesman.IncomeSection.isEquityPositiveLabel"
            )}
            description={formatMessage(
              "StandardLoan.Tradesman.IncomeSection.isEquityPositiveDescription"
            )}
          />
        </FormRow>,
        props.addInfoFromTaxReturn && [
          <FormRow type="full">
            <Box>
              <Button
                label={formatMessage("Cancel")}
                variant="text"
                size="default"
                action={onCloseAddInfoFromTaxReturnSection}
                disabled={!props.isEditing}
              />
            </Box>
          </FormRow>,
          props.addInfoFromTaxReturn &&
            equityPositive && [
              <FormRow type="full">
                <MoneyField
                  {...props.equityFromLastYearFieldProps}
                  currency={currency}
                  label={formatMessage(
                    "StandardLoan.Tradesman.IncomeSection.lastYearEquityAmountLabel"
                  )}
                  placeholder={formatMessage(
                    "StandardLoan.Tradesman.IncomeSection.lastYearEquityAmountPlaceholder"
                  )}
                  description={formatMessage(
                    "StandardLoan.Tradesman.IncomeSection.lastYearEquityAmountDescription"
                  )}
                  min={MIN_MONEY_VALIDATION}
                  max={MAX_MONEY_VALIDATION}
                />
              </FormRow>,
              <FormRow type="full">
                <MoneyField
                  {...props.grossIncomesFieldProps}
                  currency={currency}
                  label={formatMessage(
                    "StandardLoan.Tradesman.IncomeSection.grossIncomes"
                  )}
                  placeholder={formatMessage(
                    "StandardLoan.Tradesman.IncomeSection.grossIncomesPlaceholder"
                  )}
                  min={MIN_MONEY_VALIDATION}
                  max={MAX_MONEY_VALIDATION}
                />
              </FormRow>,
              <R37Field
                currency={currency}
                fieldProps={props.r37PartialTaxBaseFieldProps}
              />,
              <R39Field
                currency={currency}
                fieldProps={props.r39PartialTaxBaseFieldProps}
              />,
            ],
          r39Value != 0 && r39AdditionalFields,
        ],
      ]}
      {!props.addInfoFromTaxReturn && [
        <FormRow type="full">
          <Box>
            <Button
              label={formatMessage(
                "StandardLoan.additionalIncome.addInformationTaxReturn"
              )}
              variant="text"
              action={onAddInfoFromTaxReturn}
              icon={PlusIcon}
              size="default"
              disabled={!props.isEditing}
            />
          </Box>
        </FormRow>,
      ]}
      {props.addInfoFromTaxReturn &&
        typeof keepAccountancy === "boolean" &&
        !keepAccountancy && [
          <R37Field
            currency={currency}
            fieldProps={props.r37PartialTaxBaseFieldProps}
          />,
          <R39Field
            currency={currency}
            fieldProps={props.r39PartialTaxBaseFieldProps}
          />,
          r39Value != 0 && r39AdditionalFields,
          <R101Field
            currency={currency}
            fieldProps={props.r101BruttoIncomesFieldProps}
          />,
        ]}
    </FormSection>
  );
}

type PropsSK = {
  businessGrossIncomeFieldProps: ComputedFieldProps<Option<number>>;
  taxFieldProps: ComputedFieldProps<Option<number>>;
  taxBaseFieldProps: ComputedFieldProps<Option<number>>;
  hasAdditionalIncome: ComputedFieldProps<Option<boolean>>;
  addInfoFromTaxReturn: boolean;
  setAddInfoFromTaxReturn: Dispatch<SetStateAction<boolean>>;
  isMainIncome: boolean;
  isEditing: boolean;
};

export function IncomeSectionSK(props: PropsSK) {
  const formatMessage = useFormatMessage();

  const currency = useTenantCurrency();

  const onAddInfoFromTaxReturn = () => {
    props.setAddInfoFromTaxReturn(true);
  };
  const onCloseAddInfoFromTaxReturnSection = () => {
    props.setAddInfoFromTaxReturn(false);
  };

  return (
    <FormSection
      heading={{
        title: formatMessage("StandardLoan.Tradesman.IncomeSection.title"),
      }}
    >
      {props.isMainIncome && (
        <FormRow type="full">
          <YesNoRadioGroupField
            {...props.hasAdditionalIncome}
            label={formatMessage("StandardLoan.additionalIncome")}
            description={formatMessage(
              "StandardLoan.additionalIncome.description"
            )}
          />
        </FormRow>
      )}
      {!props.addInfoFromTaxReturn && [
        <FormRow type="full">
          <Box>
            <Button
              label={formatMessage(
                "StandardLoan.additionalIncome.addInformationTaxReturn"
              )}
              variant="text"
              action={onAddInfoFromTaxReturn}
              icon={PlusIcon}
              size="default"
              disabled={!props.isEditing}
            />
          </Box>
        </FormRow>,
      ]}
      {props.addInfoFromTaxReturn && [
        <FormRow type="full">
          <Box>
            <Button
              label={formatMessage("Cancel")}
              variant="text"
              size="default"
              action={onCloseAddInfoFromTaxReturnSection}
              disabled={!props.isEditing}
            />
          </Box>
        </FormRow>,
        <FormRow type="full">
          <MoneyField
            {...props.taxBaseFieldProps}
            currency={currency}
            label={formatMessage(
              "StandardLoan.Tradesman.IncomeSection.taxBase"
            )}
            placeholder={formatMessage(
              "StandardLoan.Tradesman.IncomeSection.yearlyAmountPlaceholder"
            )}
            min={-999999999}
            max={999999999}
          />
        </FormRow>,
        <FormRow type="full">
          <MoneyField
            {...props.taxFieldProps}
            currency={currency}
            label={formatMessage("StandardLoan.Tradesman.IncomeSection.tax")}
            placeholder={formatMessage(
              "StandardLoan.Tradesman.IncomeSection.yearlyAmountPlaceholder"
            )}
            min={-999999999}
            max={999999999}
          />
        </FormRow>,
        <FormRow type="full">
          <MoneyField
            {...props.businessGrossIncomeFieldProps}
            currency={currency}
            label={formatMessage(
              "StandardLoan.Tradesman.IncomeSection.businessGrossIncome"
            )}
            placeholder={formatMessage(
              "StandardLoan.Tradesman.IncomeSection.yearlyAmountPlaceholder"
            )}
            min={-999999999}
            max={999999999}
          />
        </FormRow>,
      ]}
    </FormSection>
  );
}
