import { constNull, pipe } from "fp-ts/function";
import * as remoteData from "../../../RemoteData";
import { usePolling } from "../../../useAPI";
import * as api from "../../api";
import { foldClientStatus } from "./LowRiskClientState";
import { ClientStatusErrorId } from "../../api";
import { Option } from "fp-ts/Option";
import { option } from "fp-ts";

type Props = {
  feedbackBlock: (
    status: "loading" | "error",
    errId: Option<ClientStatusErrorId>
  ) => JSX.Element;
  content: () => JSX.Element;
};

export function CheckingContractsWithStatusCheck(props: Props) {
  const [getClientStatusResult] = usePolling(api.getClientStatus, {
    shouldPollingContinue: ({ status }) => status === "PENDING",
    intervalMS: 3000,
  });

  return pipe(
    getClientStatusResult,
    remoteData.fold(
      () => props.feedbackBlock("loading", option.none),
      constNull,
      response =>
        pipe(
          response,
          foldClientStatus(
            () => props.feedbackBlock("loading", option.none),
            () => props.feedbackBlock("error", response.id),
            () => props.content()
          )
        )
    )
  );
}
