import { PensionType, RetirementRentType } from "./domain";
import { useFormatMessage } from "../../../../intl";

export function useFormatPensionType() {
  const formatMessage = useFormatMessage();

  return (pensionType: PensionType) => {
    switch (pensionType) {
      case "Disability":
        return formatMessage("Mortgage.Bonita.Income.PensionType.disability");
      case "OldAge":
        return formatMessage("Mortgage.Bonita.Income.PensionType.oldAge");
      case "Orphans":
        return formatMessage("Mortgage.Bonita.Income.PensionType.orphans");
      case "Retirement":
        return formatMessage("Mortgage.Bonita.Income.PensionType.retirement");
      case "Widower":
        return formatMessage("Mortgage.Bonita.Income.PensionType.widower");
      case "Bursary":
        return formatMessage("Mortgage.Bonita.Income.PensionType.bursary");
      case "RetirementAnnuity":
        return formatMessage(
          "Mortgage.Bonita.Income.PensionType.fosterParentReward"
        );
      case "FullInvalidityPension":
        return formatMessage(
          "Mortgage.Bonita.Income.PensionType.fullInvalidityPension"
        );
      case "OtherPensionOrAllowance":
        return formatMessage(
          "Mortgage.Bonita.Income.PensionType.otherPensionOrAllowance"
        );
    }
  };
}

export function useFormatRetirementRentType() {
  const formatMessage = useFormatMessage();

  return (retirementRent: RetirementRentType) => {
    switch (retirementRent) {
      case "FireRescueCorps":
        return formatMessage(
          "Mortgage.Bonita.Income.RetirementRentType.fireRescueCorps"
        );
      case "FormerArmyMembers":
        return formatMessage(
          "Mortgage.Bonita.Income.RetirementRentType.formerArmyMembers"
        );
      case "GeneralInspectorate":
        return formatMessage(
          "Mortgage.Bonita.Income.RetirementRentType.generalInspectorate"
        );
      case "OfficeForForeignRelations":
        return formatMessage(
          "Mortgage.Bonita.Income.RetirementRentType.officeForForeignRelations"
        );
      case "CustomAdministration":
        return formatMessage(
          "Mortgage.Bonita.Income.RetirementRentType.customAdministration"
        );
      case "Other":
        return formatMessage("Mortgage.Bonita.Income.RetirementRentType.other");
      case "Policeman":
        return formatMessage(
          "Mortgage.Bonita.Income.RetirementRentType.policeman"
        );
      case "PrisonService":
        return formatMessage(
          "Mortgage.Bonita.Income.RetirementRentType.prisonService"
        );
      case "SecurityInformationService":
        return formatMessage(
          "Mortgage.Bonita.Income.RetirementRentType.securityInformationService"
        );
    }
  };
}
