import { option, taskEither } from "fp-ts";
import {
  AllowanceInfo,
  CompanyInfo,
  ContractInfo,
  IncomeData,
  IncomeInfo,
  SpecialIncomeSourceType,
  StandardIncomeSourceType,
} from "../domain";
import { LocalizedString, Validator, validators } from "design-system";
import { Option } from "fp-ts/Option";
import { MonthYear } from "../../../globalDomain";
import { constant, Lazy, pipe } from "fp-ts/function";
import { TypeOfRent } from "../Forms/CompanyOwnerForm/IncomeSection/domain";
import { useFormatMessage } from "../../../intl";
import { IncomeFormOptions } from "../commons/useControlledSubmit";

export function getEmptyAllowanceInfo(): AllowanceInfo {
  return {
    allowanceAmount: option.none,
    receivesAllowance: option.none,
  };
}

export function getEmptyContractInfo(): ContractInfo {
  return {
    alsoEmployeeOfTheCompany: option.none,
    contractIndefinitePeriod: option.none,
    contractPeriodExpiration: option.none,
    employedInSpecificProfessions: option.none,
    employmentType: option.none,
    inProbation: option.none,
    lengthOfUninterruptedCurrentEmployment: option.none,
    lengthOfUninterruptedOverallEmployment: option.none,
    paymentMethod: option.none,
    paymentMethodLabel: option.none,
    startingDate: option.none,
    workBasedOnAgreementOnWorkingActivity: option.none,
    jobPosition: option.none,
  };
}

export function getEmptyCompanyInfo(): CompanyInfo {
  return {
    applicantsShareHigherThan33: option.none,
    businessSector: option.none,
    businessStartingDate: option.none,
    companyIco: option.none,
    companyName: option.none,
    equityPositive: option.none,
    freelancerType: option.none,
    nameOf3P: option.none,
    stakeInCompany: option.none,
    entrepreneurIco: option.none,
    nameOfEntrepreneur: option.none,
    addIco: option.none,
    country: option.none,
    phone: option.none,
    city: option.none,
    street: option.none,
    zipCode: option.none,
    houseNr: option.none,
  };
}

export function getEmptyIncomeInfo(
  incomeSource: StandardIncomeSourceType
): IncomeInfo {
  return {
    alimonyIncomeDetails: option.none,
    amortizationOfRentedRe: option.none,
    annualGrossIncome: option.none,
    bruttoComissions: option.none,
    businessGrossIncome: option.none,
    currency: option.none,
    employedInCompany: option.none,
    equityFromLastYear: option.none,
    grossIncomes: option.none,
    incomeAsDeductibleExpenses: option.none,
    incomeDescription: option.none,
    incomeFromRentContract: option.none,
    incomeSource,
    incomeSourceLabel: option.none,
    isApplicantDeclareAsUserInDeclaration: option.none,
    isCooperativeOwnership: option.none,
    keepsAccountancy: option.none,
    maternityOrParentalIncomeDetails: option.none,
    monthlyIncome: option.none,
    negativeBusinessProfit: option.none,
    pensionerIncomeDetails: option.none,
    profitForLastPeriod: option.none,
    isProfitPeriodPositive: option.none,
    profitSharingIncome: option.none,
    r101BruttoIncomes: option.none,
    r201IncomeFromLease: option.none,
    r37PartialTaxBase: option.none,
    r38PartialTaxBase: option.none,
    r39PartialTaxBase: option.none,
    r74TaxAfterClaimedRelief: option.none,
    reCoOwned: option.none,
    reInPersonalOwnership: option.none,
    reUsedAsCollateral: option.none,
    salaryCurrency: option.none,
    sumOfPaidShareOfProfit: option.none,
    tax: option.none,
    taxBase: option.none,
    typeOfIncome: option.none,
    typeOfRent: option.none,
    youngPeopleConditions: option.none,
    hasAdditionalIncome: option.none,
    hasInfoFromTaxReturn: option.none,
  };
}

export function getEmptyIncomeInfoSpecial(
  specialTypeOfIncome: SpecialIncomeSourceType
): IncomeInfo {
  return {
    ...getEmptyIncomeInfo("Employed"),
    incomeSource: "SpecialType",
    specialTypeOfIncome,
  };
}

export function notInTheFutureMonthYearValidator(
  errorMessage: LocalizedString,
  emptyFieldErrorMessage: LocalizedString
) {
  return monthYearValidator(
    errorMessage,
    emptyFieldErrorMessage,
    (a, b) => a < b
  );
}

export function notInThePastMonthYearValidator(
  errorMessage: LocalizedString,
  emptyFieldErrorMessage: LocalizedString
) {
  return monthYearValidator(
    errorMessage,
    emptyFieldErrorMessage,
    (a, b) => a > b
  );
}

function monthYearValidator(
  errorMessage: LocalizedString,
  emptyFieldErrorMessage: LocalizedString,
  compare: (a: Date, b: Date) => boolean
) {
  return validators.inSequence(
    validators.definedNoExtract<MonthYear>(emptyFieldErrorMessage),
    validators.fromPredicate(
      monthYear =>
        pipe(
          monthYear,
          option.fold(
            () => false,
            monthYear =>
              compare(new Date(monthYear.year, monthYear.month, 1), new Date())
          )
        ),
      errorMessage
    )
  );
}

export function constOptionNoneValidator<I, O = I>(): Validator<
  Option<I>,
  Option<O>
> {
  return () => taskEither.right(option.none);
}

export function foldTypeOfRent<A>(pattern: { [k in TypeOfRent]: Lazy<A> }) {
  return (typeOfRent: TypeOfRent) => {
    switch (typeOfRent) {
      case "RentFromOtherRealEstate":
        return pattern["RentFromOtherRealEstate"]();
      case "RentFromPledgedRealEstate":
        return pattern["RentFromPledgedRealEstate"]();
    }
  };
}

export function useFormatTypeOfRent() {
  const formatMessage = useFormatMessage();

  return foldTypeOfRent({
    RentFromOtherRealEstate: constant(
      formatMessage("StandardLoan.RentFromOtherRealEstate")
    ),
    RentFromPledgedRealEstate: constant(
      formatMessage("StandardLoan.RentFromPledgedRealEstate")
    ),
  });
}

export function getCompanyNameWithDefault(incomeData: IncomeData) {
  return pipe(
    incomeData.companyInfo,
    option.chain(companyInfo => companyInfo.companyName),
    option.alt(() => incomeData.incomeOptions.companyNameDefault),
    option.getOrElse(() => "")
  );
}

export function getCompanyIcoWithDefault(incomeData: IncomeData) {
  return pipe(
    incomeData.companyInfo,
    option.chain(companyInfo => companyInfo.companyIco),
    option.alt(() => incomeData.incomeOptions.companyIcoDefault),
    option.getOrElse(() => "")
  );
}

export function useUniqueCompanyValidators(options: IncomeFormOptions) {
  const formatMessage = useFormatMessage();

  const uniqueCompanyName = (companyName: string) =>
    validators.fromPredicate(
      () =>
        pipe(
          options.alreadySelectedCompanies,
          option.fold(
            () => true,
            blackList =>
              blackList.find(item => item.companyName === companyName) ===
              undefined
          )
        ),
      formatMessage("StandardLoan.AdditionalIncome.Income.usedCompany")
    );

  const uniqueCompanyIco = (companyIco: string) =>
    validators.fromPredicate(
      () =>
        pipe(
          options.alreadySelectedCompanies,
          option.fold(
            () => true,
            blackList =>
              blackList.find(item => item.companyIco === companyIco) ===
              undefined
          )
        ),
      formatMessage("StandardLoan.AdditionalIncome.Income.usedICO")
    );

  return [uniqueCompanyName, uniqueCompanyIco];
}
