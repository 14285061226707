import { ComputedFieldProps, FormRow, FormSection } from "design-system";
import { option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { useFormatMessage } from "../../../../../intl";
import { useAppContext } from "../../../../../useAppContext";
import { YesNoRadioGroupField } from "../../../../../Common/YesNoRadioGroup/YesNoRadioGroupField";

type Props = {
  equityPositiveFieldProps: ComputedFieldProps<Option<boolean>>;
  isProfitPeriodPositive: ComputedFieldProps<Option<boolean>>;
  hasAdditionalIncome: ComputedFieldProps<Option<boolean>>;
  isMainIncome: boolean;
};

export function IncomeSection(props: Props) {
  const formatMessage = useFormatMessage();

  const {
    apiParameters: {},
  } = useAppContext();

  const isEquityPositive = pipe(
    props.equityPositiveFieldProps.value,
    option.toNullable
  );

  return (
    <FormSection
      heading={{
        title: formatMessage(
          "StandardLoan.AdditionalIncome.IncomeSection.title"
        ),
      }}
    >
      <FormRow type="full">
        <YesNoRadioGroupField
          {...props.equityPositiveFieldProps}
          label={formatMessage(
            "StandardLoan.AdditionalIncome.IncomeSection.isEquityPositiveLabel"
          )}
          description={formatMessage(
            "StandardLoan.AdditionalIncome.IncomeSection.isEquityPositiveTooltip"
          )}
        />
      </FormRow>
      {isEquityPositive && (
        <FormRow type="full">
          <YesNoRadioGroupField
            {...props.isProfitPeriodPositive}
            label={formatMessage(
              "StandardLoan.AdditionalIncome.IncomeSection.newProfitLastPeriodPositiveLabel"
            )}
            description={formatMessage(
              "StandardLoan.AdditionalIncome.IncomeSection.newProfitLastPeriodPositiveDescription"
            )}
          />
        </FormRow>
      )}
      {props.isMainIncome && (
        <FormRow type="full">
          <YesNoRadioGroupField
            {...props.hasAdditionalIncome}
            label={formatMessage("StandardLoan.additionalIncome")}
            description={formatMessage(
              "StandardLoan.additionalIncome.description"
            )}
          />
        </FormRow>
      )}
    </FormSection>
  );
}
