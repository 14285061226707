import { FlowAccordion, FlowAccordionItem } from "design-system";
import { option, taskEither } from "fp-ts";
import { IO } from "fp-ts/IO";
import { constVoid, flow, pipe } from "fp-ts/function";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { TaskEither } from "fp-ts/TaskEither";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { useFormatMessage } from "../../../intl";
import { UserID } from "../../../UKontoSecondPart/CreateProfileAndReadDocuments/CreateProfile/UserID";
import { SecurityPin } from "../../../UKontoSecondPart/CreateProfileAndReadDocuments/CreateProfile/SecurityPin";
import { SecurityPassword } from "../../../UKontoSecondPart/CreateProfileAndReadDocuments/CreateProfile/SecurityPassword";
import { useCommand } from "../../../useAPI";
import * as api from "../../../StandardLoan/api";
import { CheckingContracts } from "./CheckingContracts";
import {
  foldState,
  onUserCreationErrorAction,
  savePasswordAction,
  saveSecurityPINAction,
  saveUserIDAction,
  setActiveItemAction,
  reducer,
  State,
  CreatedLowRiskClient,
  CreatingLowRiskClient,
} from "./LowRiskClientState";
import { useReducer } from "react";
import { Option } from "fp-ts/Option";
import { StandardLoanFlowType } from "../../domain";
import { ExistingClientAuthenticationMethod } from "../../../globalDomain";

type Props = {
  onContinue: (status: Option<api.LFStatus>) => unknown;
  onApplicationRejected: IO<void>;
  onAfterSignature: (status: Option<api.LFStatus>) => unknown;
  flowType: StandardLoanFlowType;
  authenticationMethod: Option<ExistingClientAuthenticationMethod>;
  mustReadAllDocuments?: boolean;
  skipContracts: boolean;
  isCredentialsAndSigningRemote: boolean;
  credentialStatus:
    | {
        existingClient: true;
      }
    | {
        existingClient: false;
        shouldAskCredential: boolean;
      };
  isRework: boolean;
};
type StateWithNoExistsClient = CreatingLowRiskClient | CreatedLowRiskClient;

export function LowRiskClient(props: Props) {
  const formatMessage = useFormatMessage();

  const initialState: State = props.credentialStatus.existingClient
    ? { type: "ExistingClient" }
    : props.credentialStatus.shouldAskCredential
    ? {
        type: "CreatingLowRiskClient",
        activeItem: option.some(0),
        userID: option.none,
        securityPIN: option.none,
        password: option.none,
        error: false,
      }
    : {
        type: "CreatedLowRiskClient",
        activeItem: option.some(0),
        userID: option.none,
        securityPIN: option.none,
        password: option.none,
        error: false,
      };

  const [state, dispatch] = useReducer(reducer, initialState);

  const submitCredentials = useCommand(api.submitCredentials);

  const onProfileSubmit = (
    internalState: StateWithNoExistsClient,
    password: NonEmptyString
  ): TaskEither<unknown, unknown> =>
    pipe(
      submitCredentials({
        profile: {
          userId: internalState.userID,
          pin: internalState.securityPIN,
          password,
        },
      }),
      taskEither.orElse(() =>
        taskEither.leftIO<unknown, unknown>(() =>
          dispatch(onUserCreationErrorAction())
        )
      ),
      taskEither.chain(() =>
        taskEither.fromIO(() => dispatch(savePasswordAction(password)))
      )
    );

  const userIdStep = (
    internalState: StateWithNoExistsClient
  ): FlowAccordionItem => ({
    title: formatMessage(
      "StandardLoan.FollowUpAndSignature.LowRiskClient.userID"
    ),
    content: () => (
      <UserID
        initialUserId={internalState.userID}
        onNext={newUserId =>
          taskEither.fromIO(() => dispatch(saveUserIDAction(newUserId)))
        }
      />
    ),
  });

  const securityPINStep: FlowAccordionItem = {
    title: formatMessage(
      "StandardLoan.FollowUpAndSignature.LowRiskClient.securityPIN"
    ),
    content: () => (
      <SecurityPin
        setUkontoPinStep={constVoid}
        next={flow(saveSecurityPINAction, dispatch)}
      />
    ),
  };
  const passwordStep = (
    internalState: StateWithNoExistsClient
  ): FlowAccordionItem => ({
    title: formatMessage(
      "StandardLoan.FollowUpAndSignature.LowRiskClient.passwordForCommunication"
    ),
    content: () => (
      <SecurityPassword
        setUkontoPasswordStep={constVoid}
        submitLabel={formatMessage("CreateProfile.securityPassword.submit")}
        onComplete={password => onProfileSubmit(internalState, password)}
        error={pipe(
          formatMessage("StandardLoan.SecurityPassword.error"),
          option.fromPredicate(() => internalState.error)
        )}
      />
    ),
  });

  const accordionItems = (
    internalState: StateWithNoExistsClient
  ): NonEmptyArray<FlowAccordionItem> => {
    const userIdStepContent = userIdStep(internalState);
    const passwordStepContent = passwordStep(internalState);
    return [userIdStepContent, securityPINStep, passwordStepContent];
  };

  const clientCredentialsFlow = (internalState: StateWithNoExistsClient) => {
    const items = accordionItems(internalState);

    return (
      <FlowAccordion
        items={items}
        value={pipe(
          internalState.activeItem,
          option.filter(() => state.type === "CreatingLowRiskClient")
        )}
        onChange={flow(setActiveItemAction, dispatch)}
      />
    );
  };

  return pipe(
    state,
    foldState({
      creating: creatingState => clientCredentialsFlow(creatingState),
      created: createdState => (
        <>
          {!props.isRework && clientCredentialsFlow(createdState)}
          <CheckingContracts
            onContinue={props.onContinue}
            onApplicationRejected={props.onApplicationRejected}
            onAfterSignature={props.onAfterSignature}
            flowType={props.flowType}
            authenticationMethod={props.authenticationMethod}
            mustReadAllDocuments={props.mustReadAllDocuments}
            skipContracts={props.skipContracts}
            existingClient={props.credentialStatus.existingClient}
            isCredentialsAndSigningRemote={props.isCredentialsAndSigningRemote}
            isRiskyClient={false}
          />
        </>
      ),
      existingClient: () => (
        <CheckingContracts
          onContinue={props.onContinue}
          onApplicationRejected={props.onApplicationRejected}
          onAfterSignature={props.onAfterSignature}
          flowType={props.flowType}
          authenticationMethod={props.authenticationMethod}
          mustReadAllDocuments={props.mustReadAllDocuments}
          skipContracts={props.skipContracts}
          existingClient={props.credentialStatus.existingClient}
          isCredentialsAndSigningRemote={props.isCredentialsAndSigningRemote}
          isRiskyClient={false}
        />
      ),
    })
  );
}
