import { DropdownField } from "../../../../Common/DropdownField/DropdownField";
import {
  selectedDropdownOption,
  dropdownOptionToValue,
} from "../../../../Common/selectDropdownOption";
import { Option } from "fp-ts/Option";
import { DropdownOption, ComputedFieldProps } from "design-system";
import { PensionType, PensionTypeSK, PensionTypeCZ } from "./domain";
import { record, array } from "fp-ts";
import { pipe } from "fp-ts/function";
import { foldTenant } from "../../../../globalDomain";
import { useAppContext } from "../../../../useAppContext";
import { useFormatMessage } from "../../../../intl";
import { useFormatPensionType } from "./utils";

type Props = {
  fieldProps: ComputedFieldProps<Option<PensionType>>;
};

export function PensionTypeDropdown({ fieldProps }: Props) {
  const {
    apiParameters: { tenant },
  } = useAppContext();
  const formatMessage = useFormatMessage();
  const formatPensionType = useFormatPensionType();

  const options = pipe(
    foldTenant<Array<PensionType>>(
      tenant,
      () => record.keys(PensionTypeSK.keys),
      () => record.keys(PensionTypeCZ.keys)
    ),
    array.map(pensionType => ({
      value: pensionType,
      label: formatPensionType(pensionType),
    }))
  );

  const handleChange = (value: Option<DropdownOption<PensionType>>) => {
    fieldProps.onChange(dropdownOptionToValue(value));
  };

  const value = selectedDropdownOption(fieldProps.value, options);

  return (
    <DropdownField
      {...fieldProps}
      clearable={false}
      label={formatMessage(
        "StandardLoan.PensionerForm.IncomeSection.pensionType"
      )}
      onChange={handleChange}
      options={options}
      placeholder={formatMessage(
        "StandardLoan.PensionerForm.IncomeSection.pensionTypePlaceholder"
      )}
      searchable={false}
      value={value}
    />
  );
}
