import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { IO } from "fp-ts/IO";
import { Reader } from "fp-ts/Reader";
import {
  ClientStatusOutput,
  RemoteSignatureStatusOutput,
  SignatureStatusOutput,
} from "../../api";
import { pipe } from "fp-ts/function";

export type CreatingLowRiskClient = {
  type: "CreatingLowRiskClient";
  activeItem: Option<number>;
  userID: Option<NonEmptyString>;
  securityPIN: Option<NonEmptyString>;
  password: Option<NonEmptyString>;
  error: boolean;
};
export type CreatedLowRiskClient = {
  type: "CreatedLowRiskClient";
  activeItem: Option<number>;
  userID: Option<NonEmptyString>;
  securityPIN: Option<NonEmptyString>;
  password: Option<NonEmptyString>;
  error: boolean;
};

type ExistingClient = { type: "ExistingClient" };

export type State =
  | CreatingLowRiskClient
  | CreatedLowRiskClient
  | ExistingClient;

export function foldState<T>(f: {
  creating: Reader<CreatingLowRiskClient, T>;
  created: Reader<CreatedLowRiskClient, T>;
  existingClient: Reader<ExistingClient, T>;
}): (status: State) => T {
  return state => {
    switch (state.type) {
      case "CreatingLowRiskClient":
        return f.creating(state);
      case "CreatedLowRiskClient":
        return f.created(state);
      case "ExistingClient":
        return f.existingClient(state);
    }
  };
}

export function foldClientStatus<T>(
  whenPending: IO<T>,
  whenError: IO<T>,
  whenDone: IO<T>
): Reader<ClientStatusOutput, T> {
  return ({ status }) => {
    switch (status) {
      case "PENDING":
        return whenPending();
      case "ERROR":
        return whenError();
      case "DONE":
        return whenDone();
    }
  };
}

export function foldRemoteSignatureStatus<T>(
  whenPending: IO<T>,
  whenSigning: IO<T>,
  whenError: IO<T>,
  whenDone: IO<T>
): Reader<RemoteSignatureStatusOutput, T> {
  return ({ status }) => {
    switch (status) {
      case "PENDING":
        return whenPending();
      case "SIGNING":
        return whenSigning();
      case "ERROR":
        return whenError();
      case "DONE":
        return whenDone();
    }
  };
}

export function foldSignatureStatus<T>(
  whenNone: IO<T>,
  whenPending: IO<T>,
  whenError: IO<T>,
  whenDone: IO<T>
): Reader<SignatureStatusOutput, T> {
  return ({ signatureStatus }) => {
    switch (signatureStatus) {
      case "NONE":
        return whenNone();
      case "PENDING":
        return whenPending();
      case "ERROR":
        return whenError();
      case "DONE":
        return whenDone();
    }
  };
}

type SaveUserIDAction = {
  type: "SaveUserID";
  userID: Option<NonEmptyString>;
};
type SaveSecurityPINAction = {
  type: "SaveSecurityPIN";
  securityPIN: Option<NonEmptyString>;
};
type SavePasswordAction = {
  type: "SavePassword";
  password: NonEmptyString;
};
type SetActiveItemAction = {
  type: "SetActiveItem";
  activeItem: Option<number>;
};
type OnUserCreationErrorAction = {
  type: "OnUserCreationError";
};

export type Action =
  | SaveUserIDAction
  | SaveSecurityPINAction
  | SavePasswordAction
  | SetActiveItemAction
  | OnUserCreationErrorAction;

export function saveUserIDAction(userID: NonEmptyString): Action {
  return { type: "SaveUserID", userID: option.some(userID) };
}
export function saveSecurityPINAction(securityPIN: NonEmptyString): Action {
  return { type: "SaveSecurityPIN", securityPIN: option.some(securityPIN) };
}
export function savePasswordAction(password: NonEmptyString): Action {
  return { type: "SavePassword", password };
}
export function setActiveItemAction(activeItem: Option<number>): Action {
  return { type: "SetActiveItem", activeItem: activeItem };
}
export function onUserCreationErrorAction(): OnUserCreationErrorAction {
  return {
    type: "OnUserCreationError",
  };
}

export function reducer(state: State, action: Action): State {
  switch (state.type) {
    case "CreatedLowRiskClient":
    case "CreatingLowRiskClient":
      switch (action.type) {
        case "SaveUserID":
          return {
            ...state,
            userID: action.userID,
            activeItem: pipe(
              state.activeItem,
              option.map(i => i + 1)
            ),
            error: false,
          };
        case "SaveSecurityPIN":
          return {
            ...state,
            securityPIN: action.securityPIN,
            activeItem: pipe(
              state.activeItem,
              option.map(i => i + 1)
            ),
            error: false,
          };
        case "SavePassword":
          switch (state.type) {
            case "CreatingLowRiskClient":
              return {
                ...state,
                type: "CreatedLowRiskClient",
                password: option.some(action.password),
                activeItem: pipe(
                  state.activeItem,
                  option.map(i => i + 1)
                ),
              };
            case "CreatedLowRiskClient":
              return {
                ...state,
                type: "CreatingLowRiskClient",
                password: option.none,
              };
          }
        case "SetActiveItem":
          return { ...state, activeItem: action.activeItem };
        case "OnUserCreationError":
          return {
            ...state,
            error: true,
          };
      }
    case "ExistingClient":
      return state;
  }
}
