import {
  Banner,
  Box,
  FeedbackBlock,
  useAuthenticationContext,
} from "design-system";
import { IO } from "fp-ts/IO";
import { constNull, pipe } from "fp-ts/function";
import { either, option, taskEither } from "fp-ts";
import { useFormatMessage } from "../../intl";
import { PageHeading } from "../../Common/PageHeading/PageHeading";
import { foldFlowType, StandardLoanFlowType } from "../domain";
import { CreateProfileWithRemoteCredentials } from "./CreateProfileWithRemoteCredentials";
import { CreateProfile } from "./CreateProfile";
import { Option } from "fp-ts/Option";
import { ExistingClientAuthenticationMethod } from "../../globalDomain";
import { LFStatus } from "../api";
import { Rework } from "../StandardLoanState";
import { useCommand, useQuery } from "../../useAPI";
import { useEffect, useState } from "react";
import { readParameters } from "../../UKontoSecondPart/Credentials/api";
import * as remoteData from "../../RemoteData";
import * as reworkApi from "../Rework/api";
import { MainContent } from "../../Common/MainContent";
import { GDPR } from "../../GDPR/GDPR";
import { useAppContext } from "../../useAppContext";
import { useIsInPersonChannel } from "../../useChannel";

export type CredentialStatus =
  | { type: "existingClient" }
  | { type: "newClient"; shouldAskCredential: boolean }
  | { type: "unknown" };

type Props = {
  onContinue: (status: Option<LFStatus>) => void;
  onApplicationRejected: IO<void>;
  onExit: IO<unknown>;
  flowType: StandardLoanFlowType;
  authenticationMethod: Option<ExistingClientAuthenticationMethod>;
  rework: Rework;
  credentialStatus: CredentialStatus;
  existingClient: boolean;
  isCredentialsAndSigningRemote: boolean;
  isKycNeeded: boolean;
  onRemoteCredentialsExit: IO<unknown>;
  onAfterSignature: (status: Option<LFStatus>) => void;
};

type State =
  | { type: "Loading" }
  | { type: "Ready"; skipContracts: boolean }
  | { type: "Error" };

export function FollowUpAndSignature(props: Props) {
  const formatMessage = useFormatMessage();
  const [isRiskyClient, setIsRiskyClient] = useState(false);
  const getReworkDetails = useCommand(reworkApi.reworkDetails);
  const loadingState: State = { type: "Loading" };
  const errorState: State = { type: "Error" };
  const [state, setState] = useState<State>(loadingState);

  useEffect(
    () =>
      pipe(
        props.rework.reworkSteps,
        option.fold(
          () => setState({ type: "Ready", skipContracts: false }),
          () =>
            taskEither.bracket(
              taskEither.fromIO(() => setState(loadingState)),
              getReworkDetails,
              (_, result) =>
                taskEither.fromIO(() => {
                  either.isLeft(result)
                    ? setState(errorState)
                    : setState({
                        type: "Ready",
                        skipContracts: !result.right.needContractsUpdate,
                      });
                })
            )()
        )
      ),
    []
  );

  const { authInfo } = useAuthenticationContext();
  const id = pipe(
    authInfo,
    option.map(i => i.flowId),
    option.getOrElse(() => "")
  );

  const [parametersQuery] = useQuery(readParameters, { id });
  const {
    apiParameters: { tenant },
  } = useAppContext();
  const isInPersonChannel = useIsInPersonChannel();

  useEffect(() => {
    pipe(
      parametersQuery,
      remoteData.fold(constNull, constNull, (parameters: any) => {
        setIsRiskyClient(parameters.riskyActivitiesOrPep);
      })
    );
  }, [parametersQuery]);

  const renderClientProfile = (
    credentialStatus:
      | {
          existingClient: true;
        }
      | {
          existingClient: false;
          shouldAskCredential: boolean;
        },
    skipContracts: boolean
  ) => (
    <CreateProfile
      onApplicationRejected={props.onApplicationRejected}
      onContinue={props.onContinue}
      onAfterSignature={props.onAfterSignature}
      flowType={props.flowType}
      authenticationMethod={props.authenticationMethod}
      mustReadAllDocuments={true}
      skipContracts={skipContracts}
      credentialStatus={credentialStatus}
      isCredentialsAndSigningRemote={props.isCredentialsAndSigningRemote}
      onRemoteCredentialsExit={props.onRemoteCredentialsExit}
      isRework={option.isSome(props.rework.reworkSteps)}
    />
  );

  const renderClientProfileWithRemoteCredentials = (
    credentialStatus:
      | {
          existingClient: true;
        }
      | {
          existingClient: false;
          shouldAskCredential: boolean;
        },
    skipContracts: boolean,
    mustReadAllDocuments?: boolean
  ) => (
    <CreateProfileWithRemoteCredentials
      onComplete={props.onContinue}
      onExit={props.onExit}
      authenticationMethod={props.authenticationMethod}
      onApplicationRejected={props.onApplicationRejected}
      skipContracts={skipContracts}
      credentialStatus={credentialStatus}
      flowType={props.flowType}
      isCredentialsAndSigningRemote={props.isCredentialsAndSigningRemote}
      isKycNeeded={props.isKycNeeded}
      isRiskyClient={isRiskyClient}
      onRemoteCredentialsExit={props.onRemoteCredentialsExit}
      mustReadAllDocuments={mustReadAllDocuments}
      onAfterSignature={props.onAfterSignature}
    />
  );

  const createProfile = (
    credentialStatus:
      | {
          existingClient: true;
        }
      | {
          existingClient: false;
          shouldAskCredential: boolean;
        },
    skipContracts: boolean
  ) =>
    pipe(
      props.flowType,
      foldFlowType({
        when3P: () =>
          renderClientProfileWithRemoteCredentials(
            credentialStatus,
            skipContracts,
            true
          ),
        whenHomeBanking: () =>
          renderClientProfile(credentialStatus, skipContracts),
        whenSmartBanking: () =>
          renderClientProfile(credentialStatus, skipContracts),
        whenInPerson: () =>
          renderClientProfile(
            props.isCredentialsAndSigningRemote
              ? {
                  existingClient: false,
                  shouldAskCredential: false,
                }
              : credentialStatus,
            skipContracts
          ),
        whenTLSAgent: () =>
          props.existingClient
            ? renderClientProfile(credentialStatus, skipContracts)
            : renderClientProfileWithRemoteCredentials(
                credentialStatus,
                skipContracts
              ),
        whenPWSRemote: () =>
          renderClientProfile(credentialStatus, skipContracts),
      })
    );

  const renderContent = (skipContracts: boolean) => {
    switch (props.credentialStatus.type) {
      case "existingClient":
        return createProfile({ existingClient: true }, skipContracts);
      case "newClient":
        return createProfile(
          {
            existingClient: false,
            shouldAskCredential: props.credentialStatus.shouldAskCredential,
          },
          skipContracts
        );
      case "unknown":
        return createProfile(
          props.existingClient
            ? { existingClient: true }
            : { existingClient: false, shouldAskCredential: true },
          skipContracts
        );
    }
  };

  const [gdprDialogDisplayed, setGdprDialogDisplayed] = useState(false);
  const showGdprDialog =
    tenant === "SK" &&
    isInPersonChannel &&
    (props.credentialStatus.type === "newClient" ||
      (props.credentialStatus.type === "unknown" && !props.existingClient)) &&
    !gdprDialogDisplayed;
  return (
    <MainContent>
      <Box column shrink>
        {state.type === "Loading" && (
          <FeedbackBlock
            type="loading"
            size="large"
            heading={formatMessage("Loading")}
            subheading={option.none}
          />
        )}
        {state.type === "Error" && (
          <Banner
            type="error"
            actions={option.none}
            content={formatMessage("GenericError")}
            title={option.none}
          />
        )}
        {state.type === "Ready" && (
          <>
            <PageHeading
              title={formatMessage(
                state.skipContracts
                  ? "StandardLoan.FollowUpAndSignature.title.skippedContracts"
                  : "StandardLoan.FollowUpAndSignature.title"
              )}
              hideOnMobile
            />
            {showGdprDialog && (
              <GDPR
                onDismiss={props.onExit}
                onContinue={() => {
                  setGdprDialogDisplayed(true);
                }}
                paperBased={state.skipContracts}
              />
            )}
            {!showGdprDialog && renderContent(state.skipContracts)}
          </>
        )}
      </Box>
    </MainContent>
  );
}
