import {
  PlusIcon,
  Button,
  FormRow,
  FormSection,
  Stack,
  useForm,
  validators,
  Box,
} from "design-system";
import { boolean, option, taskEither } from "fp-ts";
import { Option } from "fp-ts/Option";
import { constFalse, constNull, constTrue, pipe } from "fp-ts/function";
import { IncomeData, isOptionTrue } from "../domain";
import { CompanySuggestion } from "../../../Common/CompanyField/domain";
import { useFormatMessage } from "../../../intl";
import { YesNoRadioGroupField } from "../../../Common/YesNoRadioGroup/YesNoRadioGroupField";
import { MonthYearField } from "../commons/MonthYearField";
import { foldTenant } from "../../../globalDomain";
import { useAppContext } from "../../../useAppContext";
import { useTenantCurrency } from "../../../Common/useTenantCurrency";
import { useValidators } from "../../../Common/useValidators";
import { useEffect, useRef, useState } from "react";
import {
  constOptionNoneValidator,
  getEmptyAllowanceInfo,
  getEmptyCompanyInfo,
  getEmptyContractInfo,
  getEmptyIncomeInfo,
  notInTheFutureMonthYearValidator,
  useUniqueCompanyValidators,
} from "../commons/Utils";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { FreelancerTypeDropdown } from "../commons/FreelancerTypeDrowdown";
import { ClientWithoutICO, CompanyInfoRow } from "../commons/CompanyInfoRow";
import {
  IncomeCardProps,
  useControlledSubmit,
} from "../commons/useControlledSubmit";
import {
  isReworked,
  useReworkComparator,
} from "../../Rework/useReworkComparator";
import { MoneyField } from "../../../Common/Price/MoneyField/MoneyField";
import { useQuery } from "../../../useAPI";
import * as api from "../api";
import * as remoteData from "../../../RemoteData";

const MIN_AMOUNT = -999999999;
const MAX_AMOUNT = 999999999;

export function FreelancerCard(props: IncomeCardProps) {
  const formatMessage = useFormatMessage();
  const {
    validCompanyNameRequired,
    validCompanyIcoRequired,
    amountMinMaxWithNegative,
    definedNoExtract,
    validCompanyIco,
    validAddressField,
    validStreetNumber,
    validPhone,
    nonBlankString,
    nonNegativeNumber,
  } = useValidators();
  const {
    apiParameters: { tenant },
  } = useAppContext();
  const tenantCurrency = useTenantCurrency();
  const validatedValues = useRef<Option<IncomeData>>(option.none);

  const { options, incomeData } = props;

  const syncCompanyFields = (suggestion: CompanySuggestion) =>
    setFormState({
      companyName: suggestion.name,
      companyIco: suggestion.id,
    });

  const [
    uniqueCompanyNameValidator,
    uniqueCompanyIcoValidator,
  ] = useUniqueCompanyValidators(options);

  const amountValidator = () =>
    validators.inSequence(
      definedNoExtract<number>(),
      validators.validateIfDefined(
        amountMinMaxWithNegative(MIN_AMOUNT, MAX_AMOUNT)
      )
    );
  const SKOnlyAmountValidator = foldTenant(
    tenant,
    amountValidator,
    constOptionNoneValidator
  );

  function shouldValidateCompanyIco(
    entrepreneurIcoOption: Option<string>,
    addIco: Option<boolean>
  ): boolean {
    if (isOptionTrue(addIco)) {
      const entrepreneurIco = pipe(
        entrepreneurIcoOption,
        option.getOrElse(() => "")
      );
      return entrepreneurIco.length > 0;
    }
    return false;
  }

  const { specialFieldsReworkFieldProps } = useReworkComparator(props.rework);
  // This dummy validator is to avoid a known error in useForm fieldArrayValidator
  const workAroundAlwaysRightValidator = <T extends any>() =>
    validators.fromPredicate<T>(constTrue, formatMessage("GenericError"));

  const initialValues = {
    addInfoFromTaxReturn: pipe(
      incomeData.incomeInfo,
      option.chain(data => data.equityFromLastYear),
      option.map(constTrue),
      option.getOrElse(constFalse)
    ),
    salaryCurrency: props.salaryCurrency,
    monthlyIncome: props.monthlyIncome,
    freelancerType: pipe(
      incomeData.companyInfo,
      option.chain(data => data.freelancerType)
    ),
    companyName: pipe(
      incomeData.companyInfo,
      option.chain(companyInfo => companyInfo.companyName),
      option.getOrElse(() => "")
    ),
    companyIco: pipe(
      incomeData.companyInfo,
      option.chain(companyInfo => companyInfo.companyIco),
      option.getOrElse(() => "")
    ),
    businessSector: pipe(
      incomeData.companyInfo,
      option.chain(value => value.businessSector)
    ),
    businessStartingDate: pipe(
      incomeData.companyInfo,
      option.chain(data => data.businessStartingDate)
    ),
    taxBase: pipe(
      incomeData.incomeInfo,
      option.chain(data => data.taxBase)
    ),
    businessGrossIncome: pipe(
      incomeData.incomeInfo,
      option.chain(data => data.businessGrossIncome)
    ),
    hasAdditionalIncome: pipe(
      incomeData.incomeInfo,
      option.chain(data => data.hasAdditionalIncome),
      option.alt(() => option.some(false))
    ),
    hasInfoFromTaxReturn: pipe(
      incomeData.incomeInfo,
      option.chain(data => data.hasInfoFromTaxReturn),
      option.alt(() => option.some(false))
    ),
    equityPositive: pipe(
      incomeData.companyInfo,
      option.fold(
        () => option.some(true),
        data => data.equityPositive
      )
    ),
    keepsAccountancy: pipe(
      incomeData.incomeInfo,
      option.fold(
        () => option.some(true),
        data => data.keepsAccountancy
      )
    ),
    equityFromLastYear: pipe(
      incomeData.incomeInfo,
      option.chain(data => data.equityFromLastYear)
    ),
    grossIncomes: pipe(
      incomeData.incomeInfo,
      option.chain(data => data.grossIncomes)
    ),
    r101BruttoIncomes: pipe(
      incomeData.incomeInfo,
      option.chain(data => data.r101BruttoIncomes)
    ),
    r37PartialTaxBase: pipe(
      incomeData.incomeInfo,
      option.chain(data => data.r37PartialTaxBase)
    ),
    addIco: pipe(
      incomeData.companyInfo,
      option.chain(data => data.addIco)
    ),
    nameOfEntrepreneur: pipe(
      incomeData.companyInfo,
      option.chain(data => data.nameOfEntrepreneur)
    ),
    entrepreneurIco: pipe(
      incomeData.companyInfo,
      option.chain(data => data.entrepreneurIco)
    ),
    country: pipe(
      incomeData.companyInfo,
      option.chain(data => data.country)
    ),
    phone: pipe(
      incomeData.companyInfo,
      option.chain(data => data.phone)
    ),
    city: pipe(
      incomeData.companyInfo,
      option.chain(data => data.city)
    ),
    street: pipe(
      incomeData.companyInfo,
      option.chain(data => data.street)
    ),
    houseNr: pipe(
      incomeData.companyInfo,
      option.chain(data => data.houseNr)
    ),
    zipCode: pipe(
      incomeData.companyInfo,
      option.chain(data => data.zipCode)
    ),
  };
  const [companyNameIsValid, setCompanyNameIsValid] = useState(true);
  const [companyIcoIsValid, setCompanyIcoIsValid] = useState(true);
  const onCompanyNameValidationChange = (isValid: boolean) => {
    setCompanyNameIsValid(isValid);
  };
  const onCompanyIcoValidationChange = (isValid: boolean) => {
    setCompanyIcoIsValid(isValid);
  };
  const onAddInfoFromTaxReturn = () => {
    fieldProps("addInfoFromTaxReturn").onChange(true);
  };
  const onCloseAddInfoFromTaxReturnSection = () => {
    fieldProps("addInfoFromTaxReturn").onChange(false);
  };

  const {
    fieldProps,
    setValues: setFormState,
    handleSubmit,
    resetForm,
    // fieldErrors,
  } = useForm(
    {
      initialValues: initialValues,
      fieldValidators: values => ({
        salaryCurrency: () => definedNoExtract<string>()(props.salaryCurrency),
        monthlyIncome: () =>
          validators.inSequence(
            definedNoExtract<number>(),
            validators.validateIfDefined(nonNegativeNumber)
          )(props.monthlyIncome),
        freelancerType: definedNoExtract(),
        companyName: !isOptionTrue(values.addIco)
          ? validators.inSequence(
              validCompanyNameRequired(companyNameIsValid),
              uniqueCompanyNameValidator(values.companyName)
            )
          : workAroundAlwaysRightValidator<string>(),
        companyIco: !isOptionTrue(values.addIco)
          ? validators.inSequence(
              validCompanyIcoRequired(false, companyIcoIsValid),
              uniqueCompanyIcoValidator(values.companyIco)
            )
          : workAroundAlwaysRightValidator<string>(),
        businessStartingDate: foldTenant(
          tenant,
          () =>
            notInTheFutureMonthYearValidator(
              formatMessage("StandardLoan.EmployedCard.startingDateError"),
              formatMessage("Form.fieldError.required")
            ),
          () =>
            pipe(
              isOptionTrue(values.addIco),
              boolean.fold(constOptionNoneValidator, () =>
                notInTheFutureMonthYearValidator(
                  formatMessage("StandardLoan.EmployedCard.startingDateError"),
                  formatMessage("Form.fieldError.required")
                )
              )
            )
        ),
        businessSector: pipe(
          isOptionTrue(values.addIco),
          boolean.fold(constOptionNoneValidator, () => definedNoExtract())
        ),
        tax: pipe(
          values.addInfoFromTaxReturn,
          boolean.fold(constOptionNoneValidator, () => SKOnlyAmountValidator)
        ),
        taxBase: pipe(
          values.addInfoFromTaxReturn,
          boolean.fold(constOptionNoneValidator, () => SKOnlyAmountValidator)
        ),
        businessGrossIncome: pipe(
          values.addInfoFromTaxReturn,
          boolean.fold(constOptionNoneValidator, () => SKOnlyAmountValidator)
        ),
        equityPositive: foldTenant(tenant, constOptionNoneValidator, () =>
          pipe(
            isOptionTrue(values.keepsAccountancy),
            boolean.fold(constOptionNoneValidator, () =>
              validators.inSequence(
                definedNoExtract(),
                validators.validateIfDefined(
                  validators.fromPredicate(
                    equity => equity === true,
                    formatMessage(
                      "StandardLoan.FreelancerCard.equityNegativeError"
                    )
                  )
                )
              )
            )
          )
        ),
        keepsAccountancy: foldTenant(tenant, constOptionNoneValidator, () =>
          definedNoExtract()
        ),
        equityFromLastYear: foldTenant(tenant, constOptionNoneValidator, () =>
          pipe(
            isOptionTrue(values.keepsAccountancy) &&
              isOptionTrue(values.equityPositive) &&
              values.addInfoFromTaxReturn,
            boolean.fold(constOptionNoneValidator, amountValidator)
          )
        ),
        grossIncomes: foldTenant(tenant, constOptionNoneValidator, () =>
          pipe(
            isOptionTrue(values.keepsAccountancy) &&
              isOptionTrue(values.equityPositive) &&
              values.addInfoFromTaxReturn,
            boolean.fold(constOptionNoneValidator, amountValidator)
          )
        ),
        r101BruttoIncomes: foldTenant(tenant, constOptionNoneValidator, () =>
          pipe(
            isOptionActuallyFalse(values.keepsAccountancy) &&
              values.addInfoFromTaxReturn,
            boolean.fold(constOptionNoneValidator, amountValidator)
          )
        ),
        r37PartialTaxBase: foldTenant(tenant, constOptionNoneValidator, () =>
          pipe(
            (isOptionActuallyFalse(values.keepsAccountancy) ||
              isOptionTrue(values.equityPositive)) &&
              values.addInfoFromTaxReturn,
            boolean.fold(constOptionNoneValidator, amountValidator)
          )
        ),
        country: pipe(
          isOptionTrue(values.addIco),
          boolean.fold(constOptionNoneValidator, () =>
            definedNoExtract<string>()
          )
        ),
        nameOfEntrepreneur: pipe(
          isOptionTrue(values.addIco),
          boolean.fold(constOptionNoneValidator, () =>
            validators.validateIfDefined(nonBlankString)
          )
        ),
        entrepreneurIco: pipe(
          shouldValidateCompanyIco(values.entrepreneurIco, values.addIco),
          boolean.fold(constOptionNoneValidator, () =>
            validators.validateIfDefined(validCompanyIco(false))
          )
        ),
        city: pipe(
          isOptionTrue(values.addIco),
          boolean.fold(constOptionNoneValidator, () =>
            validators.validateIfDefined(validAddressField(40))
          )
        ),
        street: pipe(
          isOptionTrue(values.addIco),
          boolean.fold(constOptionNoneValidator, () =>
            validators.validateIfDefined(validAddressField(40))
          )
        ),
        houseNr: pipe(
          isOptionTrue(values.addIco),
          boolean.fold(constOptionNoneValidator, () =>
            validators.validateIfDefined(validStreetNumber)
          )
        ),
        zipCode: pipe(
          isOptionTrue(values.addIco),
          boolean.fold(constOptionNoneValidator, () =>
            validators.validateIfDefined(nonBlankString)
          )
        ),
        phone: pipe(
          isOptionTrue(values.addIco),
          boolean.fold(constOptionNoneValidator, () =>
            validators.validateIfDefined(validPhone)
          )
        ),
      }),
    },
    {
      onSubmit: values =>
        taskEither.fromIO(() => {
          validatedValues.current = option.some({
            ...incomeData,
            incomeInfo: option.some({
              ...getEmptyIncomeInfo("Freelancer"),
              taxBase: values.taxBase,
              businessGrossIncome: values.businessGrossIncome,
              grossIncomes: values.grossIncomes,
              equityFromLastYear: values.equityFromLastYear,
              r101BruttoIncomes: values.r101BruttoIncomes,
              r37PartialTaxBase: values.r37PartialTaxBase,
              keepsAccountancy: values.keepsAccountancy,
              salaryCurrency: values.salaryCurrency,
              monthlyIncome: values.monthlyIncome,
              hasAdditionalIncome: values.hasAdditionalIncome,
              hasInfoFromTaxReturn: values.hasInfoFromTaxReturn,
            }),
            companyInfo: option.some({
              ...getEmptyCompanyInfo(),
              companyName: !isOptionTrue(values.addIco)
                ? pipe(
                    values.companyName,
                    NonEmptyString.decode,
                    option.fromEither
                  )
                : option.none,
              companyIco: !isOptionTrue(values.addIco)
                ? pipe(
                    values.companyIco,
                    NonEmptyString.decode,
                    option.fromEither
                  )
                : option.none,
              equityPositive: values.equityPositive,
              freelancerType: values.freelancerType,
              businessStartingDate: values.businessStartingDate,
              businessSector: values.businessSector,
              country: values.country,
              city: values.city,
              street: values.street,
              houseNr: values.houseNr,
              zipCode: values.zipCode,
              phone: values.phone,
              nameOfEntrepreneur: values.nameOfEntrepreneur,
              entrepreneurIco: values.entrepreneurIco,
              addIco: values.addIco,
            }),
            contractInfo: option.some({
              ...getEmptyContractInfo(),
            }),
            allowanceInfo: option.some({
              ...getEmptyAllowanceInfo(),
            }),
          });
        }),
    }
  );

  useEffect(() => {
    resetForm();
  }, [props.employmentType]);

  useControlledSubmit(props, handleSubmit, validatedValues);
  const currentYear = new Date().getFullYear();

  const employmentTypeReworked = isReworked(
    props.rework,
    "contractInfo",
    "employmentType"
  );
  const incomeSourceReworked = isReworked(
    props.rework,
    "incomeInfo",
    "incomeSource"
  );
  const disabled = !options.isEditing;
  const reworkDependent = employmentTypeReworked || incomeSourceReworked;

  const keepAccountancyReworked = isReworked(
    props.rework,
    "incomeInfo",
    "keepsAccountancy"
  );

  const [dataForClientWithoutIco] = useQuery(api.dataForClientWithoutIco);
  const clientPhone = pipe(
    dataForClientWithoutIco,
    remoteData.fold(
      () => option.none,
      () => option.none,
      value => value.phone
    )
  );
  const phoneValue = pipe(
    fieldProps("phone").value,
    option.fold(
      () => clientPhone,
      somePhoneValue => {
        if (somePhoneValue.length <= 4) {
          return clientPhone;
        } else {
          return option.some(somePhoneValue);
        }
      }
    )
  );
  return (
    <Stack column grow shrink units={6}>
      <FormSection
        heading={{
          title: formatMessage(
            "StandardLoan.FreelancerCard.CompanySection.title"
          ),
        }}
      >
        <FormRow type="full">
          <FreelancerTypeDropdown
            fieldProps={specialFieldsReworkFieldProps(
              fieldProps("freelancerType"),
              reworkDependent,
              isReworked(props.rework, "companyInfo", "freelancerType") &&
                option.isSome(initialValues.freelancerType),
              disabled,
              props.reworkAll
            )}
          />
        </FormRow>

        <ClientWithoutICO
          incomeSourceType="Freelancer"
          fieldProps={fieldProps}
          rework={props.rework}
          reworkAll={props.reworkAll}
          initialValues={initialValues}
          options={options}
          onSelectCitySuggestion={suggestion => {
            setFormState({
              city: option.some(suggestion),
            });
          }}
          onSelectStreetSuggestion={suggestion => {
            setFormState({
              street: option.some(suggestion),
            });
          }}
          dataForClientWithoutIco={dataForClientWithoutIco}
          phoneValue={phoneValue}
        />

        {!isOptionTrue(fieldProps("addIco").value) && (
          <CompanyInfoRow
            incomeSourceType="Freelancer"
            companyIcoFieldProps={specialFieldsReworkFieldProps(
              fieldProps("companyIco"),
              reworkDependent,
              isReworked(props.rework, "companyInfo", "companyIco") &&
                initialValues.companyIco != "",
              disabled,
              props.reworkAll
            )}
            companyNameFieldProps={specialFieldsReworkFieldProps(
              fieldProps("companyName"),
              reworkDependent,
              isReworked(props.rework, "companyInfo", "companyName") &&
                initialValues.companyName != "",
              disabled,
              props.reworkAll
            )}
            syncCompanyFields={syncCompanyFields}
            onCompanyIcoValidationChange={onCompanyIcoValidationChange}
            onCompanyNameValidationChange={onCompanyNameValidationChange}
          />
        )}

        {foldTenant(
          tenant,
          () =>
            !isOptionTrue(fieldProps("addIco").value) && (
              <FormRow type="full">
                <MonthYearField
                  {...specialFieldsReworkFieldProps(
                    fieldProps("businessStartingDate"),
                    reworkDependent,
                    isReworked(
                      props.rework,
                      "companyInfo",
                      "businessStartingDate"
                    ) && option.isSome(initialValues.businessStartingDate),
                    disabled,
                    props.reworkAll
                  )}
                  clearable={false}
                  label={formatMessage(
                    "StandardLoan.FreelancerCard.startingDate"
                  )}
                  maxYear={currentYear}
                  minYear={currentYear - 50}
                  searchable
                  monthSelectionYearLimit={3}
                  checkMonthLimit
                />
              </FormRow>
            ),
          constNull
        )}
      </FormSection>
      <FormSection
        heading={{
          title: formatMessage(
            "StandardLoan.FreelancerCard.IncomeSection.title"
          ),
        }}
      >
        {foldTenant(
          tenant,
          () => (
            <Stack column grow shrink units={6}>
              {props.isMainIncome && (
                <FormRow type="full">
                  <YesNoRadioGroupField
                    {...specialFieldsReworkFieldProps(
                      fieldProps("hasAdditionalIncome"),
                      reworkDependent,
                      isReworked(
                        props.rework,
                        "incomeInfo",
                        "hasAdditionalIncome"
                      ) && option.isSome(initialValues.hasAdditionalIncome),
                      disabled,
                      props.reworkAll
                    )}
                    label={formatMessage("StandardLoan.additionalIncome")}
                    description={formatMessage(
                      "StandardLoan.additionalIncome.description"
                    )}
                  />
                </FormRow>
              )}
              {fieldProps("addInfoFromTaxReturn").value && [
                <FormRow type="full">
                  <Box>
                    <Button
                      label={formatMessage("Cancel")}
                      variant="text"
                      size="default"
                      action={onCloseAddInfoFromTaxReturnSection}
                      disabled={!props.options.isEditing}
                    />
                  </Box>
                </FormRow>,
              ]}
              {!fieldProps("addInfoFromTaxReturn").value && [
                <FormRow type="full">
                  <Box>
                    <Button
                      label={formatMessage(
                        "StandardLoan.additionalIncome.addInformationTaxReturn"
                      )}
                      variant="text"
                      action={onAddInfoFromTaxReturn}
                      icon={PlusIcon}
                      size="default"
                      disabled={!props.options.isEditing}
                    />
                  </Box>
                </FormRow>,
              ]}
              {fieldProps("addInfoFromTaxReturn").value && (
                <FormRow type="full">
                  <MoneyField
                    {...specialFieldsReworkFieldProps(
                      fieldProps("taxBase"),
                      reworkDependent,
                      isReworked(props.rework, "incomeInfo", "taxBase") &&
                        option.isSome(initialValues.taxBase),
                      disabled,
                      props.reworkAll
                    )}
                    label={formatMessage("StandardLoan.FreelancerCard.taxBase")}
                    placeholder={formatMessage(
                      "StandardLoan.FreelancerCard.taxBasePlaceholder"
                    )}
                    currency={tenantCurrency}
                  />
                </FormRow>
              )}
              {fieldProps("addInfoFromTaxReturn").value && (
                <FormRow type="full">
                  <MoneyField
                    {...specialFieldsReworkFieldProps(
                      fieldProps("businessGrossIncome"),
                      reworkDependent,
                      isReworked(
                        props.rework,
                        "incomeInfo",
                        "businessGrossIncome"
                      ) && option.isSome(initialValues.businessGrossIncome),
                      disabled,
                      props.reworkAll
                    )}
                    label={formatMessage(
                      "StandardLoan.FreelancerCard.businessGrossIncome"
                    )}
                    placeholder={formatMessage(
                      "StandardLoan.FreelancerCard.businessGrossIncomePlaceholder"
                    )}
                    currency={tenantCurrency}
                  />
                </FormRow>
              )}
            </Stack>
          ),
          () => (
            <Stack column grow shrink units={6}>
              <FormRow type="full">
                <YesNoRadioGroupField
                  {...specialFieldsReworkFieldProps(
                    fieldProps("keepsAccountancy"),
                    reworkDependent,
                    isReworked(
                      props.rework,
                      "incomeInfo",
                      "keepsAccountancy"
                    ) && option.isSome(initialValues.keepsAccountancy),
                    disabled,
                    props.reworkAll
                  )}
                  label={formatMessage(
                    "StandardLoan.FreelancerCard.keepsAccountancy"
                  )}
                  description={formatMessage(
                    "StandardLoan.FreelancerCard.keepsAccountancy.description"
                  )}
                />
              </FormRow>
              {isOptionTrue(fieldProps("keepsAccountancy").value) && (
                <FormRow type="full">
                  <YesNoRadioGroupField
                    {...specialFieldsReworkFieldProps(
                      fieldProps("equityPositive"),
                      reworkDependent || keepAccountancyReworked,
                      isReworked(
                        props.rework,
                        "companyInfo",
                        "equityPositive"
                      ) && option.isSome(initialValues.equityPositive),
                      disabled,
                      props.reworkAll
                    )}
                    label={formatMessage(
                      "StandardLoan.FreelancerCard.equityPositive"
                    )}
                    description={formatMessage(
                      "StandardLoan.FreelancerCard.equityPositive.description"
                    )}
                  />
                </FormRow>
              )}

              <FormRow type="full">
                <YesNoRadioGroupField
                  {...specialFieldsReworkFieldProps(
                    fieldProps("hasAdditionalIncome"),
                    reworkDependent,
                    isReworked(
                      props.rework,
                      "incomeInfo",
                      "hasAdditionalIncome"
                    ) && option.isSome(initialValues.hasAdditionalIncome),
                    disabled,
                    props.reworkAll
                  )}
                  label={formatMessage("StandardLoan.additionalIncome")}
                  description={formatMessage(
                    "StandardLoan.additionalIncome.description"
                  )}
                />
              </FormRow>

              {!fieldProps("addInfoFromTaxReturn").value && [
                <FormRow type="full">
                  <Box>
                    <Button
                      label={formatMessage(
                        "StandardLoan.additionalIncome.addInformationTaxReturn"
                      )}
                      variant="text"
                      action={onAddInfoFromTaxReturn}
                      icon={PlusIcon}
                      size="default"
                      disabled={!props.options.isEditing}
                    />
                  </Box>
                </FormRow>,
              ]}
              {fieldProps("addInfoFromTaxReturn").value && [
                <FormRow type="full">
                  <Box>
                    <Button
                      label={formatMessage("Cancel")}
                      variant="text"
                      size="default"
                      action={onCloseAddInfoFromTaxReturnSection}
                      disabled={!props.options.isEditing}
                    />
                  </Box>
                </FormRow>,
              ]}
              {fieldProps("addInfoFromTaxReturn").value &&
                isOptionTrue(fieldProps("keepsAccountancy").value) &&
                isOptionTrue(fieldProps("equityPositive").value) && [
                  <FormRow type="full">
                    <MoneyField
                      {...specialFieldsReworkFieldProps(
                        fieldProps("equityFromLastYear"),
                        reworkDependent || keepAccountancyReworked,
                        isReworked(
                          props.rework,
                          "incomeInfo",
                          "equityFromLastYear"
                        ) && option.isSome(initialValues.equityFromLastYear),
                        disabled,
                        props.reworkAll
                      )}
                      label={formatMessage(
                        "StandardLoan.FreelancerCard.equityFromLastYear"
                      )}
                      placeholder={formatMessage(
                        "StandardLoan.FreelancerCard.equityFromLastYearPlaceholder"
                      )}
                      currency={tenantCurrency}
                      description={formatMessage(
                        "StandardLoan.FreelancerCard.equityFromLastYear.description"
                      )}
                    />
                  </FormRow>,
                  <FormRow type="full">
                    <MoneyField
                      {...specialFieldsReworkFieldProps(
                        fieldProps("grossIncomes"),
                        reworkDependent || keepAccountancyReworked,
                        isReworked(
                          props.rework,
                          "incomeInfo",
                          "grossIncomes"
                        ) && option.isSome(initialValues.grossIncomes),
                        disabled,
                        props.reworkAll
                      )}
                      label={formatMessage(
                        "StandardLoan.FreelancerCard.grossIncomes"
                      )}
                      placeholder={formatMessage(
                        "StandardLoan.FreelancerCard.grossIncomesPlaceholder"
                      )}
                      currency={tenantCurrency}
                    />
                  </FormRow>,
                ]}
              {fieldProps("addInfoFromTaxReturn").value &&
                isOptionActuallyFalse(fieldProps("keepsAccountancy").value) && (
                  <FormRow type="full">
                    <MoneyField
                      {...specialFieldsReworkFieldProps(
                        fieldProps("r101BruttoIncomes"),
                        reworkDependent || keepAccountancyReworked,
                        isReworked(
                          props.rework,
                          "incomeInfo",
                          "r101BruttoIncomes"
                        ) && option.isSome(initialValues.r101BruttoIncomes),
                        disabled,
                        props.reworkAll
                      )}
                      label={formatMessage(
                        "StandardLoan.FreelancerCard.r101BruttoIncomes"
                      )}
                      placeholder={formatMessage(
                        "StandardLoan.FreelancerCard.r101BruttoIncomesPlaceholder"
                      )}
                      currency={tenantCurrency}
                    />
                  </FormRow>
                )}
              {fieldProps("addInfoFromTaxReturn").value &&
                (isOptionActuallyFalse(fieldProps("keepsAccountancy").value) ||
                  isOptionTrue(fieldProps("equityPositive").value)) && (
                  <FormRow type="full">
                    <MoneyField
                      {...specialFieldsReworkFieldProps(
                        fieldProps("r37PartialTaxBase"),
                        reworkDependent,
                        isReworked(
                          props.rework,
                          "incomeInfo",
                          "r37PartialTaxBase"
                        ) && option.isSome(initialValues.r37PartialTaxBase),
                        disabled,
                        props.reworkAll
                      )}
                      label={formatMessage(
                        "StandardLoan.FreelancerCard.r37PartialTaxBase"
                      )}
                      placeholder={formatMessage(
                        "StandardLoan.FreelancerCard.r37PartialTaxBasePlaceholder"
                      )}
                      currency={tenantCurrency}
                    />
                  </FormRow>
                )}
            </Stack>
          )
        )}
      </FormSection>
    </Stack>
  );
}

function isOptionActuallyFalse(value: Option<boolean>): boolean {
  return pipe(
    value,
    option.exists(value => value === false)
  );
}
