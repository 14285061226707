import { FormRow, ComputedFieldProps, FormSection } from "design-system";
import { useFormatMessage } from "../../../../../../intl";
import { MoneyField } from "../../../../../../Common/Price/MoneyField/MoneyField";
import { Option } from "fp-ts/Option";
import { constNull, pipe } from "fp-ts/function";
import { boolean, option } from "fp-ts";
import { YesNoRadioGroupField } from "../../../../../../Common/YesNoRadioGroup/YesNoRadioGroupField";
import { Currency } from "../../../../../../globalDomain";
import { isOptionTrue } from "../../../../domain";

type Props = {
  reInPersonalOwnershipFieldProps: ComputedFieldProps<Option<boolean>>;
  reUsedAsCollateralFieldProps: ComputedFieldProps<Option<boolean>>;
  reCoOwned: ComputedFieldProps<Option<boolean>>;
  incomeFromRentContractFieldProps: ComputedFieldProps<Option<number>>;
  r201IncomeFromLeaseFieldProps: ComputedFieldProps<Option<number>>;
  isCooperativeOwnership: ComputedFieldProps<Option<boolean>>;
  isApplicantDeclareAsUserInDeclaration: ComputedFieldProps<Option<boolean>>;
  currency: Currency;
};

export function RentFields(props: Props) {
  const formatMessage = useFormatMessage();

  const MIN_MONEY_VALIDATION = -999999999;
  const MAX_MONEY_VALIDATION = 999999999;

  return (
    <FormSection>
      <FormRow type="full">
        <YesNoRadioGroupField
          {...props.reInPersonalOwnershipFieldProps}
          label={formatMessage(
            "StandardLoan.AdditionalIncome.IncomeSection.IsReInPersonalOwnershipLabel"
          )}
        />
      </FormRow>
      {pipe(
        props.reInPersonalOwnershipFieldProps.value,
        option.fold(
          constNull,
          boolean.fold(
            () => (
              <FormRow type="full">
                <YesNoRadioGroupField
                  {...props.isCooperativeOwnership}
                  label={formatMessage(
                    "StandardLoan.AdditionalIncome.IncomeSection.IsCooperativeOwnership"
                  )}
                />
              </FormRow>
            ),
            () => (
              <FormSection>
                <FormRow type="full">
                  <YesNoRadioGroupField
                    {...props.reCoOwned}
                    label={formatMessage(
                      "StandardLoan.AdditionalIncome.IncomeSection.IsReCoOwnedByAnotherPerson"
                    )}
                  />
                </FormRow>
                <FormRow type="full">
                  <YesNoRadioGroupField
                    {...props.reUsedAsCollateralFieldProps}
                    label={formatMessage(
                      "StandardLoan.AdditionalIncome.IncomeSection.IsReUsedCollateral"
                    )}
                  />
                </FormRow>
              </FormSection>
            )
          )
        )
      )}

      {pipe(
        !isOptionTrue(props.reInPersonalOwnershipFieldProps.value) &&
          isOptionTrue(props.isCooperativeOwnership.value),
        boolean.fold(constNull, () => (
          <FormRow type="full">
            <YesNoRadioGroupField
              {...props.isApplicantDeclareAsUserInDeclaration}
              label={formatMessage(
                "StandardLoan.AdditionalIncome.IncomeSection.IsApplicantDeclareAsUserInDeclaration"
              )}
            />
          </FormRow>
        ))
      )}
      <FormRow type="full">
        <MoneyField
          {...props.incomeFromRentContractFieldProps}
          currency={props.currency}
          label={formatMessage(
            "StandardLoan.AdditionalIncome.IncomeSection.incomeFromRentContractLabel"
          )}
          placeholder={formatMessage(
            "StandardLoan.AdditionalIncome.IncomeSection.incomeFromRentContractPlaceholder"
          )}
          min={MIN_MONEY_VALIDATION}
          max={MAX_MONEY_VALIDATION}
        />
      </FormRow>
      <FormRow type="full">
        <MoneyField
          {...props.r201IncomeFromLeaseFieldProps}
          currency={props.currency}
          label={formatMessage(
            "StandardLoan.AdditionalIncome.IncomeSection.incomeOnlyFromLeaseLabel"
          )}
          placeholder={formatMessage(
            "StandardLoan.AdditionalIncome.IncomeSection.incomeOnlyFromLeasePlaceholder"
          )}
          min={MIN_MONEY_VALIDATION}
          max={MAX_MONEY_VALIDATION}
        />
      </FormRow>
    </FormSection>
  );
}
