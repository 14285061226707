import { constNull, pipe } from "fp-ts/function";
import { IO } from "fp-ts/IO";
import { useQuery } from "../../useAPI";
import { foldRiskType } from "./FollowUpAndSignatureState";
import { HighRiskClient } from "./HighRiskClient/HighRiskClient";
import { LowRiskClient } from "./LowRiskClient/LowRiskClient";
import { followUpPreliminaryStatus, LFStatus } from "../api";
import * as remoteData from "../../RemoteData";
import { ContentRow, Loader } from "design-system";
import { StandardLoanFlowType } from "../domain";
import { Option } from "fp-ts/Option";
import { ExistingClientAuthenticationMethod } from "../../globalDomain";

type Props = {
  onContinue: (status: Option<LFStatus>) => unknown;
  onApplicationRejected: IO<void>;
  onAfterSignature: (status: Option<LFStatus>) => unknown;
  flowType: StandardLoanFlowType;
  authenticationMethod: Option<ExistingClientAuthenticationMethod>;
  mustReadAllDocuments?: boolean;
  skipContracts: boolean;
  isCredentialsAndSigningRemote: boolean;
  onRemoteCredentialsExit: IO<unknown>;
  credentialStatus:
    | {
        existingClient: true;
      }
    | {
        existingClient: false;
        shouldAskCredential: boolean;
      };
  isRework: boolean;
};

export function CreateProfile(props: Props) {
  const [preliminaryStatus] = useQuery(followUpPreliminaryStatus);

  return pipe(
    preliminaryStatus,
    remoteData.fold(
      () => (
        <ContentRow type="lateral-margins" grow>
          <Loader />
        </ContentRow>
      ),
      constNull,
      ({ statusCode }) =>
        pipe(
          statusCode === "OK" ? "low" : "high",
          foldRiskType(
            () => <HighRiskClient credentialStatus={props.credentialStatus} />,
            () => (
              <LowRiskClient
                onContinue={props.onContinue}
                onApplicationRejected={props.onApplicationRejected}
                onAfterSignature={props.onAfterSignature}
                flowType={props.flowType}
                authenticationMethod={props.authenticationMethod}
                mustReadAllDocuments={props.mustReadAllDocuments}
                skipContracts={props.skipContracts}
                credentialStatus={props.credentialStatus}
                isCredentialsAndSigningRemote={
                  props.isCredentialsAndSigningRemote
                }
                isRework={props.isRework}
              />
            )
          )
        )
    )
  );
}
