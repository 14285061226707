import {
  ComputedFieldProps,
  LocalizedString,
  NumberField,
  TextChildren,
} from "design-system";
import { option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { useFormatIncomeCurrency } from "../../../intl";
import { useTenantCurrency } from "../../../Common/useTenantCurrency";

type Props = {
  fieldProps: ComputedFieldProps<Option<number>>;
  description?: LocalizedString;
  placeholder: LocalizedString;
  autoFocus?: boolean;
  min?: number;
  max?: number;
  allowNegative?: boolean;
  assistiveText?: TextChildren;
  currency?: string;
  disabled?: boolean;
  label: LocalizedString;
};

export function AmountWithCurrencyField(props: Props) {
  const formatCurrency = useFormatIncomeCurrency();
  const tenantCurrency = useTenantCurrency();

  return (
    <NumberField
      {...props}
      {...props.fieldProps}
      rightContent={option.some(
        formatCurrency(props.currency || tenantCurrency)
      )}
    />
  );
}
