import { FormRow, FormSection, ComputedFieldProps } from "design-system";
import { useFormatMessage } from "../../../../../intl";
import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { CompanySuggestion } from "../../../../../Common/CompanyField/domain";
import { CompanyInfoRow } from "../../../commons/CompanyInfoRow";
import { BusinessSectorField } from "./Fields/BusinessSectorField";
import { MonthYear } from "../../../../../globalDomain";
import { MonthYearField } from "../../../commons/MonthYearField";

type PropsCZ = {
  companyIcoFieldProps: ComputedFieldProps<string>;
  companyNameFieldProps: ComputedFieldProps<string>;
  syncCompanyFields: (suggestion: CompanySuggestion) => void;
  onCompanyNameValidationChange: (value: boolean) => unknown;
  onCompanyIcoValidationChange: (value: boolean) => unknown;
};

export function CompanySectionCZ(props: PropsCZ) {
  const formatMessage = useFormatMessage();

  return (
    <FormSection
      heading={{
        title: formatMessage("StandardLoan.Tradesman.CompanySection.title"),
      }}
    >
      <CompanyInfoRow
        incomeSourceType="TradesmanCoOperatingPerson"
        companyIcoFieldProps={props.companyIcoFieldProps}
        companyNameFieldProps={props.companyNameFieldProps}
        syncCompanyFields={props.syncCompanyFields}
        onCompanyIcoValidationChange={props.onCompanyIcoValidationChange}
        onCompanyNameValidationChange={props.onCompanyNameValidationChange}
      />
    </FormSection>
  );
}

type PropsSK = {
  companyIcoFieldProps: ComputedFieldProps<string>;
  companyNameFieldProps: ComputedFieldProps<string>;
  businessSectorFieldProps: ComputedFieldProps<Option<NonEmptyString>>;
  businessStartingDateFieldProps: ComputedFieldProps<Option<MonthYear>>;
  startingDateMinYear: number;
  currentYear: number;
  syncCompanyFields: (suggestion: CompanySuggestion) => void;
  onCompanyNameValidationChange: (value: boolean) => unknown;
  onCompanyIcoValidationChange: (value: boolean) => unknown;
};

export function CompanySectionSK(props: PropsSK) {
  const formatMessage = useFormatMessage();

  return (
    <FormSection
      heading={{
        title: formatMessage("StandardLoan.Tradesman.CompanySection.title"),
      }}
    >
      <CompanyInfoRow
        incomeSourceType="TradesmanCoOperatingPerson"
        companyIcoFieldProps={props.companyIcoFieldProps}
        companyNameFieldProps={props.companyNameFieldProps}
        syncCompanyFields={props.syncCompanyFields}
        onCompanyIcoValidationChange={props.onCompanyIcoValidationChange}
        onCompanyNameValidationChange={props.onCompanyNameValidationChange}
      />
      <FormRow type="full">
        <BusinessSectorField fieldProps={props.businessSectorFieldProps} />
      </FormRow>
      <FormRow type="full">
        <MonthYearField
          {...props.businessStartingDateFieldProps}
          clearable={false}
          label={formatMessage(
            "StandardLoan.Tradesman.CompanySection.businessStartingDate"
          )}
          maxYear={props.currentYear}
          minYear={props.currentYear - 50}
          searchable
          checkMonthLimit
          monthSelectionYearLimit={3}
        />
      </FormRow>
    </FormSection>
  );
}
