import { FormRow } from "design-system";
import { useFormatMessage } from "../../../../../../intl";
import { MoneyField } from "../../../../../../Common/Price/MoneyField/MoneyField";
import { RFieldProps } from "../domain";

export function R37Field(props: RFieldProps) {
  const formatMessage = useFormatMessage();

  return (
    <FormRow type="full">
      <MoneyField
        {...props.fieldProps}
        currency={props.currency}
        label={formatMessage(
          "StandardLoan.AdditionalIncome.IncomeSection.partialTaxFromActivityLabel"
        )}
        placeholder={formatMessage(
          "StandardLoan.AdditionalIncome.IncomeSection.partialTaxFromActivityPlaceholder"
        )}
      />
    </FormRow>
  );
}
