import { ComputedFieldProps, DropdownOption } from "design-system";
import { option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { pipe, constVoid } from "fp-ts/function";
import { useFormatIncomeCurrency, useFormatMessage } from "../../../intl";
import { useQuery } from "../../../useAPI";
import * as api from "../api";
import * as remoteData from "../../../RemoteData";
import { DropdownField } from "../../../Common/DropdownField/DropdownField";
import { selectedDropdownOption } from "../../../Common/selectDropdownOption";
import { useEffect, useState } from "react";

type Props = {
  fieldProps: ComputedFieldProps<Option<string>>;
  isMainIncome: boolean;
};

export function CurrencyDropdown(props: Props) {
  const formatMessage = useFormatMessage();
  const formatCurrency = useFormatIncomeCurrency();

  const [currenciesResponse] = useQuery(api.currencies);
  const [currencies, setCurrencies] = useState<string[]>([]);

  const options: DropdownOption<string>[] = currencies.map(
    (currency: string) => ({
      value: currency,
      label: formatCurrency(currency, "long"),
    })
  );

  const onChange = (value: Option<DropdownOption<string>>) => {
    props.fieldProps.onChange(
      pipe(
        value,
        option.map(v => v.value)
      )
    );
  };

  useEffect(() => {
    pipe(
      currenciesResponse,
      remoteData.fold(constVoid, constVoid, curr => {
        const defaultCurrency = pipe(
          props.fieldProps.value,
          option.fold(
            () => option.some(curr[0]),
            value => option.some(value)
          )
        );

        props.fieldProps.onChange(defaultCurrency);
        setCurrencies(curr);
      })
    );
  }, [currenciesResponse]);

  const initialValue = pipe(
    props.fieldProps.value,
    option.getOrElse(() => currencies[0])
  );

  const value = selectedDropdownOption(option.some(initialValue), options);

  return (
    <DropdownField
      {...props.fieldProps}
      clearable={false}
      label={
        props.isMainIncome
          ? formatMessage("StandardLoan.EmployedCard.salaryCurrency")
          : formatMessage("StandardLoan.EmployedCard.additionalSalaryCurrency")
      }
      onChange={onChange}
      options={options}
      placeholder={formatMessage(
        "StandardLoan.EmployedCard.salaryCurrencyPlaceholder"
      )}
      searchable
      value={value}
    />
  );
}
