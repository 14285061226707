import {
  ComputedFieldProps,
  DropdownOption,
  unsafeLocalizedString,
} from "design-system";
import { tuple, option } from "fp-ts";
import { pipe, constant, identity } from "fp-ts/function";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { Option } from "fp-ts/Option";
import { fold } from "../../../RemoteData";
import { useFormatMessage } from "../../../intl";
import { selectedDropdownOption } from "../../../Common/selectDropdownOption";
import { DropdownField } from "../../../Common/DropdownField/DropdownField";
import { useQuery } from "../../../useAPI";
import * as api from "../api";

type Props = {
  fieldProps: ComputedFieldProps<Option<NonEmptyString>>;
};

export function FreelancerTypeDropdown(props: Props) {
  const formatMessage = useFormatMessage();

  const freelancerTypes = pipe(
    useQuery(api.freelancerTypes),
    tuple.fst,
    fold(constant([]), constant([]), identity)
  );

  const options: DropdownOption<NonEmptyString>[] = freelancerTypes.map(
    freelancer => ({
      value: freelancer.key,
      label: unsafeLocalizedString(freelancer.name),
    })
  );

  const onChange = (dropdownOption: Option<DropdownOption<NonEmptyString>>) => {
    props.fieldProps.onChange(
      pipe(
        dropdownOption,
        option.map(v => v.value)
      )
    );
  };

  const value = selectedDropdownOption(props.fieldProps.value, options);

  return (
    <DropdownField
      {...props.fieldProps}
      clearable={false}
      label={formatMessage("StandardLoan.FreelancerCard.freelancerType")}
      onChange={onChange}
      options={options}
      placeholder={formatMessage(
        "StandardLoan.FreelancerCard.freelancerTypePlaceholder"
      )}
      searchable
      value={value}
    />
  );
}
